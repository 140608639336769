import { SearchData } from "../types/location";

export function toParams(obj: any) {
  let str = "";
  for (var key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
}

export function getObjectFromUrl(pathname: string = window.location.pathname) {
  if (!isObjectcardView()) {
    return null;
  }
  const rdfId = pathname.replace("/objectcard/", "");
  if (!rdfId || !rdfId.startsWith("_")) {
    return null;
  }
  return rdfId;
}
export function isObjectcardView() {
  if (window.location.pathname.indexOf("/objectcard") === 0) {
    return true;
  } else {
    return false;
  }
}

export function getSearchData(
  search: string = window.location.search
): SearchData {
  let data: SearchData = {};
  if (!search || !search.startsWith("?")) {
    /**Search may be omitted, check session storage */
    const storageData = getSessionQueryParams();
    if (storageData) {
      return storageData;
    }
    return data;
  }
  search = search.substring(1); //Remove starting #
  search.split("&").forEach((v) => {
    let param = v.split("=");
    if (param.length == 2) {
      let key = decodeURIComponent(param[0].trim());
      let value = decodeURIComponent(param[1].trim());
      data[key] = value;
    }
  });
  return data;
}

export function getSearchString(href: string): string {
  const searchStart = href.indexOf("?");
  if (searchStart === -1) {
    return "";
  }
  return href.substring(searchStart);
}

export function getContextPath(): string {
  const metaElm = document.querySelector("meta[name=servlet-context]");
  if (metaElm == null) {
    return "/";
  }
  let contextPath = metaElm.getAttribute("content");
  if (typeof contextPath != "string" || contextPath.length == 0) {
    contextPath = "/";
  } else if (!contextPath.endsWith("/")) {
    contextPath = contextPath + "/";
  }
  return contextPath;
}

export function buildParamsString(
  params: { [k: string]: string },
  withoutEncoding?: boolean
): string {
  let paramsString = "";
  for (let k in params) {
    paramsString += withoutEncoding
      ? `&${k}=${params[k]}`
      : `&${k}=${encodeURIComponent(params[k])}`;
  }
  paramsString = paramsString.replace("&", "");
  return paramsString;
}

export function normalizeUrl(url: string): string {
  let protocol = "";
  let href = "";
  const parts = url.split("://");
  if (parts.length != 1) {
    protocol = parts[0];
    parts.splice(0, 1);
  }
  href = parts.join("://");
  href = href.replace(/\/\/+/g, "/");
  return protocol + href;
}

export function buildUrl({
  url,
  search,
  withoutEncoding,
}: {
  url: string;
  search?: { [k: string]: string } | null;
  withoutEncoding?: boolean;
}): string {
  let href = normalizeUrl(url);
  if (search) {
    href += `?${buildParamsString(search, withoutEncoding)}`;
  }
  return href;
}

export function storeSessionQueryParams(params: { [k: string]: string }) {
  window.sessionStorage.setItem("NPT.queryParams", JSON.stringify(params));
}

export function getSessionQueryParams() {
  const queryParamsString = window.sessionStorage.getItem("NPT.queryParams");
  if (!queryParamsString) {
    return null;
  }
  try {
    return JSON.parse(queryParamsString);
  } catch (e) {
    console.error("Error occured while parsing session query params:", e);
    return null;
  }
}

export function removeSessionQueryParams() {
  window.sessionStorage.removeItem("NPT.queryParams");
}

//Parse legacy links to get query params
export function getQueryParamsFromLink(link: string): SearchData | null {
  const searchStart = link.indexOf("?");
  if (searchStart === -1) {
    return null;
  }
  const params: SearchData = {};
  const search = link.substring(searchStart + 1);
  for (let param of search.split("&")) {
    const [p, value] = param.split("=");
    params[p] = value;
  }
  return params;
}
