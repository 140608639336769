import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import {
  ContextedDivider,
  Divider,
  DivPanel,
  Splitter,
} from "./components/divider";
import TreeBreadcrumbs from "./components/helpers/TreeBreadcrumbs";
import TabsSwitch from "./components/helpers/TabsSwitch";
import Tabs from "./components/helpers/Tabs";
import Plugin from "./components/plugin/Plugin";
import Fetch from "./components/helpers/Fetch";
import Session from "./components/helpers/Session";
import Repeat from "./components/helpers/Repeat";
import When from "./components/helpers/When";
import Redux from "./components/helpers/Redux";
import Toolbar from "./components/helpers/Toolbar";
import Modal from "./components/helpers/NamedModal";
import Tooltip from "./components/helpers/NamedTooltip";
import {
  Navigation,
  NavigationGroup,
  NavigationLink,
} from "./components/navigation";
import { Sitemap } from "./components/sitemap";
import { Filler } from "./components/filler";
import Search from "./components/search/Search";

import asyncComponent from "./AsyncComponent";
import { SplitterBar } from "./components/divider/SplitterBar";
import UnreadMessages from "./components/menu/UnreadMessages";
import MControls from "./components/menu/MenuControls";
import { Faq } from "./components/faq/FAQ";
import SystemInfo from "./components/helpers/SystemInfo";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { ConnectedView } from "./components/view/View";
import { ViewContext } from "./components/view/ViewContext";
import { AutocompleteInput as Autocomplete } from "./components/autocomplete/AutocompleteInput";
import { FullScreenWrapper } from "./components/fullscreen/FullScreenWrapper";

const Objectcard = asyncComponent(
  () => import("./components/objectcard/AsyncObjectCard")
);
const Table = asyncComponent(() => import("./components/table/AsyncTable"));
const GantLegend = asyncComponent(
  () => import("./components/table/AsyncGantLegend")
);
const Tree = asyncComponent(() => import("./components/tree/AsyncListTree"));
// const Map = asyncComponent(() => import("./components/map/CimMap"));
const WsLogger = asyncComponent(() => import("./components/wslogger/WsLogger"));
const FileUploader = asyncComponent(
  () => import("./components/uploader/FileUploader")
);

const MenuControls = () => {
  const unreadMessagesBadge = <UnreadMessages />;
  return <MControls badge={unreadMessagesBadge} />;
};

const View = ({ view, ...otherProps }: { view: string }) => {
  return <ConnectedView viewPath={"/view" + view} {...otherProps} />;
};

export default {
  Link,
  SystemInfo,
  Autocomplete,
  TreeBreadcrumbs,
  Tabs,
  Tab,
  TabsSwitch,
  Divider,
  Splitter,
  DivPanel,
  FullScreenWrapper,
  SplitterBar,
  Plugin,
  Fetch,
  Session,
  Repeat,
  When,
  Redux,
  Toolbar,
  Objectcard,
  Table,
  Tree,
  Navigation,
  NavigationGroup,
  NavigationLink,
  Sitemap,
  GantLegend,
  Filler,
  WsLogger,
  FileUploader,
  MenuControls,
  ContextedDivider,
  Search,
  Faq,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  OverlayTrigger,
  View,
  ViewContext, //To access __PAGE__ from anywhere
  Modal,
  Tooltip,
};
