import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { click, sendSubjectChangeTab } from "../../../actions/subject";
import { isCardEdit, isEditable } from "../../../services/layout";
import { ApplicationState, ApplicationAction } from "../../../types";
import { isSubject, Layout, LayoutNode } from "../../../types/subject";

interface CardButtonProps {
  subjectKey?: string;
  node?: LayoutNode;
  layout?: Layout;
  visible?: boolean;
  loading?: boolean;
  click: (id: string) => void;
}

class CardButton extends React.Component<CardButtonProps> {
  constructor(props: CardButtonProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(reactEvent: any) {
    const { node } = this.props;
    node && this.props.click(node.id);
  }

  getIcon() {
    const { node } = this.props;
    if (!node?.options?.icon) {
      return null;
    }
    return (
      <i className={`fa ${node.options.icon} fa-fw`} aria-hidden="true"></i>
    );
  }

  shouldComponentUpdate(nextProps: CardButtonProps) {
    return (
      this.props.node != nextProps.node || this.props.layout != nextProps.layout
    );
  }
  renderLoading() {
    const { loading } = this.props;
    if (!loading) {
      return null;
    }
    return <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />;
  }
  render() {
    const { node } = this.props;
    if (!node) {
      return null;
    }
    const bg = node.options.bg || "primary";
    return (
      <button
        type="button"
        className={`npt-objectcard-button btn btn-${bg}`}
        onClick={this.handleClick}
      >
        {this.renderLoading()}
        {this.getIcon()}
        <span>{node.label}</span>
      </button>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { nodeId, subjectKey } = ownProps;
    if (!state.subject) {
      return null;
    }
    const subject = state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return null;
    }

    const node = subject.nodeById[nodeId];
    return {
      layout: subject,
      node,
      loading: subject?.loading?.[node.id],
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      click: (id: string) => dispatch(click(subjectKey, id)),
    };
  }
)(CardButton);
