import { FC } from "react";
import styles from "./Bulb.module.css";

export type BulbColorType =
  | "warning"
  | "success"
  | "danger"
  | "primary"
  | "secondary"
  | "secondary";
type Size = "md" | "lg" | "sm";
interface BulbProps {
  value: BulbColorType;
  isOn: boolean;
  size?: Size;
}
export const Bulb: FC<BulbProps> = ({ value, isOn, size: s }) => {
  const getColorClass = () => {
    switch (value) {
      case "warning":
        return styles.bulbWarning;
      case "danger":
        return styles.bulbDanger;
      case "success":
        return styles.bulbSuccess;
      case "primary":
        return styles.bulbPrimary;
      case "secondary":
        return styles.bulbSecondary;
    }
  };
  const getSizeClass = () => {
    const size: Size = s || "md";
    switch (size) {
      case "lg":
        return styles.bulbLg;
      case "md":
        return styles.bulbMd;
      case "sm":
        return styles.bulbSm;
    }
  };
  const getPulse = () => (isOn ? styles.pulsing : "");
  return (
    <div
      className={`${getColorClass()} ${
        styles.bulb
      } ${getPulse()} ${getSizeClass()}`}
    ></div>
  );
};
