import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Select, { ControlProps } from "react-select";
import { ThunkDispatch } from "redux-thunk";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import shortid from "shortid";
import { ApplicationAction, ApplicationState } from "../../../types";
import { I18NString } from "../../../types/modal";
import {
  EnumerationClass,
  EnumerationInfo,
  EnumerationNode,
  isSubject,
  LayoutNode,
  SubjectData,
  Layout,
  SubjectComment,
} from "../../../types/subject";
import { change, link, makeForm, remove } from "../../../actions/subject";
import { isCardEdit, isEditable } from "../../../services/layout";
import { retrieveFunction } from "../../../services/automation";

import BasicInput from "./BasicInput";

import styles from "./ComboBoxInput.module.css";

export const MSG_SELECT_LOADING = (
  <FormattedMessage
    id="MSG_SELECT_LOADING"
    defaultMessage="Loading..."
    description="Available predicates is loading"
  />
);

export const MSG_SELECT_PLACEHOLDER = (
  <FormattedMessage
    id="MSG_SELECT_PLACEHOLDER"
    defaultMessage="Select..."
    description="User must enter text to find predicate"
  />
);

export const MSG_SELECT_NO_RESULTS = (
  <FormattedMessage
    id="MSG_SELECT_NO_RESULTS"
    defaultMessage="No results found"
    description="There's no results corresponding to user's filter"
  />
);

function generateIdentifier(node: any): string {
  if (!node || typeof node != "object") {
    return node;
  }
  let id = node.$rdfId;
  if (node.$namespace) {
    let prefix;
    switch (typeof node.$namespace) {
      case "string":
        prefix = node.$namespace;
        break;
      case "object":
        prefix = (node.$namespace as any)?.$prefix || node.$namespace;
        break;
    }
    id = prefix + ":" + id;
  }
  return id;
}

function isEmptyObject(obj: any): boolean {
  if (typeof obj != "object") {
    return false;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

interface ComboBoxInputProps {
  subjectKey: string;
  nodeId: string;
  data?: SubjectData;
  dynamicEnumeration?: boolean;
  layout?: Layout;
  visible: boolean;
  values?: any;
  comment?: SubjectComment;
  link: (data: any) => void;
  remove: (indexList: number[] | number) => void;
  change: (data: any, options?: any) => void;
  loading?: boolean;
  editable?: boolean;
  cardEditable?: boolean;
  node?: LayoutNode;
  value?: any | any[];
  hideLabel?: boolean;
  enumerationCls?: EnumerationClass;
  allowPartialSelection?: boolean;
  valid?: boolean;
  error?: I18NString;
  menuShouldBlockScroll?: boolean;
  select?: (value: any) => void;
}
interface ComboboxInputStates {
  automationFilter: any;
}
/**
 * Main combobox element with common functions
 */
class ComboBoxInput extends React.Component<
  ComboBoxInputProps,
  ComboboxInputStates
> {
  constructor(props: ComboBoxInputProps) {
    super(props);
    this.state = {
      automationFilter: this.getEnumeraionFilter(),
    };
  }

  getlabel(): string {
    if (!this.props.node) {
      return "";
    }
    return this.props.node.options.label || this.props.node.label || "";
  }

  componentWillReceiveProps(nextProps: ComboBoxInputProps) {
    let shouldUpdateFilter =
      this.props.node != nextProps.node ||
      this.props.layout?.automation != nextProps.layout?.automation;
    // if (!shouldUpdateFilter && nextProps.node?.options?.["update-on-change"]) {
    //   let predicateList = nextProps.node?.options?.["update-on-change"];
    //   if (!Array.isArray(predicateList)) {
    //     predicateList = [predicateList];
    //   }
    //   for (let predicateId of predicateList) {
    //     predicateId = predicateId.replace(":", ".");
    //     if (this.props.values[predicateId] !== nextProps.values[predicateId]) {
    //       shouldUpdateFilter = true;
    //       break;
    //     }
    //   }
    // }
    // if (shouldUpdateFilter) {
    this.updateEnumerationFilter(nextProps);
    // }
  }
  isError(): boolean {
    const { valid } = this.props;
    return Boolean(this.props?.enumerationCls?.error) || !valid;
  }

  isLoading(): boolean {
    return Boolean(this.props?.enumerationCls?.loading);
  }

  isEmpty(): boolean {
    if (!this.props.enumerationCls) {
      return true;
    }
    return (
      !this.props.enumerationCls.error &&
      !this.props.enumerationCls.loading &&
      this.props.enumerationCls.enumerationInfo != null &&
      this.props.enumerationCls.enumerationInfo.children.length == 0
    );
  }

  isEditable() {
    return this.props.editable;
  }

  isMultiline(): boolean {
    if (!this.props.node) {
      return false;
    }
    return this.props.node.multiple || false;
  }

  isPartialSelectionAllowed(): boolean {
    return Boolean(this.props.allowPartialSelection);
  }

  getEnumerationInfo(): EnumerationInfo | null {
    if (!this.props.enumerationCls) {
      return null;
    }
    return this.props.enumerationCls.enumerationInfo || null;
  }

  printError(): ReactElement {
    return this.printEmpty();
  }

  printLoading(): ReactElement {
    return this.printEmpty();
  }

  printEmpty(): ReactElement {
    return (
      <SingleComboBox
        dynamicEnumeration={this.props.dynamicEnumeration}
        link={this.props.link}
        hasEmptyValue={this.hasEmptyValue()}
        emptyValueLabel={this.getEmptyValueLabel()}
        enumerationInfo={null}
        automationFilter={this.state.automationFilter}
        error={this.isError()}
        select={this.props.select}
        loading={false}
        empty={true}
        editable={this.isEditable()}
        allowPartialSelection={false}
        value={""}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
        selectDefault={true}
      />
    );
  }
  /* Hide enumeration items via automation */
  getEnumeraionFilter(props = this.props) {
    try {
      const nodeId = props?.node?.id;
      const funcId =
        nodeId && props.layout?.automation?.enumerationBindings[nodeId];
      if (!funcId || !props.data) {
        return null;
      }
      const func = retrieveFunction(funcId);
      const form = makeForm(props.values, props.data);

      try {
        return func.bind(this, form);
      } catch (ex) {
        console.log(
          "Enumeration bindgetEmptyValueLabeling error",
          props?.node?.id,
          ex
        );
      }
    } catch (ex) {
      return null;
    }
    return null;
  }
  updateEnumerationFilter(props = this.props) {
    const enumerationFilter = this.getEnumeraionFilter(props);
    if (enumerationFilter !== this.state.automationFilter) {
      this.setState({
        automationFilter: enumerationFilter,
      });
    }
  }
  getEmptyValueLabel = () => {
    const { node } = this.props;
    const defaultEmptyValueLabel = node?.options?.emptyValueLabel;
    return defaultEmptyValueLabel || "--";
  };
  hasEmptyValue = () => {
    const { node } = this.props;
    if (!node?.options) {
      return true;
    }
    const hasValue = node.options.hasEmptyValue;
    if (typeof hasValue === "undefined") {
      return true;
    }
    return hasValue;
  };
  printSingle(): ReactElement {
    return (
      <SingleComboBox
        valid={this.props.valid}
        link={this.props.link}
        enumerationInfo={this.getEnumerationInfo()}
        error={this.isError()}
        emptyValueLabel={this.getEmptyValueLabel()}
        loading={this.isLoading()}
        select={this.props.select}
        empty={this.isEmpty()}
        hasEmptyValue={this.hasEmptyValue()}
        dynamicEnumeration={this.props.dynamicEnumeration}
        editable={this.isEditable()}
        allowPartialSelection={this.isPartialSelectionAllowed()}
        automationFilter={this.state.automationFilter}
        value={generateIdentifier(this.props.value)}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
        selectDefault={true}
      />
    );
  }

  printMultiline(): ReactElement {
    let value: any[] | any = this.props.value;
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    return (
      <MultilineComboBox
        link={this.props.link}
        emptyValueLabel={this.getEmptyValueLabel()}
        remove={this.props.remove}
        enumerationInfo={this.getEnumerationInfo() as EnumerationInfo}
        error={this.isError()}
        hasEmptyValue={this.hasEmptyValue()}
        loading={this.isLoading()}
        empty={this.isEmpty()}
        dynamicEnumeration={this.props.dynamicEnumeration}
        editable={this.isEditable()}
        automationFilter={this.state.automationFilter}
        allowPartialSelection={this.isPartialSelectionAllowed()}
        values={value || undefined}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
        select={this.props.select}
      />
    );
  }

  render() {
    const { node, value } = this.props;
    if (!this.props.node) {
      return null;
    }

    let input = null;
    if (this.isLoading()) {
      input = this.printLoading();
    } else if (this.isEmpty()) {
      input = this.printEmpty();
    } else if (this.isMultiline()) {
      input = this.printMultiline();
    } else {
      input = this.printSingle();
    }
    if (this.props.hideLabel) {
      return <div className={"col-md-12 nopadding"}>{input}</div>;
    }
    return (
      <BasicInput
        id={this.props.subjectKey + "." + this.props.nodeId}
        editable={this.props.editable}
        cardEditable={this.props.cardEditable}
        error={this.props.error}
        node={this.props.node}
        visible={this.props.visible}
        comment={this.props.comment}
      >
        {input}
      </BasicInput>
    );
  }
}

interface SelectOption {
  value: string;
  label: string;
  description?: string;
}

interface SingleComboBoxProps {
  valid?: boolean;
  link: (value: any) => any;
  dynamicEnumeration?: boolean;
  emptyValueLabel?: string;
  enumerationInfo: EnumerationInfo | null;
  error?: boolean;
  loading?: boolean;
  automationFilter?: any;
  empty?: boolean;
  hasEmptyValue?: boolean;
  editable?: boolean;
  allowPartialSelection?: boolean;
  onlyValues?: boolean;
  value?: any[] | any;
  values?: string[];
  className?: string;
  menuShouldBlockScroll?: boolean;
  selectDefault?: boolean;
  select?: (value: any) => void;
}

interface SingleComboBoxState {
  optionsByIndex: SelectOption[][];
  optionsByValue: { [value: string]: SelectOption[] };
  parentByValue: { [value: string]: string };
  nodeByValue: { [value: string]: EnumerationNode };
  lastLevelsMap: { [value: string]: boolean };
  selectedValues: string[];
}
/**
 * Single combobox / row of comboboxes
 */
export class SingleComboBox extends React.Component<
  SingleComboBoxProps,
  SingleComboBoxState
> {
  constructor(props: SingleComboBoxProps) {
    super(props);

    const defaultValue = this.getDefaultValue();

    this.state = {
      optionsByIndex: [],
      optionsByValue: {},
      parentByValue: {
        [defaultValue]: defaultValue,
      },
      nodeByValue: {
        [defaultValue]: {},
      },
      lastLevelsMap: {},
      selectedValues: [defaultValue],
    };

    if (this.isEnumerationReady(props)) {
      this.normalizeEnumeration(
        props.enumerationInfo as EnumerationInfo,
        props.automationFilter
      );
      this.setupRoot();
    }

    if (props.value) {
      this.selectValue(props.value);
    }
    if (props.editable && this.isEnumerationReady(props) && props.value) {
      this.changeInvalidValue(props);
    }
    this.changeHandler = this.changeHandler.bind(this);
  }

  isError(): boolean {
    return Boolean(this.props.error);
  }

  isLoading(): boolean {
    return Boolean(this.props.loading);
  }

  isEmpty(): boolean {
    return Boolean(this.props.empty);
  }

  isEditable(): boolean {
    return Boolean(this.props.editable);
  }

  isPartialSelectionAllowed(): boolean {
    return Boolean(this.props.allowPartialSelection);
  }

  isEnumerationReady(props = this.props): boolean {
    return (
      Boolean(props.enumerationInfo) && typeof props.enumerationInfo == "object"
    );
  }

  isLastLevel(value: string): boolean {
    return Boolean(this.state.lastLevelsMap[value]);
  }

  isLastPrint(boxIndex: number): boolean {
    return this.getValues().length == boxIndex + 1;
  }

  getDefaultValue(): string {
    return "";
  }

  getOptionsByIdx(boxIndex: number): SelectOption[] {
    return this.state.optionsByIndex[boxIndex] || [];
  }

  getOptionsByValue(value: string): SelectOption[] {
    return this.state.optionsByValue[value] || [];
  }

  getValues(state = this.state): string[] {
    return state.selectedValues;
  }

  getSelectedValue(state = this.state): string {
    for (let i = state.selectedValues.length - 1; i >= 0; --i) {
      if (state.selectedValues[i] != this.getDefaultValue()) {
        return state.selectedValues[i];
      }
    }
    return "";
  }

  findSelectOption(value: string, options: SelectOption[]): SelectOption {
    for (let option of options) {
      if (option.value == value) {
        return option;
      }
    }
    return options[0];
  }
  getChildrenRdfIdMap(children: EnumerationNode[]) {
    let list: any = {};
    if (!children) {
      return [];
    }
    if (!Array.isArray(children)) {
      return list;
    }
    for (let child of children) {
      if (!child.data) {
        continue;
      }
      list[child.data.$rdfId] = true;
    }
    return list;
  }
  getEmptyValueLabel = () => {
    return this.props.emptyValueLabel || "--";
  };

  normalizeEnumeration(
    enumerationNode: EnumerationNode,
    automationFilter: any,
    parentValue: string = this.getDefaultValue()
  ): void {
    const { hasEmptyValue } = this.props;
    if (!enumerationNode.children || !Array.isArray(enumerationNode.children)) {
      return;
    }
    const children = enumerationNode.children;
    const options: SelectOption[] = [];
    if (hasEmptyValue) {
      options.push({
        value: this.getDefaultValue(),
        label: this.getEmptyValueLabel(),
        description: "",
      });
    }
    const childrenRdfIdList = this.getChildrenRdfIdMap(children);
    const filterMap = automationFilter && automationFilter(childrenRdfIdList);
    for (let child of enumerationNode.children) {
      if (!child.data) {
        console.error("Enumeration child doesn't have data:", child);
        continue;
      }
      const node = child.data;

      if (filterMap && !filterMap[node.$rdfId]) {
        continue;
      }
      const value = generateIdentifier(node);
      const label = node.$label || "";
      const description = node.$description;
      this.state.nodeByValue[value] = node;
      this.state.parentByValue[value] = parentValue;
      options.push({ value, label, description });

      const isLast = child.lastLevel || typeof child.children == "undefined";
      if (isLast) {
        this.state.lastLevelsMap[value] = isLast;
      } else {
        this.normalizeEnumeration(child, automationFilter, value);
      }
    }

    this.state.optionsByValue[parentValue] = options;
  }

  /* Modifies state! Be aware! */
  setupRoot(isDynamic?: boolean): void {
    if (isDynamic) {
      Object.assign(this.state, { selectedValues: [this.getDefaultValue()] });
    }
    this.state.optionsByIndex[0] =
      this.state.optionsByValue[this.getDefaultValue()];
  }

  compareValues(prevValue: string, value: string): boolean {
    return (
      prevValue == value || (isEmptyObject(prevValue) && isEmptyObject(value))
    );
  }

  shouldUpdateValue(value: string, depth: number): boolean {
    /* Last level selected */
    if (this.isLastLevel(value)) {
      return true;
    }
    /* Empty value selected */
    if (depth == 0 && value == this.getDefaultValue()) {
      return true;
    }
    /* Partial selection allowed */
    if (this.isPartialSelectionAllowed()) {
      return true;
    }
    return false;
  }

  changeValue(value: string, depth: number): void {
    const newState = Object.assign({}, this.state);
    const selectedValues = this.getValues(this.state).slice(0, depth);
    const optionsByIndex = newState.optionsByIndex.slice(0, depth);
    selectedValues[depth] = value;
    optionsByIndex[depth] = this.getOptionsByValue(
      selectedValues[depth - 1] || this.state.parentByValue[value]
    );
    if (value != this.getDefaultValue() && !this.isLastLevel(value)) {
      selectedValues[depth + 1] = this.getDefaultValue();
      optionsByIndex[depth + 1] = this.getOptionsByValue(value);
    }
    Object.assign(newState, { selectedValues, optionsByIndex });

    if (!this.shouldUpdateValue(value, depth)) {
      if (typeof this.props.select === "function") {
        this.props.select(value);
      }
      this.setState(newState);
      return;
    }

    value = this.getSelectedValue(newState);
    const prevValue = this.getSelectedValue(this.state);
    if (!this.compareValues(value, prevValue)) {
      this.props.link(this.state.nodeByValue[value]);
    }
    this.setState(newState);
  }
  /* Modifies state! Be aware! */
  selectValue(value: SubjectData | null): void {
    if (typeof value == "undefined") {
      return;
    }
    let enumId = generateIdentifier(value);

    if (!this.state.nodeByValue[enumId]) {
      console.error(
        `Can't find enumeration node with value "${enumId},${value}"`
      );
      return;
    }
    const currentValue = this.getSelectedValue();
    if (this.compareValues(currentValue, enumId)) {
      return;
    }
    const selectedValues = [];
    const optionsByIndex = [];
    do {
      selectedValues.push(enumId);
      enumId = this.state.parentByValue[enumId];
      if (typeof value == "undefined") {
        console.error(
          `Can't find parent enumeration node for value "${enumId}"`
        );
        return;
      }
      optionsByIndex.push(this.getOptionsByValue(enumId));
    } while (enumId != this.getDefaultValue());
    Object.assign(this.state, {
      selectedValues: selectedValues.reverse(),
      optionsByIndex: optionsByIndex.reverse(),
    });
  }

  getSelectionDepth(): number {
    return this.state.selectedValues.length;
  }

  filterOptions(
    initialOptions: SelectOption[],
    filterString?: string
  ): SelectOption[] {
    if (!filterString) {
      return initialOptions;
    }
    try {
      filterString = filterString.toLowerCase();
      return initialOptions.filter(
        (option) =>
          option.label
            .toString()
            .toLowerCase()
            .indexOf(filterString as string) >= 0
      );
    } catch (e) {
      return initialOptions;
    }
  }

  /* Setup selected value to selection comboBox */
  changeHandler(depth: number, option: any): void {
    const { selectDefault } = this.props;
    if (selectDefault || option.label !== this.getEmptyValueLabel()) {
      this.changeValue((option as SelectOption).value, depth);
    }
  }

  /* Prints comboBox element and add separator (if needed) */
  printComboBox(
    boxValue: string,
    boxIndex: number,
    className: string = ""
  ): ReactElement {
    let isLast = this.isLastPrint(boxIndex);
    let compositeClassName = className + " ";
    if (isLast) {
      compositeClassName += " cim-combobox-element-last";
    }
    if (this.isError()) {
      // compositeClassName += " form-control is-invalid";
      // compositeClassName += " form-control ";
    }
    return (
      <div
        key={"cb-" + boxIndex}
        className={`${compositeClassName} mb-1 px-0 d-flex flex-row `}
      >
        {this.printSelect(boxValue, boxIndex)}
        {!isLast && (
          <span className="fa ml-1 d-flex align-items-center fa-caret-right cim-combobox-element-separator">
            &nbsp;
          </span>
        )}
      </div>
    );
  }

  sortOptions = (options: SelectOption[]) => {
    let sortFunction: Function = (item1: string, item2: string) =>
      item1.localeCompare(item2);

    let newOptions = [...options];
    const emptyValueIndex = options.findIndex(
      (o) => o.label === this.getEmptyValueLabel()
    );
    let emptyValue = null;
    if (emptyValueIndex != -1) {
      emptyValue = options[emptyValueIndex];
      newOptions.splice(emptyValueIndex, 1);
    }

    const sortedOptions: SelectOption[] = newOptions.sort((item1, item2) => {
      return sortFunction(item1.label, item2.label);
    });
    if (emptyValue) {
      return [emptyValue, ...sortedOptions];
    }
    return sortedOptions;
  };

  filterSelected = (selectOptions: SelectOption[]) => {
    const { value, values } = this.props;
    const { selectedValues } = this.state;

    if (!values) {
      return this.sortOptions(selectOptions);
    }
    let convertedValues: string[] = values;

    // if (!Array.isArray(value)) {
    //     convertedValues = [value]
    // } else {
    //     for (const v of value) {
    //         if (v) {
    //             convertedValues.push(v);
    //         }
    //     }
    // }
    // if (convertedValues.length === 0) {
    //     return selectOptions;
    // }
    let filtered = [...selectOptions];

    filtered = filtered.filter((o) => !convertedValues.includes(o.value));

    return this.sortOptions(filtered);
  };

  printTooltip(description: string) {
    return (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        {description}
      </Tooltip>
    );
  }

  printOption(option: SelectOption) {
    if (!option.description) {
      return <span>{option.label}</span>;
    }
    return (
      <OverlayTrigger overlay={this.printTooltip(option.description)}>
        <span>{option.label}</span>
      </OverlayTrigger>
    );
  }
  getControlStyle = (
    base: React.CSSProperties,
    state: ControlProps<SelectOption, false>
  ) => {
    const { valid } = this.props;
    const isInvalid = valid === false;
    return {
      ...base,
      borderColor: isInvalid ? "var(--danger)" : base.borderColor,
      "&:hover": {
        borderColor: isInvalid ? "var(--danger)" : base.borderColor,
      },

      boxShadow:
        state.isFocused && isInvalid
          ? "0 0 0 0.2rem rgb(199 28 34 / 25%) !important"
          : "none",
    };
  };
  printSelect(boxValue: string, boxIndex: number): ReactElement {
    const { valid } = this.props;
    const selectOptions =
      /*this.filterSelected(*/ this.getOptionsByIdx(boxIndex); /*);*/
    const controlStyle = this.isError() ? { borderColor: "var(--danger)" } : {};
    if (this.props.onlyValues) {
      return (
        <div
          className={`d-flex align-items-center px-1 minified-react-select card-input npt-select flex-grow-1 rounded bg-secondary border`}
        >
          {this.printOption(this.findSelectOption(boxValue, selectOptions))}
        </div>
      );
    }
    const isInvalid = valid === false;

    return (
      <Select
        styles={{
          control: this.getControlStyle,
        }}
        searchable="true"
        classNamePrefix="cb-select"
        loadingPlaceholder={MSG_SELECT_LOADING}
        placeholder={MSG_SELECT_PLACEHOLDER}
        noResultsText={MSG_SELECT_NO_RESULTS}
        simpleValue={true}
        value={this.findSelectOption(boxValue, selectOptions)}
        onChange={this.changeHandler.bind(this, boxIndex)}
        isDisabled={!this.isEditable()}
        clearable={false}
        filterOptions={this.filterOptions.bind(this)}
        className={`minified-react-select card-input npt-select flex-grow-1 ${
          this.isError() ? styles.cbSelectError : ""
        }`}
        options={this.filterSelected(selectOptions)}
        formatOptionLabel={this.printOption.bind(this)}
        menuPosition="fixed"
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
      />
    );
  }

  printEmpty(): ReactElement {
    return this.printSelect(this.getDefaultValue(), 0);
  }
  resetEnumState() {
    this.setState({ lastLevelsMap: {} });
  }
  changeInvalidValue(props = this.props) {
    if (!props.editable) {
      return;
    }
    if (
      typeof props.value == "undefined" ||
      !this.isEnumerationReady(props) ||
      props.value == this.getDefaultValue() ||
      typeof this.state.nodeByValue[props.value] != "undefined"
    ) {
      return;
    }
    this.props.link(this.state.nodeByValue[this.getDefaultValue()]);
  }
  componentWillReceiveProps(nextProps: SingleComboBoxProps): void {
    const enumChanged =
      this.isEnumerationReady(nextProps) &&
      (this.props.enumerationInfo != nextProps.enumerationInfo ||
        this.props.automationFilter != nextProps.automationFilter);
    // if (this.props.enumerationInfo != nextProps.enumerationInfo && this.isEnumerationReady(nextProps)) {
    //     this.normalizeEnumeration(nextProps.enumerationInfo as EnumerationNode);
    //     this.setupRoot();
    //     this.selectValue(nextProps.value);
    // } else if (this.props.value != nextProps.value
    //     || (this.props.editable != nextProps.editable /*&& !nextProps.editable*/)) {

    //     if (!nextProps.value) {
    //         this.setState({ selectedValues: [""] })
    //     } else {
    //         this.selectValue(nextProps.value);
    //     }

    // }

    if (enumChanged && nextProps.enumerationInfo) {
      this.resetEnumState();
      this.normalizeEnumeration(
        nextProps.enumerationInfo,
        nextProps.automationFilter
      );
      this.setupRoot(!!this.props.dynamicEnumeration);
    }

    if (
      this.props.value != nextProps.value ||
      (this.props.editable != nextProps.editable && !nextProps.editable)
    ) {
      if (!nextProps.value) {
        this.setState({ selectedValues: [""] });
      } else {
        this.selectValue(nextProps.value);
      }
    }
    if (enumChanged) {
      const valueDepth = this.state.selectedValues.length - 1;
      const value = this.state.selectedValues[valueDepth];
      this.changeValue(value, valueDepth);
    }
    if (
      typeof nextProps.value != "undefined" &&
      nextProps.editable &&
      this.isEnumerationReady(nextProps) &&
      (!this.props.editable || !this.isEnumerationReady(this.props))
    ) {
      this.changeInvalidValue(nextProps);
    }
  }

  render() {
    if (this.isEmpty()) {
      return this.printEmpty();
    }
    let className: string;
    switch (this.getSelectionDepth()) {
      case 1:
        className = "col-md-12";
        break;
      case 2:
        className = "col-md-6";
        break;
      default:
        className = "col-md-4";
        break;
    }
    return (
      <div
        className={`no-gutters cim-combobox-row px-0 d-flex flex-wrap ${
          this.isEditable() ? " cim-combobox-editable" : ""
        } ${this.props.className || ""}`}
      >
        {this.getValues().map((value, index) =>
          this.printComboBox(value, index, className)
        )}
      </div>
    );
  }
}

interface MultilineComboBoxProps {
  link: (value: any) => any;
  emptyValueLabel: string;
  remove: (indexList: number[] | number) => any;
  enumerationInfo: EnumerationInfo;
  automationFilter?: any;
  dynamicEnumeration?: boolean;
  error?: boolean;
  loading?: boolean;
  hasEmptyValue?: boolean;
  empty?: boolean;
  editable?: boolean;
  allowPartialSelection?: boolean;
  values?: any[];
  menuShouldBlockScroll?: boolean;
  select?: (value: any) => void;
}
interface MultilineComboBoxState {
  addValue: any;
}
/**
 * Miltiline combobox / row of comboboxes
 */
export class MultilineComboBox extends React.Component<
  MultilineComboBoxProps,
  MultilineComboBoxState
> {
  constructor(props: MultilineComboBoxProps) {
    super(props);

    this.state = {
      addValue: this.getDefaultValue(),
    };

    this.changeAddValue = this.changeAddValue.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);
  }

  isError(): boolean {
    return Boolean(this.props.error);
  }

  isLoading(): boolean {
    return Boolean(this.props.loading);
  }

  isEmpty(): boolean {
    return (
      this.props.empty || (this.getValues().length == 0 && !this.isEditable())
    );
  }

  isEditable(): boolean {
    return Boolean(this.props.editable);
  }

  getValues(): any[] {
    return this.props.values
      ? this.props.values.filter((value) => !!value)
      : [];
  }

  getDefaultValue(): string {
    return "";
  }

  changeAddValue(value: any): void {
    this.setState(Object.assign({}, this.state, { addValue: value }));
    this.props.link(value);
  }

  getAddValue(): string {
    if (isEmptyObject(this.state.addValue)) {
      return this.getDefaultValue();
    }
    return this.state.addValue;
  }

  addHandler(event: any): void {
    let addValue = this.getAddValue();
    if (!addValue) {
      return;
    }
    this.changeAddValue(this.getDefaultValue());
    this.props.link(addValue);
  }

  removeHandler(rowIndex: number, event: any): void {
    if (rowIndex < 0) {
      return;
    }
    this.props.remove(rowIndex);
  }

  /* Prints comboBox element and add editing button (if needed) */
  printAddingComboBox(): ReactElement {
    const { hasEmptyValue } = this.props;
    return (
      <SingleComboBox
        link={this.changeAddValue}
        hasEmptyValue={hasEmptyValue}
        enumerationInfo={this.props.enumerationInfo}
        emptyValueLabel={this.props.emptyValueLabel}
        error={this.isError()}
        loading={this.isLoading()}
        empty={false}
        editable={this.isEditable()}
        allowPartialSelection={false}
        value={generateIdentifier(this.state.addValue)}
        values={this.props.values?.map((v) => generateIdentifier(v))}
        dynamicEnumeration={this.props.dynamicEnumeration}
        automationFilter={this.props.automationFilter}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
      />
    );
  }

  /* Prints comboBox element and add editing button (if needed) */
  printExistComboBox(value: any, rowIndex: number): ReactElement {
    const { editable } = this.props;
    const { hasEmptyValue } = this.props;
    let comboboxValue = null;
    if (value) {
      comboboxValue = (
        <div className="w-100">
          <SingleComboBox
            hasEmptyValue={hasEmptyValue}
            link={() => {}}
            emptyValueLabel={this.props.emptyValueLabel}
            enumerationInfo={this.props.enumerationInfo}
            onlyValues={true}
            value={value}
            dynamicEnumeration={this.props.dynamicEnumeration}
            automationFilter={this.props.automationFilter}
            menuShouldBlockScroll={this.props.menuShouldBlockScroll}
          />
        </div>
      );
    } else {
      comboboxValue = (
        <div className="w-100 mb-1 px-1 pt-1 rounded bg-secondary border shadow-sm minified-react-select card-input npt-select">
          --
        </div>
      );
    }

    return (
      <div className="d-flex flex-row justify-content-between">
        {comboboxValue}
        {editable && (
          <div className="d-flex flex-column justify-content-center mb-1">
            <div
              onClick={this.removeHandler.bind(this, rowIndex)}
              style={{ cursor: "pointer" }}
              className="ml-1 px-1 rounded border border-danger text-danger"
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </div>
          </div>
        )}
      </div>
    );
  }

  printEmpty() {
    return (
      <div
        className={
          "col-md-12 flex-row px-0" +
          (this.isEditable() ? " cim-combobox-editable" : "")
        }
      >
        {this.printExistComboBox(this.getDefaultValue(), 0)}
      </div>
    );
  }

  componentWillReceiveProps(nextProps: MultilineComboBoxProps) {
    if (this.props.editable != nextProps.editable && !nextProps.editable) {
      // this.changeAddValue(this.getDefaultValue());
    }
  }

  render() {
    if (this.isEmpty()) {
      return this.printEmpty();
    }
    return (
      <div
        className={
          "col-md-12 flex-row px-0" +
          (this.isEditable() ? " cim-combobox-editable" : "")
        }
      >
        {this.isEditable() && this.printAddingComboBox()}
        {this.getValues().map((value, index) => (
          <div key={shortid.generate()}>
            {this.printExistComboBox(value, index)}
          </div>
        ))}
      </div>
    );
  }
}

interface ComboboxWrapperProps {
  link: (event: any) => void;
  adding: boolean;
  editable: boolean;
}
/**
 * Wraper combobox component for adding/removing comboboxes
 */
class ComboboxWrapper extends React.Component<ComboboxWrapperProps> {
  constructor(props: ComboboxWrapperProps) {
    super(props);
  }

  isEditable() {
    return this.props.editable;
  }

  render() {
    let button;
    if (this.isEditable()) {
      if (this.props.adding) {
        /* Flag setted to true -> wrap element with adding button */
        button = (
          <i
            className={"fa fa-plus-circle fa-fw " + styles.addButton}
            aria-hidden="true"
            onClick={this.props.link}
          ></i>
        );
      } else {
        /* Flag setted to false -> wrap element with delete button */
        button = (
          <i
            className={"fa fa-minus-circle fa-fw " + styles.removeButton}
            aria-hidden="true"
            onClick={this.props.link}
          ></i>
        );
      }
    }
    return (
      <div className="col-md-12 cim-combobox-multiple cim-combobox-row ">
        {this.props.children}
        <div
          className={`cim-combobox-button position-absolute ${styles.button}`}
        >
          <span>{button}</span>
        </div>
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return { visible: false };
    }
    const node = subject && subject.nodeById[ownProps.nodeId];
    const value = subject && subject.values[ownProps.nodeId];
    const enumerationCls =
      state.subject &&
      node &&
      node.options.cls &&
      state.subject.enumerations[node.options.cls];

    const cardEditable = isCardEdit(subject);
    const editable = isEditable(subject, ownProps.nodeId, cardEditable);
    const visible = subject.visibility[ownProps.nodeId] ? true : false;

    const error = subject.validation[ownProps.nodeId];

    let valid = !error || !cardEditable ? true : false;
    let mandatory = subject.mandatorySet[node.id];
    if (
      mandatory &&
      (!value || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }
    return {
      node,
      data: subject?.subjectData,
      layout: subject,
      value,
      values: subject?.values,
      enumerationCls,
      editable,
      cardEditable,
      visible,
      error,
      valid,
      comment: subject.comment[ownProps.nodeId],
    };
  },
  (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
    ownProps: { subjectKey: string; nodeId: string }
  ) => {
    /* TODO */
    const { subjectKey, nodeId } = ownProps;
    return {
      link: (data: any) => dispatch(link(subjectKey, nodeId, data)),
      remove: (indexList: number[] | number) =>
        dispatch(remove(subjectKey, nodeId, indexList)),
      change: (data: any, options?: any) =>
        dispatch(change(subjectKey, nodeId, data, options)),
    };
  }
)(ComboBoxInput);
