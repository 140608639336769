import * as React from "react";
import {
  ModalInfo,
  ModalStatus,
  TableModalInfo,
  TreeModalInfo,
} from "../../types/modal";

import ModalView from "./ModalView";
import PasswordModal from "./templates/PasswordModal";
import DeleteModal from "./templates/DeleteModal";
import SubjectInfoModal from "./templates/SubjectInfo";
import ShowSaveChangesModal from "./templates/ShowSaveChanges";
import CardModal from "./templates/CardModal";
import RefTablePasteModal from "./templates/RefTablePasteModal";
import ConfirmRefTablePasteModal from "./templates/ConfirmRefTablePasteModal";
import AddRefTableModal from "./templates/AddRefTableModal";
import ConfirmModal from "./templates/ConfirmModal";
import AddScriptModal from "./templates/AddScriptModal";
import CardTableModal from "./templates/CardTableModal";
import TableSortOptionsModal from "./templates/TableSortOptionsModal";
import TableDynamicColumnsOptionsModal from "./templates/TableDynamicColumnsOptionsModal";
import QuizModal from "./templates/QuizModal";
import StringInputModal from "./templates/StringInputModal";
import AlertModal from "./templates/AlertModal";
import AvatarHelpModal from "./templates/AvatarHelpModal";
import PluginModal from "./templates/PluginModal";
import PortalModal from "./templates/PortalModal";
import TextInputModal from "./templates/TextInputModal";

export interface ModalApiProps {
  modal: ModalInfo;
  closeModal: (
    id: string,
    status: ModalStatus,
    result: any,
    extraData?: any
  ) => void;
}

const ModalApi: React.FunctionComponent<ModalApiProps> = React.memo((props) => {
  const modal = props.modal;
  switch (modal.type) {
    case "password":
      return (
        <PasswordModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );

    case "confirmDelete":
      return (
        <DeleteModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );

    case "subjectInfo":
      return (
        <SubjectInfoModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );

    case "showSaveChanges":
      return (
        <ShowSaveChangesModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "card":
      return (
        <CardModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "cimtable":
      return (
        <CardTableModal
          modal={modal as TableModalInfo}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "confirmItemRemoveModal":
      return (
        <DeleteModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "pasteRefTable":
      return (
        <RefTablePasteModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "confirmPasteRefTable":
      return (
        <ConfirmRefTablePasteModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "cimtree":
    case "addRefTable":
      return (
        <AddRefTableModal
          modal={modal as TreeModalInfo}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );

    case "confirmAction":
    case "confirm":
      return (
        <ConfirmModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "alert":
      return (
        <AlertModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "addScript":
      return (
        <AddScriptModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "tableSortOptions":
      return (
        <TableSortOptionsModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "tableDynamicColumnsOptions":
      return (
        <TableDynamicColumnsOptionsModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "quiz":
      return (
        <QuizModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );

    case "stringInput":
      return (
        <StringInputModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "invokeAvatarHelp":
      return (
        <AvatarHelpModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "plugin":
      return (
        <PluginModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "textInput":
      return (
        <TextInputModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
    case "portal":
      return (
        <PortalModal
          modal={modal}
          closeModal={props.closeModal.bind(null, modal.id)}
        />
      );
  }
  return (
    <ModalView
      modal={modal}
      closeModal={props.closeModal.bind(null, modal.id)}
    />
  );
});

export default ModalApi;
