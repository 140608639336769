import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ServerError } from '../../actions/utils';
import { buildUrl } from '../../services/location';
import SockJS from "sockjs-client";
import * as StompJs from "@stomp/stompjs";
import { IMessage } from '@stomp/stompjs';


export interface SessionAction {
    name: string
}

export interface SessionHeader {
    uuid: string
    token: string
    state: { [P: string]: any }
    //Action name -> worker UUID
    workers: { [ACTION: string]: string }
    actionList: SessionAction[]
}

export interface SessionEvent {
    token?: string
    key: string
    value: any
}

export interface SessionWorkerEvent {
    action: string
    worker: string
    workerEventType: "STARTED" | "SUCCESS" | "ERROR" | "REMOVED"
    label?: string
}

export interface SessionOnLoadEvent {
    target: Session
}

export type SessionCheckReadyFunction = (state: { [P: string]: any }) => boolean

export interface SessionProps {
    session: string
    systemObject?: string
    checkReady?: boolean | string | SessionCheckReadyFunction
    onLoad?: (event: SessionOnLoadEvent) => void
    [P: string]: any
}

export interface SessionState {
    loading: boolean,
    error: boolean,
    sessionState: { [P: string]: any }
    //Action name -> worker UUID
    workersState: { [ACTION: string]: string }
}

export interface SessionStateSubscriber {
    sessionStateChanged: (state: { [P: string]: any }) => void
    workersStateChanged: (workers: { [ACTION: string]: string }) => void
}

export interface SessionValueChange {
    type: "SET" | "TOGGLE" | "ADD",
    key: string,
    field?: string,
    value: any,
    token?: string
    systemObject?: string
}

export type SessionActionFunction = (...args: any[]) => void

export type SessionActionDispatcher = { [ACTION: string]: SessionActionFunction }

export interface SessionInterface {
    dispatch: SessionActionDispatcher
    subscribeStateChanges: (sub: SessionStateSubscriber) => void
    unsubscribeStateChanges: (sub: SessionStateSubscriber) => void
    applyStateChanges: (change: SessionValueChange) => void
    reload: (smooth?: boolean) => Promise<void>
    drop: () => Promise<void>
}

export const SessionContext = React.createContext<SessionInterface>({
    dispatch: {},
    subscribeStateChanges: () => { },
    unsubscribeStateChanges: () => { },
    applyStateChanges: () => { },
    reload: async () => { },
    drop: async () => { }
});

const TIMEOUT = 10000

export default class Session extends React.PureComponent<SessionProps, SessionState> {

    private uuid: string = '';
    private token?: string;
    private stomp: StompJs.CompatClient | null = null;
    private subscribers: SessionStateSubscriber[] = []
    private parent: SessionInterface | null = null;

    //Make public
    public dispatch: SessionActionDispatcher = {};

    constructor(props: SessionProps) {
        super(props)
        this.state = {
            loading: true, //Always mark loading on start
            error: false,
            sessionState: {},
            workersState: {}
        }
    }

    isChildSession = () => {
        return typeof this.props.session == 'undefined' || this.props.session == '';
    }

    componentDidMount(): void {
        if (!this.isChildSession()) {
            this.reload()
        }
    }

    componentWillUnmount(): void {
        if (this.stomp) {
            this.stomp.disconnect();
            this.stomp = null;
        }
        if (this.parent) {
            this.parent.unsubscribeStateChanges(this);
            this.parent = null;
        }
    }

    subscribeStateChanges = (sub: SessionStateSubscriber) => {
        this.subscribers.push(sub);
        //Inform about current state
        sub.sessionStateChanged(this.state.sessionState)
    }

    unsubscribeStateChanges = (sub: SessionStateSubscriber) => {
        this.subscribers = this.subscribers.filter(x => x != sub);
    }

    sessionStateChanged = (sessionState: { [P: string]: any }) => {
        this.setState({ loading: false, sessionState })
    }

    workersStateChanged = (workersState: { [ACTION: string]: string }) => {
        this.setState({ workersState })
    }

    applyStateChanges = (change: SessionValueChange) => {
        if (this.parent) {
            //Send changes to parent
            this.parent.applyStateChanges(change)
        } else {
            //Update value locally
            this.updateValueInLocalState(change);
            //Send changes to server
            const payload = { ...change };
            payload.token = this.token;
            payload.systemObject = this.props.systemObject;
            fetch('/rest/sess/chg' + this.props.session, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }).then((resp) => {
                if (resp.ok) {
                    //Read for console
                    resp.json();
                } else {
                    //Read for console
                    resp.text().then((error) => {
                        console.error("Failed change value", change, error)
                    })
                }
            }, (error) => {
                console.error("Failed change value", change, error)
            })
        }
    }

    dispatchAction = (name: string, parameters: any[]) => {
        fetch('/rest/sess/act' + this.props.session, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                systemObject: this.props.systemObject,
                parameters
            })
        }).then((resp) => {
            if (resp.ok) {
                //Read for console
                resp.json();
            } else {
                //Read for console
                resp.text().then((error) => {
                    console.error("Failed to execute action", name, error)
                })
            }
        }, (error) => {
            console.error("Failed to execute action", name, error)
        })
    }

    createActionDispatcher = (name: string): SessionActionFunction => {
        return (...args: any[]) => {
            this.dispatchAction(name, args)
        }
    }

    updateValueInLocalState = (change: SessionValueChange) => {
        this.setState((state) => {
            const sessionState = { ...state.sessionState };
            if (change.field) {
                let map = sessionState[change.key];
                if (typeof map != 'object' || map == null) {
                    map = {}
                }
                this.updateValueInMap(change, change.field, map);
            } else {
                this.updateValueInMap(change, change.key, sessionState);
            }
            return { ...state, sessionState }
        })
    }

    updateValueInMap = (change: SessionValueChange, field: string, map: { [P: string]: any }) => {
        const current = map[field];
        switch (change.type) {
            case "SET":
                map[field] = change.value
                break;
            case "ADD":
                if (typeof change.value == 'number') {
                    if (typeof current == 'number') {
                        map[field] = current + change.value;
                    } else {
                        map[field] = change.value;
                    }
                }
                break;
            case "TOGGLE":
                if (typeof current == 'undefined') {
                    map[field] = true;
                } else if (typeof current == 'boolean') {
                    map[field] = !current;
                }
                break
            default:
                console.error("Unknown session state operation: ", change);
        }
    }

    setStateValue = (key: string, value: object) => {
        this.applyStateChanges({
            type: "SET",
            key, value
        })
    }

    toggleValue = (key: string) => {
        this.applyStateChanges({
            type: "TOGGLE",
            key, value: null
        })
    }

    addValue = (key: string, value: number) => {
        this.applyStateChanges({
            type: "ADD",
            key, value
        })
    }

    setFieldValue = (key: string, field: string, value: any) => {
        this.applyStateChanges({
            type: "SET",
            key, field, value
        })
    }

    toggleField = (key: string, field: string) => {
        this.applyStateChanges({
            type: "TOGGLE",
            key, field, value: null
        })
    }

    addToField = (key: string, field: string, value: number) => {
        this.applyStateChanges({
            type: "ADD",
            key, field, value
        })
    }

    drop = async () => {

        //Use context session here?
        if (this.isChildSession()) {
            if (this.parent) {
                await this.parent.drop();
            }
            return;
        }

        const { session, systemObject } = this.props;
        const fetchUrl = '/rest/sess/header' + session

        let search: { [k: string]: string } | null = null;
        if (systemObject) {
            search = {}
            search.obj = systemObject;
        }

        try {
            const url = buildUrl({ url: fetchUrl, search });

            const resp = await fetch(url, {
                method: "DELETE"
            });

            if (!resp.ok) {
                throw new ServerError(resp.status, resp.statusText);;
            }

            const header = (await resp.json()) as SessionHeader;
            console.log("SESSION WAS DROPPED: ", header)

            await this.reload();
        } catch (e) {
            this.setState({
                loading: false,
                error: true
            });
            console.error(e);
        }
    }

    reload = async (smooth?: boolean) => {

        //Use context session here?
        if (this.isChildSession()) {
            if (this.parent) {
                await this.parent.reload(smooth);
            }
            return;
        }

        const { session, systemObject } = this.props;
        const fetchUrl = '/rest/sess/header' + session

        let search: { [k: string]: string } | null = null;
        if (systemObject) {
            search = {}
            search.obj = systemObject;
        }
        if (!smooth) {
            this.setState({
                loading: true,
                error: false
            });
        }
        try {
            const url = buildUrl({ url: fetchUrl, search });

            const resp = await fetch(url);
            if (!resp.ok) {
                throw new ServerError(resp.status, resp.statusText);;
            }

            const header = (await resp.json()) as SessionHeader;

            this.dispatch = {};
            for (let action of header.actionList) {
                this.dispatch[action.name] = this.createActionDispatcher(action.name)
            }

            this.setState({
                loading: false,
                error: false,
                sessionState: header.state
            })

            this.token = header.token;
            if (this.uuid != header.uuid) {
                this.uuid = header.uuid;
                this.reconnectImmediatly();
            }
        } catch (e) {
            this.setState({
                loading: false,
                error: true
            });
            console.error(e);
        }
    }

    reconnectImmediatly = () => {
        if (this.stomp) {
            try {
                this.stomp.disconnect();
            } catch (e) {
                console.error("Failed to call stomp disconnect", e);
            }
            this.stomp = null;
        }
        this.connect();
    }

    connect = () => {
        if (this.stomp) { //Already connected
            return
        }
        try {
            const location = window.location;
            const url = location.protocol + "//" + location.host + "/ws/event";

            console.log("Connecting...", url);

            const stomp = StompJs.Stomp.over(() => {
                const socket = new SockJS(url);
                socket.onerror = (e: any) => {
                    console.error("Web socket error: ", e);
                }
                return socket;
            })

            stomp.onStompError = (frame: StompJs.IFrame) => {
                console.error("Stomp error: ", frame);
            }

            stomp.onWebSocketError = (evt: any) => {
                console.error("Stomp web socket erro: ", evt);
            }

            stomp.reconnectDelay = TIMEOUT

            this.stomp = stomp;
            stomp.connect({}, () => { //Connected
                const stateTopic = "sess-" + this.uuid;
                stomp.subscribe('/topic/' + stateTopic, (message: IMessage) => {
                    if (message.body) {
                        const event = JSON.parse(message.body) as SessionEvent;
                        if (event.token != this.token) {
                            this.setState((state) => {
                                const sessionState = { ...state.sessionState };
                                sessionState[event.key] = event.value;
                                return { ...state, sessionState };
                            })
                        }
                    }
                });
                const workerTopic = "sess-worker-" + this.uuid;
                stomp.subscribe('/topic/' + workerTopic, (message: IMessage) => {
                    if (message.body) {
                        const event = JSON.parse(message.body) as SessionWorkerEvent;
                        this.setState((state) => {
                            const workersState = { ...state.workersState };
                            switch (event.workerEventType) {
                                case 'STARTED': {
                                    workersState[event.action] = event.worker;
                                    break;
                                }
                                case 'REMOVED':
                                case 'SUCCESS':
                                case 'ERROR': {
                                    delete workersState[event.action];
                                    break;
                                }
                                default: {
                                    console.error("Unknown worker event type", event.workerEventType)
                                }
                            }
                            return { ...state, workersState };
                        })
                    }
                });
            })
        } catch (e) {
            console.error("Failed to connect to session updates: " + e);
        }
    }

    componentDidUpdate(prevProps: Readonly<SessionProps>, prevState: Readonly<SessionState>, snapshot?: any): void {
        if (!this.isChildSession()) {
            if (prevState.sessionState != this.state.sessionState) {
                for (let sub of this.subscribers) {
                    sub.sessionStateChanged(this.state.sessionState)
                }
            }
            if (prevState.workersState != this.state.workersState) {
                for (let sub of this.subscribers) {
                    sub.workersStateChanged(this.state.workersState)
                }
            }
        }
        if (prevState.loading && !this.state.loading && !this.state.error) {
            const { onLoad } = this.props;
            if (typeof onLoad == 'function') {
                onLoad({
                    target: this
                });
            }
        }
    }

    getCheckReadyFunction = (): SessionCheckReadyFunction => {
        const { checkReady } = this.props;
        if (typeof checkReady == 'boolean') {
            return () => checkReady;
        }
        if (typeof checkReady == 'string') {
            return (sessionState) => sessionState[checkReady] ? true : false;
        }
        if (typeof checkReady == 'function') {
            return checkReady;
        }
        return () => true;

    }

    renderChild(parent?: SessionInterface) {

        if (parent) {
            if (this.parent != parent) {
                this.parent = parent
                this.dispatch = parent.dispatch
                parent.subscribeStateChanges(this)
                //Will be rendered on subscribe
                return null;
            }
        }

        const checkReady = this.getCheckReadyFunction();
        if (!checkReady(this.state.sessionState)) {
            return <FillPlaceholder>
                <LoadingPlaceholder />
            </FillPlaceholder>;
        }

        const { children, ...rest } = this.props;

        const childProps = {
            ...rest,
            state: this.state.sessionState,
            workers: this.state.workersState,
            reload: this.reload,
            drop: this.drop,
            dispatch: this.dispatch,
            setStateValue: this.setStateValue,
            toggleValue: this.toggleValue,
            addValue: this.addValue,
            setFieldValue: this.setFieldValue,
            toggleField: this.toggleField,
            addToField: this.addToField,
        }

        const count = React.Children.count(children)
        if (count == 0) {
            return null
        } else if (count == 1) {
            const el = React.Children.only(children);
            if (React.isValidElement<any>(el)) {
                return React.cloneElement(el, childProps)
            }
            console.error("Invalid element type in session", el)
            return null;
        } else {
            return <>
                {
                    React.Children.map(children, (el) => {
                        if (React.isValidElement<any>(el)) {
                            return React.cloneElement(el, childProps)
                        }
                        console.error("Invalid element type in session", el)
                        return null
                    })
                }
            </>
        }
    }

    render() {
        if (this.isChildSession()) {
            return <SessionContext.Consumer>
                {parent => this.renderChild(parent)}
            </SessionContext.Consumer>
        } else {
            if (this.state.error) {
                return <FillPlaceholder>
                    <ErrorPlaceholder fetchData={this.reload} />
                </FillPlaceholder>;
            }
            if (this.state.loading) {
                return <FillPlaceholder>
                    <LoadingPlaceholder />
                </FillPlaceholder>;
            }
            return <SessionContext.Provider value={this}>
                {this.renderChild()}
            </SessionContext.Provider>
        }
    }

}

const FillPlaceholder = React.memo((props) => <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    {props.children}
</div>);

interface ErrorPlaceholderProps {
    fetchData: () => void
}
const ErrorPlaceholder: React.FunctionComponent<ErrorPlaceholderProps> = React.memo((props) => <div className="alert alert-danger">
    <FormattedMessage
        id="NPT_SESSION_ERROR"
        defaultMessage="Error occured during session information fetch"
        description="Fetch error placeholder"
    />
    <button className="btn btn-danger ml-2" onClick={props.fetchData}>
        <i className="fa fa-refresh"></i>
    </button>
</div>);

const LoadingPlaceholder = React.memo(() => <div className="d-flex align-items-center alert alert-info">
    <i className="fa fa-circle-o-notch fa-spin fa-2x mr-2"></i>
    <FormattedMessage
        id="NPT_SESSION_LOADING"
        defaultMessage="Loading..."
        description="Session loading placeholder"
    />
</div>);