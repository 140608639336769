import * as React from "react";
import { AutoSizer } from "react-virtualized";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import shortid from "shortid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { ApplicationState } from "../../types";
import {
  Column,
  FilterData,
  FilterInfo,
  GantDateLimits,
  GantViewType,
  RowData,
  SelectType,
  SortInfo,
  TableDragOptions,
  TableDropOptions,
  TableFilterChanges,
  TableGantData,
  TableGantOptions,
  TableParameter,
  TableRequest,
  ToolbarItem,
  ToolbarReport,
} from "../../types/table";
import { FetchError } from "../../types/error";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
} from "../../types/modal";
import { AlertLevelType } from "../../types/alert";
import { LoggedInUser } from "../../types/security";
import { FinderOptions } from "../../types/finder";

import { openModal } from "../../actions/modal";
import { addAlert } from "../../actions/alert";
import {
  buttonClick,
  downloadReport,
  sendSelectAll,
  sendSelectRow,
  fetchTableData,
  changePageSize,
  changePage,
  sortByColumn,
  sendGantViewType,
  sendGantSelectedGroup,
  saveTable,
  changeGantElement,
  filterByColumn,
  openTableSortOptions,
  openTableDynamicColumns,
  sendField,
  confirmFilterChanges,
  resolveDrop,
  cancelFilterChanges,
} from "../../actions/table";
import { getSelectedRowsData } from "../../services/table";

import TableFinderContainer from "./TableFinderContainer";
import TableContainer from "./TableContainer";

import styles from "./Table.module.css";

export const LoadingAlert = () => (
  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    <div className="alert alert-info align-self-center" role="alert">
      <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
      <FormattedMessage
        id="NPT_TABLE_LOADING"
        defaultMessage="Loading..."
        description="Table loading alert"
      />
    </div>
  </div>
);

export const LoadingError = () => (
  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    <div className="alert alert-danger align-self-center" role="alert">
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
      <FormattedMessage
        id="NPT_TABLE_ERROR"
        defaultMessage="Error occured while downloading data"
        description="Info text on table data downloading error"
      />
    </div>
  </div>
);

/**Properties of Table (without actions for correct extending on api) */
export interface PlainTableProps {
  /**Table ID. Using it to identify redux store data location.*/
  tableId: string;
  /**Height of table (100% by default) */
  height?: number;
  /**Scrollable flag of table (true by default) */
  scrollable?: boolean;
  /**Display indicator that table is loading*/
  loading: boolean;
  /**Display indicator that table data is loading*/
  loadingData: boolean;
  /**Stylesheet map*/
  stylesheets: { [k: string]: string };
  /**Info about table parameters*/
  parameters: { [k: string]: TableParameter };
  /**Values of table parameters*/
  fields: { [k: string]: string };
  /**Forced fields values that will be traversed to table */
  forcedFields?: { [k: string]: string };
  /**Report items of table*/
  reports: ToolbarReport[];
  /**Error data that occured on table loading*/
  error: null | FetchError;
  /**Error data that occured on table data loading*/
  errorData: null | FetchError;
  /**Flag that show if this table have pagination */
  pageable: boolean;
  /**Total number of rows */
  totalRowsLength: number;
  /**Toolbar visibility flag */
  toolbarHidden?: boolean;
  /**Toolbar items visibility flag */
  toolbarHiddenItems?: { [k: string]: boolean };
  /**Selected page of table */
  page?: number;
  /**Size of single table page */
  pageSize?: number;
  /**Possible values of table page size */
  pageSelection?: number[];
  /**Array of toolbar items*/
  toolbar: ToolbarItem[];
  /**Array of table columns*/
  columns: Column[];
  /**List of selected columns for filter */
  dynamicColumns: string[] | null;
  /**Map of dynamically hidden columns for filter */
  dynamicHiddenColumns: { [k: string]: boolean };
  /**Array of table rows ids*/
  pageRows: number[];
  /**Map of row data by it idx */
  rowByIdx: { [rowIdx: number]: RowData };
  /**Select type of table (null if table rows cannot be selected) */
  selectType: SelectType | null;
  /**Map of row selected flag by key */
  selectedRows: { [k: string]: boolean };
  /**Counter of selected rows */
  selectedRowsLength: number;
  /**Info about table sorting */
  sortInfo: SortInfo;
  /**Info about table filtering */
  filterInfo: FilterInfo;
  /**Changings in filter */
  filterChanges: TableFilterChanges | null;
  /**Options of table finder (if exists) */
  finderOptions: FinderOptions | null;
  /**Options of gant diagram (if exists) */
  gantOptions: TableGantOptions | null;
  /**Data of gant diagram (if exists) */
  gantData: TableGantData;
  /**Table drag options */
  dragOptions: TableDragOptions | null;
  /**Table drop options */
  dropOptions: TableDropOptions | null;
}
/**Full properties of table */
export interface TableProps extends PlainTableProps {
  forceLoadData?: boolean;
  /**Context path of application (needed for link cells)*/
  contextPath: string;
  /**Values of fields in selection store (allows table to react for url changings)*/
  selectionFields: { [k: string]: string };
  /** List of user rights */
  userAcl: string[];
  /**Action to trigger report downloading*/
  downloadReport: (report: ToolbarReport) => void;
  /**Action to select row */
  selectRow: (key: string) => void;
  /**Action to select all rows on page */
  selectAll: () => void;
  /**Action to call automation for clicked button */
  buttonClick: (id: string) => void;
  /**Action to update table data with new fields values */
  updateTableFields: (
    fields: { [k: string]: string },
    forceLoadData?: boolean
  ) => void;
  /**Action to change table page size */
  changePageSize: (pageSize: number) => void;
  /**Action to change table selected page */
  changePage: (page: number) => void;
  /**Action to sort table by column*/
  sortByColumn: (field: string) => void;
  /**Action to filter table by column*/
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  /**Fetch available selection values for specified field and filter*/
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  /**Fetch available values range for specified field and filter*/
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
  /**Open sorting and filtering options modal*/
  openTableSortOptions: () => void;
  /**Open dynamic columns options modal*/
  openTableDynamicColumns: () => void;
  /**Action to field value */
  changeField: (parameter: string, value: string) => void;
  /**Action to change view type of gant diagram */
  changeViewType: (type: GantViewType) => void;
  /**Action to change id of adding gant element group */
  changeGroup: (group: string) => void;
  /**Handler of gant row element changings */
  changeGantElement: (
    rowIndex: number,
    itemIndex: number,
    newDate: GantDateLimits,
    isPlanned: boolean
  ) => void;
  /**Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  /**Action to confirm table filter changings */
  confirmFilterChanges: () => void;
  /**Action to deny table filter changings */
  denyFilterChanges: () => void;
  /**Action to save table changings */
  saveTable: () => void;
  /**Function that will be called to dynamically change request filter */
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;

  /**DnD actions */
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}

interface TableState {
  dynamicColumns: Column[] | null;
}

/********************************
 *     Main Table Component     *
 ********************************/
class Table extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);

    this.state = {
      dynamicColumns: this.getDynamicColumns(),
    };
  }

  componentDidMount() {
    if (this.props.onSelectionChange && this.props.selectedRows) {
      this.props.onSelectionChange(
        this.props.selectedRows,
        getSelectedRowsData(this.props.rowByIdx, this.props.selectedRows)
      );
    }
  }

  componentWillReceiveProps(nextProps: TableProps) {
    if (
      this.props.onSelectionChange &&
      (this.props.selectedRows !== nextProps.selectedRows ||
        this.props.rowByIdx !== nextProps.rowByIdx)
    ) {
      this.props.onSelectionChange(
        nextProps.selectedRows,
        getSelectedRowsData(nextProps.rowByIdx, nextProps.selectedRows)
      );
    }
    if (
      !nextProps.loadingData &&
      this.props.selectionFields !== nextProps.selectionFields &&
      this.props.selectionFields !== initialSelectionFields &&
      JSON.stringify(this.props.selectionFields) !==
        JSON.stringify(nextProps.selectionFields)
    ) {
      this.props.updateTableFields(
        nextProps.selectionFields,
        this.props.forceLoadData
      );
    }

    if (
      this.props.columns !== nextProps.columns ||
      this.props.dynamicColumns !== nextProps.dynamicColumns
    ) {
      this.setState({ dynamicColumns: this.getDynamicColumns(nextProps) });
    }
  }

  getDynamicColumns(props = this.props): Column[] | null {
    const dynamicColumns = props.dynamicColumns;
    if (!dynamicColumns) {
      return null;
    }
    const filteredColumns = props.columns.filter(
      (column) => dynamicColumns.indexOf(column.field) !== -1
    );
    const sortedColumns = filteredColumns.sort(
      (columnA, columnB) =>
        dynamicColumns.indexOf(columnA.field) -
        dynamicColumns.indexOf(columnB.field)
    );
    /**Display selected hidden columns */
    return sortedColumns.map((column) => {
      if (!column.hidden || !column.dynamic) {
        return column;
      }
      return Object.assign({}, column, { hidden: false });
    });
  }

  render() {
    if (this.props.loading) {
      return <LoadingAlert />;
    }
    if (this.props.error) {
      return <LoadingError />;
    }
    if (this.props.scrollable === false) {
      return (
        <div className={styles.nptTable + " d-flex flex-column mb-auto h-auto"}>
          <TableFinderContainer
            {...this.props}
            selectedDynamicColumns={this.state.dynamicColumns}
          >
            <AutoSizer style={{ width: "100%", height: "auto" }}>
              {({ width }) => (
                <TableContainer
                  {...this.props}
                  width={width}
                  height={-1}
                  selectedDynamicColumns={this.state.dynamicColumns}
                />
              )}
            </AutoSizer>
          </TableFinderContainer>
        </div>
      );
    }
    if (typeof this.props.height === "number") {
      return (
        <div className={styles.nptTable + " d-flex flex-column mb-auto"}>
          <TableFinderContainer
            {...this.props}
            selectedDynamicColumns={this.state.dynamicColumns}
          >
            <AutoSizer style={{ width: "100%", height: this.props.height }}>
              {({ width }) => (
                <TableContainer
                  {...this.props}
                  width={width}
                  height={this.props.height as number}
                  selectedDynamicColumns={this.state.dynamicColumns}
                />
              )}
            </AutoSizer>
          </TableFinderContainer>
        </div>
      );
    }
    return (
      <div className={styles.nptTable + " d-flex flex-column mb-auto"}>
        <TableFinderContainer
          {...this.props}
          selectedDynamicColumns={this.state.dynamicColumns}
        >
          <AutoSizer style={{ width: "100%", height: "100%" }}>
            {({ width, height }) => (
              <TableContainer
                {...this.props}
                width={width}
                height={height}
                selectedDynamicColumns={this.state.dynamicColumns}
              />
            )}
          </AutoSizer>
        </TableFinderContainer>
      </div>
    );
  }
}

/**Using variable out of connect function to be able to watch changes by variable ref */
const initialSelectionFields = {};
let selectionFields: { [k: string]: string } = initialSelectionFields;

/** Connect table to actions */
export default connect(
  (state: ApplicationState, ownProps: PlainTableProps) => {
    const newSelectionFields: { [k: string]: string } = {};
    let selectionChanged = false;
    for (let p in ownProps.parameters) {
      if (p === "object" || p === "type") {
        if (!state.selection.info || !state.selection.info[p]) {
          if (selectionFields[p]) {
            selectionChanged = true;
          }
          continue;
        }
        newSelectionFields[p] = state.selection.info[p] as string;
        if (selectionFields[p] !== newSelectionFields[p]) {
          selectionChanged = true;
        }
        continue;
      }
      if (!state.location.params[p]) {
        if (selectionFields[p]) {
          selectionChanged = true;
        }
        continue;
      }
      newSelectionFields[p] = state.location.params[p];
      if (selectionFields[p] !== newSelectionFields[p]) {
        selectionChanged = true;
      }
    }
    /**Update stored selection field only on changes to prevent table re-render */
    if (selectionChanged) {
      selectionFields = newSelectionFields;
    }

    return {
      selectionFields: selectionFields,
      contextPath: state.location.contextPath,
      userAcl:
        (state.security.loginStatus as LoggedInUser)?.generalAccessRules || [],
      ...ownProps,
    };
  },
  (
    dispatch: ThunkDispatch<{}, {}, any>,
    ownProps: {
      tableId: string;
      onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
    }
  ) => {
    const { tableId } = ownProps;
    return {
      downloadReport: (report: ToolbarReport) => {
        dispatch(downloadReport(tableId, report, ownProps.onFetch));
      },
      selectRow: (key: string) => {
        dispatch(sendSelectRow(tableId, key));
      },
      selectAll: () => {
        dispatch(sendSelectAll(tableId));
      },
      buttonClick: (id: string) => {
        dispatch(buttonClick(tableId, id, ownProps.onFetch));
      },
      updateTableFields: (
        fields: { [k: string]: string },
        forceLoadData?: boolean
      ) => {
        dispatch(
          fetchTableData(
            tableId,
            {
              fields,
              finder: null,
              reset: true,
              onFetch: ownProps.onFetch,
            },
            forceLoadData
          )
        );
      },
      changePageSize: (pageSize: number) => {
        dispatch(changePageSize(tableId, pageSize, ownProps.onFetch));
      },
      changePage: (page: number) => {
        dispatch(changePage(tableId, page, ownProps.onFetch));
      },
      sortByColumn: (field: string) => {
        dispatch(sortByColumn(tableId, field));
      },
      filterByColumn: (field: string, filterData: FilterData | null) => {
        dispatch(filterByColumn(tableId, field, filterData));
      },
      openTableSortOptions: () => {
        dispatch(openTableSortOptions(tableId));
      },
      openTableDynamicColumns: () => {
        dispatch(openTableDynamicColumns(tableId));
      },
      changeField: (parameter: string, value: string) => {
        dispatch(sendField(tableId, parameter, value));
      },
      changeViewType: (type: GantViewType) => {
        dispatch(sendGantViewType(tableId, type));
      },
      changeGroup: (group: string) => {
        dispatch(sendGantSelectedGroup(tableId, group));
      },
      changeGantElement: (
        rowIndex: number,
        itemIndex: number,
        newDate: GantDateLimits,
        isPlanned: boolean
      ) => {
        dispatch(
          changeGantElement(tableId, rowIndex, itemIndex, newDate, isPlanned)
        );
      },
      confirmFilterChanges: () => {
        dispatch(confirmFilterChanges(tableId, ownProps.onFetch));
      },
      denyFilterChanges: () => {
        dispatch(cancelFilterChanges(tableId));
      },
      saveTable: () => {
        dispatch(saveTable(tableId, ownProps.onFetch));
      },
      openModal: (
        type: string,
        options: ModalOptions,
        okCallback?: OkCallback,
        cancelCallback?: CancelCallback,
        closeCallback?: CloseCallback
      ) => {
        dispatch(
          openModal(
            shortid.generate(),
            type,
            options,
            okCallback,
            cancelCallback,
            closeCallback
          )
        );
      },
      addAlert: (type: AlertLevelType, message: string | I18NString) => {
        dispatch(addAlert(type, message));
      },
      resolveDrop: (
        collectedData: any,
        dropInfo: {
          cell?: any;
          row?: RowData;
          rowIdx?: number;
          column?: string;
          columnIdx?: number;
        },
        resolveDropFunctionId: string
      ) => {
        dispatch(
          resolveDrop(
            tableId,
            collectedData,
            dropInfo,
            resolveDropFunctionId,
            ownProps.onFetch
          )
        );
      },
    };
  }
)(Table);
