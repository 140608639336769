import moment from "moment";
import shortid from "shortid";
import { COLUMN_MIN_WIDTH } from "../constants/table";
import {
    AutomationBindingFunctions,
    AutomationBindings,
    Column,
    RowData,
    TableState,
    GantGroup,
    TableGantOptions,
    isStringFilterData,
    ColumnFilterInfo,
    isNumberFilterData,
    isDateFilterData,
    isDateTimeFilterData,
    isBooleanFilterData,
    TableRequest
} from "../types/table";
import { registerFunction } from "./automation";
import { getSearchData } from "./location";

/**
 * Special middleware type of column
 */
interface CalculateColumn {
    /**Index of column */
    idx: number,
    /**Width of column in px */
    width: number,
    /**Minimal width of column in px */
    minWidth?: number
}

/**
 * Using to get initial column values, when column coefficients isn't calculated yet.
 * 
 * Column widths calculation algorithm:
 * 
 * 1. Calculate sum of min columns widths;
 *   1.1. If sum is bigger than table width - return minimal widths of columns;
 * 2. Calculate widths of columns with % width value;
 * 3. Setup widths of columns with defined width;
 * 4. Calculate available table width;
 * 5. Calculate widths of columns with auto width value;
 *   5.1. Check if autosize is smaller than columns individual minimal width;
 *   5.2. If columns with minimal width larger thar calcualted were found - recalculate awailable width and go to step 5.1;
 *   5.3. Setup calculated width for rest columns;
 * 6. Calculate result width;
 * 7. If result width is equal to init table width - continue to step 9;
 *   7.1. Calculate width used by columns with width value defined in model with px;
 *     7.1.1. If used width is smaller than table width - stretch columns and go to step 7.2;
 *     7.1.2. Else constrict columns (use sum of their widths and available width as total width of table for algorithm);
 *   7.2. Setup calculated widths to columns;
 * 8. If result width is equal to init table width - continue to step 9;
 *   8.1. Calculate width used by columns with width value defined in model with %;
 *     8.1.1. If used width is smaller than table width - stretch columns and go to step 8.2;
 *     8.1.2. Else constrict columns (use sum of their widths and available width as total width of table for algorithm
 *                                                and calculated width in px as column.width field value);
 *   8.2. Setup calculated widths to columns;
 * 9. Return calculated widths.
 * 
 * @param columns array of table columns with field "width" (in px or percentage);
 * @param width width of table;
 * 
 * @returns array of columns widths.
 */
function calculateColumnsWidths({ columns, width }: { columns: Column[], width: number }): number[] | null {
    if (!columns) {
        console.warn("Can't calculate width of columns (undefined columns)");
        return null;
    }
    if (!columns.length) {
        return null;
    }
    if (!width) {
        console.warn("Can't calculate width of columns (undefined width)");
        return null;
    }
    const columnsWidths: number[] = [];
    /* 1. Calculate sum of min columns widths; */
    /*   1.1. If sum is bigger than table width - return minimal widths of columns; */
    if (getColumnsMinWidth({ columns }) >= width) {
        for (let column of columns) {
            if (column.hidden) {
                columnsWidths.push(0);
                continue;
            }
            columnsWidths.push(getColumnMinWidth(column));
        }
        return columnsWidths;
    }
    /* 2. Calculate widths of columns with % width value; */
    /* 3. Setup widths of columns with defined width; */
    /* 4. Calculate available table width; */
    let autoColumns = 0;
    let availableWidth = width;
    for (let column of columns) {
        if (column.hidden) {
            columnsWidths.push(0);
            continue;
        }
        if (typeof column.originalWidth == "undefined" || column.originalWidth == null) {
            columnsWidths.push(-1);
            ++autoColumns;
            continue;
        }
        let columnWidth = 0;
        if (typeof column.originalWidth == "string" && column.originalWidth.indexOf("%") !== -1) {
            columnWidth = getPercentageWidth(column.originalWidth, width);
        } else {
            columnWidth = Number(column.width);
        }
        const columnMinWidth = getColumnMinWidth(column);
        if (columnWidth < columnMinWidth) {
            columnWidth = columnMinWidth;
        }
        columnsWidths.push(columnWidth);
        availableWidth -= columnWidth;
    }
    /* 5. Calculate widths of columns with auto width value; */
    /* 5.1. Check if autosize is smaller than columns individual minimal width; */
    /* 5.2. If columns with minimal width larger thar calcualted were found - recalculate awailable width and go to step 5.1; */
    let continueFlag;
    do {
        continueFlag = false;
        let autosizeColumnWidth = availableWidth / autoColumns;
        for (let i = 0; i < columns.length; ++i) {
            const column = columns[i];
            const width = columnsWidths[i];
            if (width !== -1) {
                continue;
            }
            const columnMinWidth = getColumnMinWidth(column);
            if (autosizeColumnWidth >= columnMinWidth) {
                continue;
            }
            columnsWidths[i] = columnMinWidth;
            availableWidth -= columnMinWidth;
            --autoColumns;
            continueFlag = true;
        }
    } while (continueFlag);
    /* 5.3. Setup calculated width for rest columns; */
    let autosizeColumnWidth = availableWidth / autoColumns;
    for (let i = 0; i < columns.length; ++i) {
        const width = columnsWidths[i];
        if (width !== -1) {
            continue;
        }
        columnsWidths[i] = autosizeColumnWidth;
        availableWidth -= autosizeColumnWidth;
    }
    /* 6. Calculate result width; */
    /* 7. If result width is equal to init table width - continue to step 9; */
    if (Math.abs(availableWidth) < 0.1) {
        /* Param availableWidth can be not equal zero due to mathematical computer losses */
        return columnsWidths;
    }
    /* 7.1. Calculate width used by columns with width value defined in model with px; */
    let usedWidth = 0;
    let selectedColumns: CalculateColumn[] = [];
    for (let i = 0; i < columns.length; ++i) {
        if (typeof columns[i].originalWidth == "undefined" || (typeof columns[i].originalWidth == "string" && (columns[i].originalWidth as string).indexOf("%") !== -1)) {
            continue;
        }
        selectedColumns.push({
            idx: i,
            width: columnsWidths[i],
            minWidth: columns[i].minWidth
        });
        usedWidth += columnsWidths[i];
    }
    let selectedColumnsWidth;
    /* 7.1.1. If used width is smaller than table width - stretch columns and go to step 7.2; */
    if (usedWidth < width) {
        selectedColumnsWidth = stretchColumns({ columns: selectedColumns, originalWidth: usedWidth, width: usedWidth + availableWidth });
    } else {
        /* 7.1.2. Else constrict columns (use sum of their widths and available width as total width of table for algorithm); */
        selectedColumnsWidth = constrictColumns({ columns: selectedColumns, originalWidth: usedWidth, width: usedWidth + availableWidth });
    }
    /* 7.2. Setup calculated widths to columns; */
    for (let i = 0; i < selectedColumns.length; ++i) {
        const column = selectedColumns[i];
        const newColumnWidth = selectedColumnsWidth[i];
        columnsWidths[column.idx] = newColumnWidth;
        availableWidth -= newColumnWidth - column.width;
    }
    /* 8. If result width is equal to init table width - continue to step 9; */
    if (Math.abs(availableWidth) < 0.1) {
        /* Param availableWidth can be not equal zero due to mathematical computer losses */
        return columnsWidths;
    }
    /* 8.1. Calculate width used by columns with width value defined in model with %; */
    usedWidth = 0;
    selectedColumns = [];
    for (let i = 0; i < columns.length; ++i) {
        if (typeof columns[i].originalWidth != "string" || (columns[i].originalWidth as string).indexOf("%") === -1) {
            continue;
        }
        selectedColumns.push({
            idx: i,
            width: columnsWidths[i]
        });
        usedWidth += columnsWidths[i];
    }

    /* 8.1.1. If used width is smaller than table width - stretch columns and go to step 8.2; */
    if (usedWidth < width) {
        selectedColumnsWidth = stretchColumns({ columns: selectedColumns, originalWidth: usedWidth, width: usedWidth + availableWidth });
    } else {
        /* 8.1.2. Else constrict columns (use sum of their widths and available width as total width of table for algorithm
         *                                            and calculated width in px as column.width field value); */
        selectedColumnsWidth = constrictColumns({ columns: selectedColumns, originalWidth: usedWidth, width: usedWidth + availableWidth });
    }
    /* 8.2. Setup calculated widths to columns; */
    for (let i = 0; i < selectedColumns.length; ++i) {
        const column = selectedColumns[i];
        const newColumnWidth = selectedColumnsWidth[i];
        columnsWidths[column.idx] = newColumnWidth;
        availableWidth -= newColumnWidth - column.width;
    }
    if (availableWidth > 0.1) {
        /* Param availableWidth can be not equal zero due to mathematical computer losses */
        console.warn("Calculation of columns width failed, available width left: ", availableWidth);
    }
    /* 9. Return calculated widths. */
    return columnsWidths;
}

function getColumnsMinWidth({ columns }: { columns: Column[] }): number {
    let minWidth = 0;
    for (let column of columns) {
        if (column.hidden) {
            continue;
        }
        minWidth += getColumnMinWidth(column);
    }
    return minWidth;
}

function getColumnMinWidth(column: { minWidth?: number }): number {
    return column.minWidth || COLUMN_MIN_WIDTH;
}

function getPercentageWidth(percentageString: string, width: number): number {
    let percentage = Number(percentageString.replace("%", "")) / 100;
    return width * percentage;
}

/**
 * Stretch columns to defined width by columns width coefficients.
 *  
 * @param columns array of table columns with field "width" (in px);
 * @param originalWidth current sum width of columns;
 * @param width new sum width of columns;
 * 
 * @returns array of columns widths.
 */
function stretchColumns({ columns, originalWidth, width }: { columns: CalculateColumn[], originalWidth: number, width: number }): number[] {
    let columnsWidths: number[] = [];
    for (let column of columns) {
        columnsWidths.push(column.width / originalWidth * width);
    }
    return columnsWidths;
}

/**
 * Constrict columns to defined width by columns width coefficients.
 *  
 * @param columns array of table columns with field "width" (in px) and "minWidth" (in px);
 * @param originalWidth current sum width of columns;
 * @param width new sum width of columns;
 * 
 * @returns array of columns widths.
 */
function constrictColumns({ columns, originalWidth, width }: { columns: CalculateColumn[], originalWidth: number, width: number }): number[] {
    const columnsWidths = [];
    for (let column of columns) {
        columnsWidths.push(column.width / originalWidth * width);
    }
    return checkMinimalWidth({ columns, columnsWidths });
}

/**
 * Check columns minimal widths and try to redefine columns widths for correct fit of current width.
 * 
 * Table columns width minimal value check algorithm:
 * 
 * 1. For each column check if calculated width is smaller than minimal width for column - setup minimal width value and store difference;
 * 2. For each column calculate width coefficient;
 * 3. Remove from all non-minimal width columns difference * column.widthCoeff;
 *   3.1. If resulted width of column is smaller than minimal width for column - setup minimal width value and add current width 
 *                                                                               difference to total difference;
 * 4. If width difference is not zero and not all columns values is setup to minimal - go to step 1;
 * 5. Return calculated widths.
 * 
 * @param columns array of table columns with field "width" (in px or percentage) and field "minWidth" (in px);
 * @param columnsWidths widths of table columns;
 * 
 * @returns array of columns widths.
 */
function checkMinimalWidth({ columns, columnsWidths }: { columns: Column[] | CalculateColumn[], columnsWidths: number[] }): number[] {
    columnsWidths = columnsWidths.slice();
    let columnsWidthsCoeff: { [index: number]: number } = {};
    let minimalColumns: { [index: number]: boolean } = {};
    let allMinimal = false;
    let widthDifference = 0;
    let reducedDifference = 0;
    let nonMinimalColumnsWidth = 0;
    do {
        reducedDifference = 0;
        nonMinimalColumnsWidth = 0;
        allMinimal = true;
        columnsWidthsCoeff = {};
        /* 1. For each column check if calculated width is smaller than minimal width for column - setup minimal width value and store difference; */
        for (let i = 0; i < columns.length; ++i) {
            if (minimalColumns[i]) {
                continue;
            }
            const column = columns[i];
            const minWidth = getColumnMinWidth(column);
            let width = columnsWidths[i];
            if (width < minWidth) {
                widthDifference += minWidth - width;
                width = minWidth;
                minimalColumns[i] = true;
            } else if (width > minWidth) {
                nonMinimalColumnsWidth += width;
                allMinimal = false;
            }
            columnsWidths[i] = width;
        }
        /* 2. For each column calculate width coefficient; */
        for (let i = 0; i < columns.length; ++i) {
            if (minimalColumns[i]) {
                continue;
            }
            columnsWidthsCoeff[i] = columnsWidths[i] / nonMinimalColumnsWidth;
        }
        /* 3. Remove from all non-minimal width columns difference * column width coeff; */
        for (let i = 0; i < columns.length; ++i) {
            if (minimalColumns[i]) {
                continue;
            }
            const column = columns[i];
            const minWidth = getColumnMinWidth(column);
            const reducingColumnWidth = widthDifference * columnsWidthsCoeff[i];
            let width = columnsWidths[i] - reducingColumnWidth;
            reducedDifference += reducingColumnWidth;
            /* 3.1. If resulted width of column is smaller than minimal width for column - setup minimal width value and add current width 
             *                                                                             difference to total difference; */
            if (width < minWidth) {
                reducedDifference -= minWidth - width;
                width = minWidth;
                minimalColumns[i] = true;
            }
            columnsWidths[i] = width;
        }
        widthDifference -= reducedDifference;
        /* 4. If width difference is not zero and not all columns values is setup to minimal - go to step 1; */
        /* Param widthDifference can be not equal zero due to mathematical computer losses */
    } while (Math.abs(widthDifference) > 0.1 && !allMinimal);
    /* 5. Return calculated widths. */
    return columnsWidths;
}

/**
 * Used as common function to get widths of columns.
 * 
 * Table columns width receive algorithm:
 * 
 * 1. Check all columns if "widthCoeff" is defined;
 *   1.1. Not all coefficients is defined (columns wasn't resized) - use getColumnsWidth function to get widths and go to step 4;
 * 2. For each column calculate width by coefficient;
 * 3. Check for minimal width of columns;
 * 4. Return calculated widths.
 * 
 * @param columns array of table columns with field "width" (in px or percentage) and field "widthCoeff" (number <= 1);
 * @param width width of table;
 * 
 * @returns array of columns widths.
 */
export function getColumnsWidths({ columns, width }: { columns: Column[], width: number }): number[] | null {
    if (!columns) {
        console.warn("Can't calculate width of columns (undefined columns)");
        return null;
    }
    if (!columns.length) {
        return null;
    }
    if (!width) {
        console.warn("Can't calculate width of columns (undefined width)");
        return null;
    }
    let columnsWidths = [];
    /* 1. Check all columns if "widthCoeff" is defined */
    for (let column of columns) {
        if (column.hidden) {
            columnsWidths.push(0);
            continue;
        }
        if (typeof column.widthCoeff == "undefined" || column.widthCoeff == null) {
            /* 1.1. Not all coefficients is defined (columns wasn't resized) - use getColumnsWidth function to get widths and go to step 4; */
            return calculateColumnsWidths({ columns, width });
        }
        /* 2. For each column calculate width by coefficient; */
        columnsWidths.push(column.widthCoeff * width);
    }
    /* 3. Check for minimal width of columns; */
    columnsWidths = checkMinimalWidth({ columns, columnsWidths });
    /* 4. Return calculated widths. */
    return columnsWidths;
}

/**
 * Column width change algorithm:
 *
 * 1. If all width coefficients is defined - go to step 2;
 *   1.1. Setup width coefficients;
 * 2. While constrict column is already have minimal width - choose next in order column;
 * 3. If all constrict columns have minimal width - go to step 8;
 * 4. Reduce width of constrict column by defined value;
 *   4.1. If new width is smaller than minimal - setup overflow change value, reduce original change value by it
 *                                               and set column value equal to minimal;
 * 5. Add change value to enlarge column;
 * 6. Calculate new coefficients;
 * 7. If overflow change value is not equal 0 - recursively get new coefficients;
 * 8. Return calculated values.
 * 
 * @param columns array of table columns with field "widthCoeff" (number < 1);
 * @param width width of table;
 * @param changeValue change value in px;
 * @param enlargeColumnIdx index of enlarge column;
 * @param constrictColumnIdx index of constrict column;
 * 
 * @returns array of columns widths coefficients.
 */
export function resizeColumns({
    columns,
    width,
    columnsWidths = null,
    widthCoefficients = null,
    changeValue,
    enlargeColumnIdx,
    constrictColumnIdx
}: {
    columns: Column[],
    width: number,
    columnsWidths: number[] | null,
    widthCoefficients: number[] | null,
    changeValue: number,
    enlargeColumnIdx: number,
    constrictColumnIdx: number
}): number[] | null {
    if (typeof columnsWidths == "undefined" || columnsWidths == null) {
        columnsWidths = getColumnsWidths({ columns, width });
    }
    if (columnsWidths == null) {
        return widthCoefficients;
    }
    /* 1. If all width coefficients is defined - go to step 2; */
    /*   1.1. Setup width coefficients; */
    if (widthCoefficients == null) {
        widthCoefficients = [];
        for (let i = 0; i < columns.length; ++i) {
            const column = columns[i];
            if (typeof column.widthCoeff != "undefined" && column.widthCoeff != null) {
                widthCoefficients.push(column.widthCoeff);
            } else {
                widthCoefficients.push(columnsWidths[i] / width);
            }
        }
    }
    /* 2. While constrict column is already have minimal width - choose next in order column; */
    let sideStep = constrictColumnIdx < enlargeColumnIdx ? -1 : 1;
    while (constrictColumnIdx >= 0 && constrictColumnIdx < columns.length) {
        if (columnsWidths[constrictColumnIdx] > getColumnMinWidth(columns[constrictColumnIdx])) {
            break;
        }
        constrictColumnIdx += sideStep;
    }
    /* 3. If all constrict columns have minimal width - go to step 8; */
    if (constrictColumnIdx < 0 || constrictColumnIdx >= columns.length) {
        return widthCoefficients;
    }
    /* 5. Reduce width of constrict column by defined value; */
    columnsWidths[constrictColumnIdx] -= changeValue;
    /* 4.1. If new width is smaller than minimal - setup overflow change value, reduce original change value by it
     *                                             and set column value equal to minimal; */
    const minCostrictColumnWidth = getColumnMinWidth(columns[constrictColumnIdx]);
    let overflowChangedValue = 0;
    if (columnsWidths[constrictColumnIdx] < minCostrictColumnWidth) {
        overflowChangedValue = minCostrictColumnWidth - columnsWidths[constrictColumnIdx];
        changeValue -= overflowChangedValue;
        columnsWidths[constrictColumnIdx] = minCostrictColumnWidth;
    }
    /* 5. Add change value to enlarge column; */
    columnsWidths[enlargeColumnIdx] += changeValue;
    /* 6. Calculate new coefficients; */
    widthCoefficients[constrictColumnIdx] = columnsWidths[constrictColumnIdx] / width;
    widthCoefficients[enlargeColumnIdx] = columnsWidths[enlargeColumnIdx] / width;
    /* 7. If overflow change value is not equal 0 - recursively get new coefficients; */
    if (overflowChangedValue !== 0) {
        widthCoefficients = resizeColumns({ columns, width, columnsWidths, widthCoefficients, changeValue: overflowChangedValue, enlargeColumnIdx, constrictColumnIdx });
    }
    /* 8. Return calculated values. */
    return widthCoefficients;
}

export function buildRequestParams(parameters: { [k: string]: any }, fields: { [k: string]: any }, searchParams: { [k: string]: any }): { [k: string]: string } {
    var res: { [k: string]: string } = {};
    if (!searchParams) {
        searchParams = getSearchData();
    }
    for (let p in parameters) {
        const value = fields[p] || searchParams[p];
        if (value) {
            res[p] = value;
        }
    }
    return res;
}

export function generateTableBindings(automation: AutomationBindings): AutomationBindingFunctions {
    return {
        bindCellClass: function (col: string, func: Function) { //function(cell, row, rowIndex, columnIndex)
            if (!automation.cellClassBindings) {
                automation.cellClassBindings = {};
            }
            automation.cellClassBindings[col] = registerFunction(func);
        },
        bindValue: function (col: string, func: Function) { //function(cell, row)
            if (!automation.valueBindings) {
                automation.valueBindings = {};
            }
            automation.valueBindings[col] = registerFunction(func);
        },
        bindText: function (col: string, func: Function) { //function(cell, row)
            if (!automation.textBindings) {
                automation.textBindings = {};
            }
            automation.textBindings[col] = registerFunction(func);
        },
        bindVisible: function (col: string, func: Function) {//function(rows, fields)
            if (!automation.visibilityBindings) {
                automation.visibilityBindings = {};
            }
            automation.visibilityBindings[col] = registerFunction(func);
        },
        bindToolbarVisible: function (id: string, func: Function) {//function(rows, fields)
            if (!automation.visibilityToolbarBindings) {
                automation.visibilityToolbarBindings = {};
            }
            automation.visibilityToolbarBindings[id] = registerFunction(func);
        },
        bindAccum: function (accum: string, initialValue: any, func: Function) { //function (row, prev)
            if (!automation.accumInitialValues) {
                automation.accumInitialValues = {};
            }
            if (!automation.accumBindings) {
                automation.accumBindings = {};
            }
            automation.accumInitialValues[accum] = initialValue;
            automation.accumBindings[accum] = registerFunction(func);
        },
        bindFilterAccum: function (accum: string, initialValue: any, func: Function) { //function (row, prev)
            if (!automation.accumInitialValues) {
                automation.accumInitialValues = {};
            }
            if (!automation.accumFilterBindings) {
                automation.accumFilterBindings = {};
            }
            automation.accumInitialValues[accum] = initialValue;
            automation.accumFilterBindings[accum] = registerFunction(func);
        },
        bindClick: function (id: string, func: Function) {//function(table)
            if (!automation.clickBindings) {
                automation.clickBindings = {};
            }
            automation.clickBindings[id] = registerFunction(func);
        },
        bindPostProcess: function (func: Function) {//function(fields, rows)
            automation.postProcessBinding = registerFunction(func);
        }
    };
}

/**Safe get indexes of displaying rows after filtering and sorting */
export function getTableRowsIndexes(tableState: TableState): number[] {
    return tableState.pageRows;
}

/**Get data rows by indexes */
export function getTableRowsDataList(tableState: TableState, rowsIdxs: number[]): RowData[] {
    const rowDataList: RowData[] = [];
    for (let idx of rowsIdxs) {
        rowDataList.push(tableState.rowByIdx[idx]);
    }
    return rowDataList;
}

/**Safe get data of displaying rows after filtering and sorting */
export function getTableRows(tableState: TableState): RowData[] {
    const rowsIdxs = getTableRowsIndexes(tableState);
    return getTableRowsDataList(tableState, rowsIdxs);
}

/**Get row data for automation */
export function getRowAutomationData(row: RowData) {
    if (!row.bindedData) {
        return row.data;
    }
    return Object.assign({}, row.data, row.bindedData);
}

/**Get row value for specified field */
export function getRowValue(row: RowData, field: string) {
    if (!row || !field) {
        return null;
    }
    if (typeof row.changedData[field] !== "undefined") {
        return row.changedData[field];
    }
    if (typeof row.bindedData[field] !== "undefined") {
        return row.bindedData[field];
    }
    if (typeof row.data[field] !== "undefined") {
        return row.data[field];
    }
    return null;
}

/**Parse value and convert it to date */
export function valueToDate(value: any) {
    if (typeof value !== "string" && typeof value !== "number") {
        return null;
    }
    return moment(value);
}

/**Parse value and convert it to unified date string */
export function valueToISODateString(value: any) {
    const date = valueToDate(value);
    if (date === null) {
        return null;
    }
    return date.format('DD-MM-YYYY')
}

/**Parse value and convert it to unified date string with time */
export function valueToISODateTimeString(value: any) {
    const date = valueToDate(value);
    if (date === null) {
        return null;
    }
    return date.toISOString();
}

/**Parse value and convert it to localized date string */
export function valueToDateString(value: any) {
    const date = valueToDate(value);
    if (date === null) {
        return null;
    }
    return date.format('L')
}

/**Parse value and convert it to localized date string with time */
export function valueToDateTimeString(value: any) {
    const date = valueToDate(value);
    if (date === null) {
        return null;
    }
    return date.format('L') + ' ' + date.format('LTS');
}

/**Parses a string of inline styles into a javascript object with casing for react */
export function parseStyles(styles: string): React.CSSProperties {
    return styles
        .split(';')
        .filter(style => style.split(':')[0] && style.split(':')[1])
        .map(style => [
            style.split(':')[0].trim().replace(/^-ms-/, 'ms-').replace(/-./g, c => c.substr(1).toUpperCase()),
            style.split(':').slice(1).join(':').trim()
        ])
        .reduce((styleObj, style) => ({
            ...styleObj,
            [style[0]]: style[1],
        }), {});
}

/**Check if requested page for selected page size was already fetched or is fetching at the moment */
export function isPageFetched(tableState: TableState, page: number, pageSize: number) {
    const offset = page * pageSize;
    const p_ps = page + "_" + pageSize;
    if (tableState.loadingPages[p_ps]) {
        return true;
    }
    const endIdx = Math.min(offset + pageSize, tableState.totalRowsLength);
    for (let i = offset; i < endIdx; ++i) {
        if (!tableState.rowByIdx[i]) {
            return false;
        }
    }
    return true;
}

/**Check if table is single page */
export function isSinglePageTable(tableState: TableState) {
    return !tableState.pageable || tableState.totalRowsLength <= tableState.pageSize;
}

/**Check column filter data to identify if filter is active */
export function isColumnFilterActive(columnFilterInfo?: ColumnFilterInfo): boolean {
    if (!columnFilterInfo) {
        return false;
    }
    if (isStringFilterData(columnFilterInfo.data)) {
        return Boolean(columnFilterInfo.data.contain || columnFilterInfo.data.selected);
    }
    if (isNumberFilterData(columnFilterInfo.data)) {
        return typeof columnFilterInfo.data.value !== "undefined"
            || typeof columnFilterInfo.data.valueFrom !== "undefined"
            || typeof columnFilterInfo.data.valueTo !== "undefined";
    }
    if (isDateFilterData(columnFilterInfo.data)) {
        return typeof columnFilterInfo.data.value !== "undefined"
            || typeof columnFilterInfo.data.valueFrom !== "undefined"
            || typeof columnFilterInfo.data.valueTo !== "undefined";
    }
    if (isDateTimeFilterData(columnFilterInfo.data)) {
        return typeof columnFilterInfo.data.value !== "undefined"
            || typeof columnFilterInfo.data.valueFrom !== "undefined"
            || typeof columnFilterInfo.data.valueTo !== "undefined";
    }
    if (isBooleanFilterData(columnFilterInfo.data)) {
        return typeof columnFilterInfo.data.selected !== "undefined";
    }
    return false;
}

/**Check if color value is valid */
export function isValidColor(color?: any): boolean {
    if (typeof color != "string") {
        return false;
    }
    color = color.replace(/ /g, "");
    const validator = new RegExp("^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$|^rgb\\((([0-9]{1,2}|[0-1][0-9][0-9]|2[0-4][0-9]|25[0-5]),){2}([0-9]{1,2}|[0-1][0-9][0-9]|2[0-4][0-9]|25[0-5])\\)$|^rgba\\((([0-9]{1,2}|[0-1][0-9][0-9]|2[0-4][0-9]|25[0-5]),){3}(1(\\.0)*|0|0\\.[0-9]+)\\)");
    if (!validator.test(color)) {
        console.warn("Invalid color string:", color);
        return false;
    }
    return true;
}

/* get random number in range 0...max*/
export function getRandomInteger(max: number): number {
    return Math.floor(Math.random() * (max + 1));
}

/* Create random RGB color*/
export function getRandomRGB(): string {
    return "rgb(" + getRandomInteger(255) + "," + getRandomInteger(255) + "," + getRandomInteger(255) + ")";
}

/**Create gant group and fill missing data */
export function createGantGroup(id?: string, name?: string, color?: string): GantGroup {
    const validColor = isValidColor(color) ? color as string : getRandomRGB();
    return {
        id: id || shortid.generate(),
        name: name || shortid.generate(),
        color: validColor,
        borderColor: shadeBlendConvert(-0.25, validColor) || "#000"
    }
}

export function getGantGroupMap(gantOptions: TableGantOptions): { [k: string]: GantGroup } {
    const groupsMap: { [k: string]: GantGroup } = {};
    for (let group of gantOptions.gantGroups) {
        groupsMap[group.id] = group;
    }
    return groupsMap;
}

export function getUnidentifiedGroup(gantOptions: TableGantOptions): GantGroup {
    for (let group of gantOptions.gantGroups) {
        if (group.id === "_unidentified") {
            return group;
        }
    }
    return createGantGroup("_unidentified", "?");
}

/**Try to parse any input data to array (prevent error, when server responds with object instead of array) */
export function parseToArray(data: any): any[] {
    if (Array.isArray(data)) {
        return data;
    }
    const array: any[] = [];
    if (typeof data === "undefined" || data === null) {
        return array;
    }
    if (typeof data === "object") {
        for (let key in data) {
            array.push(data[key]);
        }
    } else {
        array.push(data);
    }
    return array;
}

/**Get gradient value for multiple-group gant item */
export function getGradientColor(colors: string[]): string | null {
    if (!colors || !colors.length) {
        return null;
    }
    const step = 100 / colors.length;
    const disperse = 2 / colors.length;
    let currentPosition = 0;
    let background = "linear-gradient(135deg"
    for (let i = 0; i < colors.length; ++i) {
        const color = colors[i];
        const leftDisperse = i == 0 ? 0 : disperse;
        const rightDisperse = i == colors.length - 1 ? 0 : disperse;
        background += `, ${color} ${currentPosition + leftDisperse}%`;
        currentPosition += step;
        background += `, ${color} ${currentPosition - rightDisperse}%`;
    }
    background += ")";
    return background;
}

export function findInArrayByKeys(args: { array: any[], keys: any[], key: any }) {
    let result = [];
    const { array, key, keys } = args
    for (let obj of array) {
        for (let i = keys.length - 1; i >= 0; --i) {
            if (keys[i] == obj[key]) {
                result.push(obj);
                keys.splice(i, 1);
                break;
            }
        }
        if (keys.length == 0) {
            break;
        }
    }
    return result;
}

/**Get rows data by selection */
export function getSelectedRowsData(rowByIdx: { [rowIdx: number]: RowData }, selectedRows: { [k: string]: boolean; }): RowData[] {
    let rows: RowData[] = [];
    for (let i in rowByIdx) {
        if (!selectedRows[rowByIdx[i].data.key]) {
            continue;
        }
        rows.push(rowByIdx[i]);
    }
    return rows;
}

/**Check if table needed to use post request to get data */
export function isFilterNeeded(tableRequest: TableRequest): boolean {
    return Boolean(tableRequest.filter?.finder || tableRequest.filter?.columnFilters);
}

/**Compose table fields and special data to search string */
export function getTableSearchFields(tableRequest: TableRequest): { [k: string]: string } {
    const fields: { [k: string]: string } = { ...tableRequest.parameters };
    if (tableRequest.model) {
        fields._m = tableRequest.model
    }
    if (tableRequest.searchInput) {
        fields._search = tableRequest.searchInput;
    }
    if (tableRequest.range) {
        if (tableRequest.range.limit) {
            fields._ps = tableRequest.range.limit.toString();
            if (typeof tableRequest.range.offset !== "undefined") {
                fields._p = (tableRequest.range.offset / tableRequest.range.limit + 1).toString();
            } else {
                fields._p = "1";
            }
        }
        if (tableRequest.range.sortItemList?.length) {
            let sortingItems: string[] = [];
            for (let item of tableRequest.range.sortItemList) {
                sortingItems.push(`${item.name}+${item.ascending ? "asc" : "desc"}`)
            }
            fields._s = sortingItems.join(",");
        }
    }
    if (tableRequest.filter?.columns) {
        fields._c = tableRequest.filter.columns.join(",");
    }
    return fields;
}

/* Create new color based on existing */
export function shadeBlendConvert(p: number, from: string, to: string = ""): string | null {
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (from) != "string" || (from[0] != 'r' && from[0] != '#') || (typeof (to) != "string" && typeof (to) != "undefined"))
        return null; //ErrorCheck
    let sbcRip = function (d: any) {
        var l = d.length;
        var RGB: any = new Object();
        if (l > 9) {
            d = d.split(",");
            if (d.length < 3 || d.length > 4) return null;//ErrorCheck
            RGB[0] = i(d[0].slice(4)); RGB[1] = i(d[1]); RGB[2] = i(d[2]); RGB[3] = d[3] ? parseFloat(d[3]) : -1;
        } else {
            if (l == 8 || l == 6 || l < 4) return null; //ErrorCheck
            if (l < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (l > 4 ? d[4] + "" + d[4] : ""); //3 digit
            d = i(d.slice(1), 16);
            RGB[0] = d >> 16 & 255; RGB[1] = d >> 8 & 255; RGB[2] = d & 255; RGB[3] = l == 9 || l == 5 ? r(((d >> 24 & 255) / 255) * 10000) / 10000 : -1;
        }
        return RGB;
    }
    var i = parseInt;
    var r = Math.round;
    var h = from.length > 9;
    h = typeof (to) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h;
    var b = p < 0;
    p = b ? p * -1 : p;
    to = to && to != "c" ? to : b ? "#000000" : "#FFFFFF";
    var f = sbcRip(from);
    var t = sbcRip(to);
    if (!f || !t) return null; //ErrorCheck
    if (h) return "rgb(" + r((t[0] - f[0]) * p + f[0]) + "," + r((t[1] - f[1]) * p + f[1]) + "," + r((t[2] - f[2]) * p + f[2]) + (f[3] < 0 && t[3] < 0 ? ")" : "," + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 10000) / 10000 : t[3] < 0 ? f[3] : t[3]) + ")");
    else return "#" + (0x100000000 + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 255) : t[3] > -1 ? r(t[3] * 255) : f[3] > -1 ? r(f[3] * 255) : 255) * 0x1000000 + r((t[0] - f[0]) * p + f[0]) * 0x10000 + r((t[1] - f[1]) * p + f[1]) * 0x100 + r((t[2] - f[2]) * p + f[2])).toString(16).slice(f[3] > -1 || t[3] > -1 ? 1 : 3);
}