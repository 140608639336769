import { Reducer } from "redux";
import { ApplicationAction } from "../types";
import {
  SubjectState,
  EnumerationClass,
  Validation,
  Layout,
  Visibility,
  Lock,
  SubjectDiff,
  isSubject,
  LayoutStatus,
  Subject,
  OperationType,
  SaveStateType,
  ActiveTabs,
} from "../types/subject";
import * as constants from "../constants/subject";
import { parseFragmentList } from "./fragment";
import { makeForm } from "../actions/subject";
import { isEmptyObject } from "../services/app";
import { retrieveFunction } from "../services/automation";
import { isFetchError } from "../types/error";

export const DEFAULT_SUBJECT_STATE: SubjectState = {
  subjects: {},
  layouts: {},
  loading: {},
  enumerations: {},
  layoutsStatus: {},
  layoutsLoading: {},
  changeLoading: {},
  saveState: {},
  loadingLock: {},
};

/**
 * Leaf nodes are valid if they were not set invalid
 * Branch nodes are valid if they do not contain invalid children
 */
function rebuildValidationTree(
  validation: Validation,
  layout: Layout,
  nodeId: string
) {
  // if (!nodeId) {
  //     const rootNodes = layout.rootNodesIds;
  //     for (let id of rootNodes) {
  //         const valid = rebuildValidationTree(validation, layout, id);
  //         if (valid === undefined) {
  //             continue;
  //         }
  //         validation[id] = valid;
  //     }
  // } else {
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    return validation[nodeId] ? false : true;
  }
  let valid = true;
  for (let childId of childrenIds) {
    //Recursively revalidate
    if (!rebuildValidationTree(validation, layout, childId)) {
      valid = false;
    }
  }
  validation[nodeId] = !valid;
  return valid;
  // }
}

/**
 * Leaf nodes are visible if they were not hidden
 * Branch nodes ara visible if they were not hidden and have one visible child
 */
function rebuildVisibilityTree(
  visibility: Visibility,
  layout: Layout,
  nodeId: string
) {
  // if (!nodeId) {
  //     const rootNodes = layout.rootNodesIds;
  //     for (let id of rootNodes) {
  //         const visible = rebuildVisibilityTree(visibility, layout, id);
  //         if (visible === undefined) {
  //             continue
  //         }
  //         visibility[id] = visible;
  //     }
  // } else {
  if (!visibility[nodeId]) {
    //Node was hidden!
    return false;
  }
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    return true; //Leaf nodes are visible if not hidden!
  }
  //Branches are visible if one of the child nodes is visible
  visibility[nodeId] = false;
  for (let childId of childrenIds) {
    //Recursively rebuild visibility tree
    if (rebuildVisibilityTree(visibility, layout, childId)) {
      visibility[nodeId] = true;
    }
  }
  return visibility[nodeId];
  // }
}

function makeVisible(layout: Layout, visibilityDemand: Visibility) {
  const visibility: Visibility = { ...visibilityDemand };
  for (let nodeId in layout.nodeById) {
    const demand = visibilityDemand[nodeId];
    if (typeof demand == "boolean") {
      visibility[nodeId] = demand;
    } else if (layout.nodeById[nodeId].hidden) {
      visibility[nodeId] = false;
    } else {
      visibility[nodeId] = true;
    }
  }
  return visibility;
}

/**
 * Locks are propogated from upped level to lower layers
 */
function propogateLock(lock: Lock, layout: Layout, nodeId: string) {
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    //no children
    return;
  }
  for (let childId of childrenIds) {
    lock[childId] = true;
    propogateLock(lock, layout, childId);
  }
}

/**
 * Leaf nodes are locked if they were locked or if parent was locked (lock is propogated)
 * Branch nodes are locked if they were locked or if all children are locked
 */
function rebuildLockTree(lock: Lock, layout: Layout, nodeId: string) {
  if (lock[nodeId]) {
    propogateLock(lock, layout, nodeId);
    return true;
  }

  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    lock[nodeId] = false;
    return false;
  }
  if (childrenIds.length == 0) {
    lock[nodeId] = false; //Branch is not locked because it doesn't contain any children
  } else {
    lock[nodeId] = true; //Set lock by default for branches
    for (let childId of childrenIds) {
      if (!rebuildLockTree(lock, layout, childId)) {
        lock[nodeId] = false; //one of children is not locked. So branch is not locked
      }
    }
  }
  return lock[nodeId];
}

function updateCommentBindings(subject: Subject) {
  if (!subject.automation) {
    return;
  }
  subject.comment = { ...subject.comment };
  const form = makeForm(subject.values, subject.subjectData);
  for (let predicateId in subject.automation.commentBindings) {
    const funcId = subject.automation.commentBindings[predicateId];
    const func = retrieveFunction(funcId);
    try {
      const comment = func(subject.values[predicateId], form);
      if (typeof comment === "undefined" || comment === null) {
        delete subject.comment[predicateId];
        continue;
      }
      if (typeof comment === "string") {
        subject.comment[predicateId] = {
          text: comment,
        };
        continue;
      }
      if (typeof comment === "object") {
        subject.comment[predicateId] = {
          text: comment.text,
          color: comment.color,
          isHtml: comment.isHtml,
          hideOnLock: comment.hideOnLock,
          hideOnEdit: comment.hideOnEdit,
          hideOnCardLock: comment.hideOnCardLock,
          hideOnCardEdit: comment.hideOnCardEdit,
        };
        continue;
      }
      subject.comment[predicateId] = {
        text: comment.toString(),
      };
    } catch (ex) {
      console.log("Comment binding error", predicateId, ex);
    }
  }
}

function layoutReceived(
  state: SubjectState,
  subjectKey: string,
  className: string,
  layout: Layout,
  storeDiffList?: SubjectDiff[]
): SubjectState {
  const layoutsStatus: LayoutStatus = {
    ...state.layoutsStatus,
    [className]: constants.STATUS_READY,
  };
  const layoutsLoading = { ...state.layoutsLoading, [className]: false };
  const layouts = { ...state.layouts, [className]: layout };

  let newSubjects = { ...state.subjects };

  if (storeDiffList && storeDiffList.length > 0) {
    for (let diff of storeDiffList) {
      const subjectKey = diff.subjectKey;
      let subject = { ...newSubjects[subjectKey] };
      if (!isSubject(subject)) {
        continue;
      }

      subject.values = { ...subject.values, ...diff.values };

      if (layout.rootId) {
        subject.validation = { ...subject.validation, ...diff.validation };
        rebuildValidationTree(subject.validation, layout, layout.rootId);

        subject.visibilityDemand = { ...diff.visibility };
        subject.visibility = makeVisible(layout, subject.visibilityDemand);
        rebuildVisibilityTree(subject.visibility, layout, layout.rootId);

        subject.lockDemand = diff.lock || {};
        subject.lock = { ...subject.lock, ...subject.lockDemand };
        rebuildLockTree(subject.lock, layout, layout.rootId);
      }

      subject = { ...subject, ...layout };
      updateCommentBindings(subject);
      newSubjects[subjectKey] = subject;
    }
  } else {
    const subject = { ...newSubjects[subjectKey], ...layout };
    if (isSubject(subject)) {
      updateCommentBindings(subject);
    }
    newSubjects[subjectKey] = subject;
  }
  return {
    ...state,
    layoutsStatus,
    layoutsLoading,
    layouts,
    subjects: newSubjects,
  };
}

function subjectReceived(
  state: SubjectState,
  subjectKey: string,
  operation: OperationType,
  subject: Subject,
  notifyId: any,
  diff?: SubjectDiff
): SubjectState {
  let newSubject = { ...subject };
  const loading = { ...state.loading, [subjectKey]: false };
  const loadingLock = { ...state.loadingLock, [subjectKey]: false };
  // if(loadingLock[subjectKey]){
  //     delete loadingLock[subjectKey];
  // }
  // if(loading[subjectKey]){
  //     delete loading[subjectKey];
  // }
  if (operation == constants.SUBJECT_OPERATION_CREATE) {
    newSubject.isNew = true;
    newSubject.notifyId = notifyId;
  }
  const layout = { ...state.layouts[newSubject.className] };
  let layoutsStatus = state.layoutsStatus;
  let layouts = state.layouts;
  if (layout && diff && layout.rootId) {
    layoutsStatus = { ...state.layoutsStatus };
    layouts = { ...state.layouts };
    //Update store
    newSubject.values = diff.values || {};
    newSubject.validation = diff.validation || {};
    rebuildValidationTree(newSubject.validation, layout, layout.rootId);
    newSubject.visibilityDemand = diff.visibility || {};
    newSubject.visibility = makeVisible(layout, newSubject.visibilityDemand);
    rebuildVisibilityTree(newSubject.visibility, layout, layout.rootId);
    newSubject.lockDemand = diff.lock || {};
    newSubject.lock = { ...newSubject.lockDemand };
    rebuildLockTree(newSubject.lock, layout, layout.rootId);
    //If initialize store then cache layout
    if (operation == constants.SUBJECT_OPERATION_INIT) {
      const cls = newSubject.className;
      if (!layouts[cls]) {
        layoutsStatus[cls] = constants.STATUS_READY;
        layouts[cls] = { ...layout };
      }
    }

    newSubject = { ...newSubject, ...layout };
  } else {
    newSubject.values = {};
    newSubject.validation = {};
    newSubject.visibilityDemand = {};
    newSubject.visibility = {};
    newSubject.lockDemand = {};
    newSubject.lock = {};
  }

  updateCommentBindings(newSubject);
  const subjects = { ...state.subjects, [subjectKey]: newSubject };
  return { ...state, layouts, layoutsStatus, subjects, loading, loadingLock };
}

function subjectChangeData(
  state: SubjectState,
  diff: SubjectDiff
): SubjectState {
  const { lock, subjectKey, visibility, validation, values } = diff;
  const newSubject = { ...state.subjects[subjectKey] };
  if (!isSubject(newSubject)) {
    return state;
  }
  const layout = state.layouts[newSubject.className];

  if (values) {
    newSubject.values = { ...newSubject.values, ...values };
  }
  if (validation && layout && layout.rootId) {
    newSubject.validation = { ...newSubject.validation, ...validation };
    rebuildValidationTree(newSubject.validation, layout, layout.rootId);
  }
  if (visibility && layout.rootId) {
    newSubject.visibilityDemand = {
      ...newSubject.visibilityDemand,
      ...diff.visibility,
    };
    newSubject.visibility = makeVisible(layout, newSubject.visibilityDemand);
    rebuildVisibilityTree(newSubject.visibility, layout, layout.rootId);
  }
  if (lock && layout.rootId) {
    newSubject.lockDemand = { ...newSubject.lockDemand, ...diff.lock };
    newSubject.lock = { ...newSubject.lockDemand };
    rebuildLockTree(newSubject.lock, layout, layout.rootId);
  }
  if (values || validation || visibility || lock) {
    updateCommentBindings(newSubject);
    const newSubjects = { ...state.subjects, [subjectKey]: newSubject };
    return {
      ...state,
      subjects: newSubjects,
      changeLoading: { ...state.changeLoading, [subjectKey]: false },
    };
  }
  return state;
}

function removeSubjectFromStore(state: SubjectState, subjectKey: string) {
  const subjects = { ...state.subjects };
  delete subjects[subjectKey];
  return { ...state, subjects };
}

function cancelSave(state: SubjectState, subjectKey: string) {
  if (
    !state.saveState[subjectKey] ||
    state.saveState[subjectKey] == constants.SAVE_STATE_WAIT_SERVER
  ) {
    //Do not cancel if state is not in progress or we are already waiting for server
    return state;
  }

  const saveState = { ...state.saveState };
  delete state.saveState[subjectKey];
  state.editingSubjects && (state.editingSubjects[subjectKey] = false);
  state.newSubjects && (state.newSubjects[subjectKey] = false);
  return { ...state, saveState };
}

function startSave(state: SubjectState, subjectKey: string) {
  // const saveState: { [SUBJECT_KEY: string]: SaveStateType } = { ...state.saveState, [subjectKey]: constants.SAVE_STATE_START }

  const current = state.saveState[subjectKey];
  if (current && current != constants.SAVE_STATE_SHOW_ERRORS) {
    //Do not change if state is already in progress
    return state;
  }
  const subject = state.subjects[subjectKey];
  if (!isSubject(subject)) {
    return state;
  }
  const layout = state.layouts[subject.className];
  const valid =
    layout.rootId && subject.validation[layout.rootId] ? false : true; //check error for rootId
  const saveState: { [SUBJECT_KEY: string]: SaveStateType } = Object.assign(
    {},
    state.saveState,
    {
      [subjectKey]: valid
        ? constants.SAVE_STATE_START
        : constants.SAVE_STATE_SHOW_ERRORS,
    }
  );
  return { ...state, saveState };
}

function changeTab(
  state: SubjectState,
  subjectKey: string,
  tabId: string,
  navId: string
) {
  let tabs: { [SUBJECT_KEY: string]: ActiveTabs } = { ...state.tabs };
  tabs[subjectKey] = { ...tabs[subjectKey], [navId]: { active: tabId } };

  return { ...state, tabs };
}

function nodeUpdated(
  state: SubjectState,
  payload: { subjectKey: string; nodeId: string; updated: boolean }
) {
  const { subjectKey, nodeId, updated } = payload;

  const subjects = { ...state.subjects };
  const subject = subjects[subjectKey];
  if (!isSubject(subject)) {
    return state;
  }
  const updatedComponents: any = { ...subject.componentUpdated };
  if (updated) {
    updatedComponents[nodeId] = true;
  } else {
    delete updatedComponents[nodeId];
  }
  const newSubject: Subject = {
    ...subject,
    componentUpdated: updatedComponents,
  };
  subjects[subjectKey] = newSubject;
  return { ...state, subjects };
}

const reducer: Reducer<SubjectState, ApplicationAction> = (
  state = DEFAULT_SUBJECT_STATE,
  action: ApplicationAction
): SubjectState => {
  switch (action.type) {
    case constants.SEND_FRAGMENT_TREE: {
      const { l, r } = action.payload;
      const fragmentState = state.fragmentTree || {};
      return { ...state, fragmentTree: parseFragmentList(fragmentState, l, r) };
    }
    case constants.SEND_FRAGMENT_TREE_LOADING: {
      const { loading } = action.payload;
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.treeLoading = loading;
      return { ...state, fragmentTree };
    }
    case constants.SEND_FRAGMENT_TREE_ERROR: {
      const { error } = action.payload;
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.error = error;
      return { ...state, fragmentTree };
    }
    case constants.SEND_FRAGMENT_TREE_SELECTED: {
      const { id } = action.payload;
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.selected = id;
      return { ...state, fragmentTree };
    }
    case constants.SEND_SUBJECT: {
      const { subject, subjectKey, notifyId, operation, diff } = action.payload;
      return subjectReceived(
        state,
        subjectKey,
        operation,
        subject,
        notifyId,
        diff
      );
    }
    case constants.SEND_SUBJECT_NODE_LOADING: {
      const { loading, subjectKey, nodeId } = action.payload;

      const nextState: SubjectState = { ...state };
      const newSubject = { ...nextState.subjects[subjectKey] };
      if (!isFetchError(newSubject)) {
        const newLoading = { ...newSubject.loading, [nodeId]: loading };
        newSubject.loading = newLoading;
        nextState.subjects[subjectKey] = newSubject;
        return nextState;
      } else {
        return state;
      }
    }
    case constants.SEND_SUBJECT_EDITING: {
      const { rdfId, isEditing } = action.payload;
      const editingSubjects = { ...state.editingSubjects };
      if (isEditing) {
        editingSubjects[rdfId] = true;
      } else {
        delete editingSubjects[rdfId];
      }
      const nextState: SubjectState = { ...state, editingSubjects };
      if (isEmptyObject(editingSubjects)) {
        delete nextState.editingSubjects;
      }
      return nextState;
    }
    case constants.SEND_SUBJECT_NEW: {
      const { rdfId, isNew } = action.payload;
      const newSubjects: { [SUBJECT_KEY: string]: boolean } = {
        ...state.newSubjects,
        [rdfId]: isNew,
      };
      if (isNew) {
        newSubjects[rdfId] = true;
      } else {
        delete newSubjects[rdfId];
      }
      const nextState: SubjectState = { ...state, newSubjects };
      if (isEmptyObject(newSubjects)) {
        delete nextState.newSubjects;
      }
      return nextState;
    }
    case constants.SEND_SUBJECT_REMOVE_FROM_STORE: {
      return removeSubjectFromStore(state, action.payload);
    }
    case constants.SEND_SUBJECT_CHANGE_DATA: {
      const subjectKey = action.payload.data.subjectKey;
      const nodeId = action.payload.nodeId;
      const middleState = nodeUpdated(state, {
        subjectKey,
        nodeId,
        updated: true,
      });
      return subjectChangeData(middleState, action.payload.data);
    }
    case constants.SEND_SUBJECT_ERROR: {
      const loading = { ...state.loading, [action.payload.subjectKey]: false };
      const subjects = {
        ...state.subjects,
        [action.payload.subjectKey]: action.payload.error,
      };
      return { ...state, subjects, loading };
    }
    case constants.SEND_SUBJECT_LOADING: {
      const { subjectKey, loading: l } = action.payload;
      const loading = { ...state.loading, [action.payload.subjectKey]: l };
      return { ...state, loading };
    }
    case constants.SEND_SUBJECT_LOCK_LOADING: {
      const { subjectKey, loading: l } = action.payload;
      const loadingLock = {
        ...state.loadingLock,
        [action.payload.subjectKey]: l,
      };
      return { ...state, loadingLock };
    }
    case constants.SEND_SUBJECT_CHANGE_LOADING: {
      const { subjectKey } = action.payload;
      const subjects = { ...state.subjects };
      const subject = subjects[subjectKey];
      if (!isSubject(subject)) {
        return state;
      }
      return {
        ...state,
        changeLoading: { ...state.changeLoading, [subjectKey]: true },
      };
    }
    case constants.CHANGE_TAB: {
      const { navId, subjectKey, tabId } = action.payload;
      return changeTab(state, subjectKey, tabId, navId);
    }
    case constants.SEND_SUBJECT_START_SAVE: {
      return startSave(state, action.payload);
      // const subjectKey = action.payload;
      // const saveState: { [SUBJECT_KEY: string]: SaveStateType } = { ...state.saveState, [subjectKey]: constants.SAVE_STATE_START }
      // return { ...state, saveState };
    }
    case constants.SEND_SUBJECT_CHANGE_SAVE_STATE: {
      const { subjectKey, saveState: sstate } = action.payload;
      const saveState = { ...state.saveState, [subjectKey]: sstate };
      const loading = { ...state.loading };
      if (sstate === "show_errors") {
        loading[subjectKey] = false;
      }
      return { ...state, loading, saveState };
    }

    case constants.SEND_SUBJECT_SAVE_WAIT: {
      const subjectKey = action.payload;
      const saveState: { [SUBJECT_KEY: string]: SaveStateType } = {
        ...state.saveState,
        [subjectKey]: constants.SAVE_STATE_WAIT_SERVER,
      };
      return { ...state, saveState };
    }
    case constants.SEND_SUBJECT_SAVE_DONE: {
      const subjectKey = action.payload;
      const saveState: { [SUBJECT_KEY: string]: SaveStateType } = {
        ...state.saveState,
      };
      const loading = { ...state.loading, [subjectKey]: false };
      delete saveState[subjectKey];
      const subject = state.subjects[subjectKey];

      if (subject && isSubject(subject) && subject.subjectData.$isNew) {
        const subjects = { ...state.subjects };
        delete subjects[subjectKey];
        return { ...state, subjects, saveState, loading };
      }
      return { ...state, saveState, loading };
    }
    case constants.SEND_SUBJECT_COMPONENT_UPDATED: {
      // const { subjectKey, nodeId, updated } = action.payload;

      // const subjects = { ...state.subjects };
      // const subject = subjects[subjectKey];
      // if (!isSubject(subject)) {
      //     return state;
      // }
      // const updatedComponents: any = { ...subject.componentUpdated };
      // if (updated) {
      //     updatedComponents[nodeId] = true;
      // } else {
      //     delete updatedComponents[nodeId]
      // }
      // const newSubject: Subject = { ...subject, componentUpdated: updatedComponents }
      // subjects[subjectKey] = newSubject;
      return nodeUpdated(state, action.payload);
    }
    case constants.SEND_SUBJECT_CANCEL_SAVE: {
      const subject = state.subjects[action.payload];
      if (isSubject(subject) && subject.subjectData.$isNew) {
        return removeSubjectFromStore(state, action.payload);
      }
      return cancelSave(state, action.payload);
    }

    case constants.SEND_CACHED_LAYOUT: {
      const { className, layout, storeDiffList, subjectKey } = action.payload;
      return layoutReceived(
        state,
        subjectKey,
        className,
        layout,
        storeDiffList
      );
    }
    case constants.SEND_SUBJECT_DESTROY: {
      const newState = { ...state };
      delete newState.subjects[action.payload];
      delete newState.saveState[action.payload];
      delete newState.changeLoading[action.payload];
      delete newState.loading[action.payload];
      return newState;
    }
    case constants.SEND_LAYOUT_LOADING: {
      const { subjectClass, loading } = action.payload;
      const layoutsLoading = {
        ...state.layoutsLoading,
        [subjectClass]: loading,
      };
      const layoutsStatus = { ...state.layoutsStatus };
      layoutsStatus[subjectClass] = constants.STATUS_LOADING;
      return { ...state, layoutsLoading, layoutsStatus };
    }
    case constants.SEND_ENUMERATION: {
      const enumerationState: EnumerationClass = {
        loading: false,
        error: false,
        enumerationInfo: action.payload.data,
      };
      const enumerations = {
        ...state.enumerations,
        [action.payload.className]: enumerationState,
      };
      return { ...state, enumerations };
    }
    case constants.SEND_ENUMERATION_LOADING: {
      const enumerationState: EnumerationClass = {
        loading: true,
        error: false,
        enumerationInfo: null,
      };
      const enumerations = {
        ...state.enumerations,
        [action.payload.className]: enumerationState,
      };
      return { ...state, enumerations };
    }
    case constants.SEND_ENUMERATION_ERROR: {
      const enumerationState: EnumerationClass = {
        loading: false,
        error: true,
        enumerationInfo: null,
      };
      const enumerations = {
        ...state.enumerations,
        [action.payload.className]: enumerationState,
      };
      return { ...state, enumerations };
    }
  }

  return state;
};

export default reducer;
