import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import shortid from "shortid";

import { DEFAULT_TABLE_STATE } from "../../reducers/table";

import { ApplicationState } from "../../types";
import {
  RowData,
  TableParameter,
  ToolbarReport,
  ToolbarItem,
  Column,
  instanceOfLocalPageableTableApi,
  SelectType,
  InitialLocalTableData,
  LocalTableData,
  isRowDataList,
  RowDataMap,
} from "../../types/table";
import { FetchError } from "../../types/error";
import {
  sendLocalTableInitialData,
  sendLocalTableUpdateData,
  sendLocalTableUninitialize,
} from "../../actions/table";

import Table from "./Table";
import {
  NptTableColumns,
  NptTableParameters,
  NptTableReports,
  NptTableToolbarItems,
  NptTableColumnsProps,
  NptTableColumn,
  NptTableParametersProps,
  NptTableParameter,
  NptTableReportsProps,
  NptTableReport,
  NptTableToolbarItemsProps,
  NptTableToolbarItem,
} from "./AsyncTable";

/********************************
 *    Local table connector     *
 ********************************/

/* Common API for using local table from react */
interface LocalTableApi {
  /**Table ID. Using it to identify redux store data location.*/
  tableId?: string;
  /**Height of table (100% by default) */
  height?: number;
  /**Scrollable flag of table (true by default) */
  scrollable?: boolean;
  /**External table will define all data functions automatically */
  external: false;
  /**Display indicator that table is loading*/
  loading?: boolean;
  /**Display indicator that table data is loading*/
  loadingData?: boolean;
  /**Error data that occured on table loading*/
  error?: null | FetchError;
  /**Info about error, that occured during data fetch */
  errorData?: null | FetchError;
  /**Stylesheet map*/
  stylesheets?: { [k: string]: string };
  /**Info about table parameters*/
  // parameters?: { [k: string]: TableParameter }
  /**Values of table parameters*/
  // fields?: { [k: string]: string }
  /**Report items of table*/
  // reports?: ToolbarReport[]
  /**Array of table toolbar items*/
  // toolbar: ToolbarItem[],
  /**Array of table columns*/
  // columns: Column[],
  /**Array of table rows*/
  data: RowData[] | RowDataMap[];
  /**Select type of table (null if table rows cannot be selected) */
  selectType?: SelectType | null;
  /**Map of row selected flag by key */
  selectedRows?: string[] | null;
  /**Counter of selected rows */
  // selectedRowsLength: number
  /**Total table rows counter */
  // totalRowsLength: number
  /**Info about table sorting */
  // sortInfo: SortInfo
  /**Info about table filtering */
  // filterInfo: FilterInfo
  /**Automation script */
  automation?: (() => void) | string;
  /**Toolbar visibility flag */
  toolbarHidden?: boolean;
  /**Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
}

/* API for using local non-pageable table from react */
export interface LocalSimpleTableApi extends LocalTableApi {
  /**Flag that show if this table have pagination */
  pageable: false;
}

/* API for using pageable table from react */
export interface LocalPageableTableApi extends LocalTableApi {
  /**Flag that show if this table have pagination */
  pageable: true;
  /**Selected page of table */
  page?: number;
  /**Size of one page */
  pageSize: number;
  /**Possible values of table page size */
  pageSelection: number[];
}

/**
 * Local table take as parameters only data values.
 * All other options is printed as table components.
 * So we must parse table childrens to get table props and traverse them further.
 */
interface LocalTableState {
  tableId: string | null;
  parameters: { [k: string]: TableParameter };
  fields: { [k: string]: string };
  reports: ToolbarReport[];
  toolbar: ToolbarItem[];
  columns: Column[];
  automation?: string;
}
class LocalTable extends React.PureComponent<
  LocalSimpleTableApi | LocalPageableTableApi,
  LocalTableState
> {
  constructor(props: LocalSimpleTableApi | LocalPageableTableApi) {
    super(props);

    this.state = {
      tableId: null,
      parameters: {},
      fields: {},
      reports: [],
      toolbar: [],
      columns: [],
    };

    this.setupOptions = this.setupOptions.bind(this);
    this.setupColumns = this.setupColumns.bind(this);
    this.setupParameters = this.setupParameters.bind(this);
  }

  setupOptions() {
    const nextState: LocalTableState = {
      tableId: this.props.tableId || shortid.generate(),
      parameters: {},
      fields: {},
      reports: [],
      toolbar: [],
      columns: [],
    };
    React.Children.map(this.props.children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (React.isValidElement(child) && child.type === NptTableColumns) {
        this.setupColumns(nextState, child as React.ReactElement<NptTableColumnsProps>);
      }
      if (child.type === NptTableParameters) {
        this.setupParameters(nextState, child as React.ReactElement<NptTableParametersProps>);
      }
      if (child.type === NptTableReports) {
        this.setupReports(nextState, child as React.ReactElement<NptTableReportsProps>);
      }
      if (child.type === NptTableToolbarItems) {
        this.setupToolbar(nextState, child as React.ReactElement<NptTableToolbarItemsProps>);
      }
    });
    this.setupAutomation(nextState);
    this.setState(nextState);
  }

  setupColumns(
    nextState: LocalTableState,
    element: React.ReactElement<NptTableColumnsProps>
  ) {
    nextState.columns = [];
    React.Children.map(element.props.children, (child) => {
      if (!React.isValidElement(child) || child.type !== NptTableColumn) {
        return;
      }
      nextState.columns.push(
        Object.assign({ originalWidth: child.props.width }, child.props, {
          key: child.props.isKey,
        })
      );
    });
  }

  setupParameters(
    nextState: LocalTableState,
    element: React.ReactElement<NptTableParametersProps>
  ) {
    nextState.parameters = {};
    nextState.fields = {};
    React.Children.map(element.props.children, (child) => {
      if (!React.isValidElement(child) || child.type !== NptTableParameter) {
        return;
      }
      const parameter: TableParameter = {
        name: child.props.name,
        label: child.props.label,
        type: child.props.type,
      };
      const value = child.props.value;
      nextState.parameters[parameter.name] = parameter;
      if (typeof value != "undefined") {
        nextState.fields[parameter.name] = value;
      }
    });
  }

  setupReports(
    nextState: LocalTableState,
    element: React.ReactElement<NptTableReportsProps>
  ) {
    nextState.reports = [];
    React.Children.map(element.props.children, (child) => {
      if (!React.isValidElement(child) || child.type !== NptTableReport) {
        return;
      }
      nextState.reports.push(child.props);
    });
  }

  setupToolbar(
    nextState: LocalTableState,
    element: React.ReactElement<NptTableToolbarItemsProps>
  ) {
    nextState.toolbar = [];
    React.Children.map(element.props.children, (child) => {
      if (!React.isValidElement(child) || child.type !== NptTableToolbarItem) {
        return;
      }
      nextState.toolbar.push(child.props);
    });
  }

  setupAutomation(nextState: LocalTableState) {
    let automation;
    if (this.props.automation) {
      if (typeof this.props.automation === "function") {
        automation = this.props.automation.toString();
        automation = automation.substring(
          automation.indexOf("{") + 1,
          automation.lastIndexOf("}")
        );
      } else {
        automation = this.props.automation;
      }
    }
    nextState.automation = automation;
  }

  componentDidMount() {
    this.setupOptions();
  }

  render() {
    let page: number | undefined;
    let pageSize: number | undefined;
    let pageSelection: number[] | undefined;
    if (instanceOfLocalPageableTableApi(this.props)) {
      page = this.props.page;
      pageSize = this.props.pageSize;
      pageSelection = this.props.pageSelection;
    }
    if (this.state.tableId === null) {
      return null;
    }
    const propsData = this.props.data;
    let data: RowData[];
    if (!isRowDataList(propsData)) {
      data = propsData.map((row) => {
        return {
          data: row,
          bindedData: {},
          changed: null,
          changedData: {},
          classes: {},
        };
      });
    } else {
      data = propsData;
    }
    return (
      <LocalTableUpdater
        tableId={this.state.tableId}
        height={this.props.height}
        scrollable={this.props.scrollable}
        columns={this.state.columns}
        data={data}
        parameters={this.state.parameters}
        fields={this.state.fields}
        reports={this.state.reports}
        toolbar={this.state.toolbar}
        loading={this.props.loading}
        loadingData={this.props.loadingData}
        error={this.props.error}
        stylesheets={this.props.stylesheets}
        pageable={this.props.pageable}
        page={page}
        pageSize={pageSize}
        pageSelection={pageSelection}
        selectType={this.props.selectType}
        selectedRows={this.props.selectedRows}
        automation={this.state.automation}
        toolbarHidden={this.props.toolbarHidden}
        onSelectionChange={this.props.onSelectionChange}
      />
    );
  }
}

/**
 * Table updater that takes local options, data, loading states and
 * initialize reducer state + send changes to reducer.
 * On unmount call uninitialize table function to remove all temp table data.
 */
interface LocalTableUpdaterProps extends LocalTableData {
  tableId: string;
  height?: number;
  scrollable?: boolean;
  columns: Column[];
  data: RowData[];
  parameters: { [k: string]: TableParameter };
  fields: { [k: string]: string };
  reports: ToolbarReport[];
  toolbar: ToolbarItem[];
  loading?: boolean;
  loadingData?: boolean;
  error?: null | FetchError;
  errorData?: null | FetchError;
  stylesheets?: { [k: string]: string };
  pageable?: boolean;
  page?: number;
  pageSize?: number;
  pageSelection?: number[];
  selectType?: SelectType | null;
  selectedRows?: string[] | null;
  automation?: string;
  toolbarHidden?: boolean;
  initializeTable: (initialTableData: InitialLocalTableData) => void;
  updateTable: (tableData: LocalTableData) => void;
  uninitializeTable: () => void;
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
}
class LocalTableUpdaterImpl extends React.PureComponent<LocalTableUpdaterProps> {
  componentDidMount() {
    let selectedRows: { [k: string]: boolean } = {};
    let selectedRowsLength = 0;
    if (this.props.selectedRows) {
      for (let value of this.props.selectedRows) {
        selectedRows[value] = true;
        ++selectedRowsLength;
      }
    }
    this.props.initializeTable({
      data: this.props.data,
      loading: this.props.loading,
      loadingData: this.props.loadingData,
      error: this.props.error,
      columns: this.props.columns,
      parameters: this.props.parameters,
      fields: this.props.fields,
      reports: this.props.reports,
      toolbar: this.props.toolbar,
      selectType: this.props.selectType || null,
      selectedRows: selectedRows,
      selectedRowsLength: selectedRowsLength,
      automation: this.props.automation,
      pageable: this.props.pageable,
      pageSize: this.props.pageSize,
      pageSelection: this.props.pageSelection,
      page: this.props.page,
    });
  }

  componentDidUpdate() {
    /**
     * Since updater is PureComponent update function occurs only on props change
     * and we don't need to perform any additional data checks
     */
    this.props.updateTable({
      data: this.props.data,
      loading: this.props.loading,
      loadingData: this.props.loadingData,
      error: this.props.error,
      errorData: this.props.errorData,
    });
  }

  componentWillUnmount() {
    this.props.uninitializeTable();
  }

  render() {
    return (
      <ConnectedLocalTable
        tableId={this.props.tableId}
        height={this.props.height}
        scrollable={this.props.scrollable}
        toolbarHidden={this.props.toolbarHidden}
        onSelectionChange={this.props.onSelectionChange}
        fetchColumnSelection={() => {}}
        fetchColumnRange={() => {}}
      />
    );
  }
}
const LocalTableUpdater = connect(
  null,
  (dispatch: ThunkDispatch<{}, {}, any>, ownProps: { tableId: string }) => {
    return {
      initializeTable: (tableData: InitialLocalTableData) => {
        dispatch(
          sendLocalTableInitialData(
            ownProps.tableId,
            tableData
          ) as any as AnyAction
        );
      },
      updateTable: (tableData: LocalTableData) => {
        dispatch(
          sendLocalTableUpdateData(
            ownProps.tableId,
            tableData
          ) as any as AnyAction
        );
      },
      uninitializeTable: () => {
        dispatch(
          sendLocalTableUninitialize(ownProps.tableId) as any as AnyAction
        );
      },
    };
  }
)(LocalTableUpdaterImpl);

/**
 * Local table that connected to redux by tableId
 */
const ConnectedLocalTable = connect(
  (state: ApplicationState, ownProps: { tableId: string }) => {
    const tableState = state.table[ownProps.tableId];
    if (!tableState) {
      return {
        tableId: ownProps.tableId,
        contextPath: "/",
        loading: true,
        loadingData: true,
        error: null,
        errorData: null,
        stylesheets: DEFAULT_TABLE_STATE.stylesheets,
        parameters: DEFAULT_TABLE_STATE.parameters,
        fields: DEFAULT_TABLE_STATE.fields,
        fieldsChanges: null,
        reports: DEFAULT_TABLE_STATE.reports,
        toolbar: DEFAULT_TABLE_STATE.toolbar,
        columns: DEFAULT_TABLE_STATE.columns,
        dynamicColumns: DEFAULT_TABLE_STATE.dynamicColumns,
        dynamicHiddenColumns: DEFAULT_TABLE_STATE.dynamicHiddenColumns,
        pageRows: DEFAULT_TABLE_STATE.pageRows,
        pageable: false,
        rowByIdx: DEFAULT_TABLE_STATE.rowByIdx,
        minWidth: 0,
        selectType: null,
        selectedRows: DEFAULT_TABLE_STATE.selectedRows,
        selectedRowsLength: 0,
        totalRowsLength: 0,
        sortInfo: DEFAULT_TABLE_STATE.sortInfo,
        filterInfo: DEFAULT_TABLE_STATE.filterInfo,
        filterChanges: null,
        gantOptions: null,
        gantData: DEFAULT_TABLE_STATE.gantData,
        finderOptions: null,
        searchString: null,
        dragOptions: null,
        dropOptions: null,
      };
    }
    const finderState = state.finder[ownProps.tableId];
    return {
      contextPath: state.location.contextPath,
      ...tableState,
      finderOptions: finderState?.options || null,
    };
  }
)(Table);

export default LocalTable;
