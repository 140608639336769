import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import shortid from "shortid";

import { messages } from "../index";

import { ApplicationState } from "../types";

import {
  SendTableLoading,
  SendTableError,
  SendTableDataLoading,
  SendTableDataError,
  TableAction,
  Column,
  TableHeader,
  TableData,
  RowData,
  ColumnFormat,
  SendTableHeader,
  SendTableData,
  TableParameter,
  ToolbarItem,
  ToolbarItemType,
  ToolbarItemFormat,
  TableParameterType,
  ToolbarReport,
  SendSelectRow,
  SendSelectAll,
  SendPageSize,
  SendPage,
  RowDataMap,
  CurrencyColumn,
  TablePagination,
  FilterData,
  FilterType,
  InitialLocalTableData,
  LocalTableData,
  SendLocalTableInitialData,
  SendLocalTableUpdateData,
  SendLocalTableUninitialize,
  TableGantOptions,
  GantGroup,
  GantScale,
  GantRoundingType,
  GantElementData,
  GantRowData,
  GantViewType,
  SendGantViewType,
  SendGantSelectedGroup,
  SendGantChangeElement,
  GantDateLimits,
  CommonToolbarItem,
  ToolbarGroup,
  SendSortKey,
  SendFilterData,
  SendTableFilterSelectionLoading,
  SendTableFilterSelectionError,
  SendTableFilterSelection,
  ServerColumnFilter,
  ServerFilterData,
  isStringFilterData,
  ColumnFilterInfo,
  isNumberFilterData,
  isDateFilterData,
  isDateTimeFilterData,
  isBooleanFilterData,
  SendTableSortOptions,
  ColumnSortInfo,
  SendTableFilterRangeLoading,
  SendTableFilterRangeError,
  SendTableFilterRange,
  SendTableColumns,
  SendTableSaveStart,
  SendTableSaveSuccess,
  SendTableSaveError,
  ServerTableData,
  SendTableInitialData,
  SendField,
  SendFilterChangesConfirm,
  SendFilterChangesDeny,
  AutomationTableModule,
  TableDragOptions,
  TableDropOptions,
  TableInitialOptions,
  TableDragViewOptions,
  TableDropViewOptions,
  SendTableRowAdd,
  SendTableRowChange,
  SendTableCellChange,
  ServerTableFilter,
  TableRequest,
  TableUserSettings,
  SendTableClientSideFilter,
  TableGantOptionsSettings,
  GantPermissionsValue,
  TableState,
} from "../types/table";
import { FetchError } from "../types/error";
import {
  CancelCallback,
  CloseCallback,
  ModalOptions,
  OkCallback,
} from "../types/modal";
import { AlertLevelType } from "../types/alert";
import {
  FinderData,
  FinderOptions,
  FinderState,
  ServerFinderFilter,
} from "../types/finder";
import {
  SEND_TABLE_LOADING,
  SEND_TABLE_ERROR,
  SEND_TABLE_DATA_LOADING,
  SEND_TABLE_DATA_ERROR,
  SEND_TABLE_HEADER,
  SEND_TABLE_DATA,
  SEND_SELECT_ROW,
  SEND_SELECT_ALL,
  SEND_PAGE_SIZE,
  SEND_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SELECTION,
  SEND_LOCAL_TABLE_INITIAL_DATA,
  SEND_LOCAL_TABLE_UPDATE_DATA,
  SEND_LOCAL_TABLE_UNINITIALIZE,
  STANDART_COLORS,
  SEND_GANT_VIEW_TYPE,
  SEND_GANT_SELECTED_GROUP,
  SEND_GANT_CHANGE_ELEMENT,
  SEND_SORT_KEY,
  SEND_FILTER_DATA,
  SEND_TABLE_FILTER_SELECTION_LOADING,
  SEND_TABLE_FILTER_SELECTION_ERROR,
  SEND_TABLE_FILTER_SELECTION,
  SEND_TABLE_SORT_OPTIONS,
  SEND_TABLE_FILTER_RANGE_LOADING,
  SEND_TABLE_FILTER_RANGE_ERROR,
  SEND_TABLE_FILTER_RANGE,
  SEND_TABLE_COLUMNS,
  SEND_TABLE_SAVE_START,
  SEND_TABLE_SAVE_SUCCESS,
  SEND_TABLE_SAVE_ERROR,
  SEND_TABLE_INITIAL_DATA,
  SEND_FIELD,
  SEND_FILTER_CHANGES_CONFIRM,
  SEND_FILTER_CHANGES_DENY,
  SEND_TABLE_ROW_ADD,
  SEND_TABLE_ROW_CHANGE,
  SEND_TABLE_CELL_CHANGE,
  SEND_TABLE_CLIENT_SIDE_FILTER,
} from "../constants/table";
import { ALERT_LEVEL_SUCCESS } from "../constants/alert";
import { receiveServerError, receiveServerMessage, ServerError } from "./utils";
import { openModal } from "./modal";
import { changeSearch } from "./location";
import { addAlert, dispatchError, dispatchErrorV2 } from "./alert";
import {
  initializeFinder,
  sendFinderChangesConfirm,
  sendFinderChangesDeny,
} from "./finder";
import { isEmptyObject } from "../services/app";
import { buildUrl, getSearchData } from "../services/location";
import { registerFunction, retrieveFunction } from "../services/automation";
import {
  getTableRowsDataList,
  getTableRowsIndexes,
  isPageFetched,
  createGantGroup,
  parseToArray,
  isFilterNeeded,
  getTableSearchFields,
  shadeBlendConvert,
  getRowAutomationData,
} from "../services/table";
import { getFileName } from "../services/reports";
import {
  parseFinderToFilter,
  parseFinderToFilterWithQuery,
} from "../services/finder";

/*********************
 * Utility functions *
 *********************/
export function getModelPath(path: string) {
  const idx = path.indexOf("?");
  if (idx >= 0) {
    return path.substring(0, idx);
  }
  return path;
}

function fetchTableHeaderImpl(tableId: string): Promise<any> {
  const url = buildUrl({ url: `/rest/table/header${tableId}` });
  return fetch(url)
    .then((resp) => {
      if (!resp.ok) {
        resp.text();
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function fetchTableDataImpl(
  tableId: string,
  tableRequest: TableRequest,
  abort?: AbortController
): Promise<TableData> {
  if (isFilterNeeded(tableRequest)) {
    return fetch(`/rest/table/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: abort?.signal,
      body: JSON.stringify({ path: getModelPath(tableId), ...tableRequest }),
    })
      .then((resp) => {
        if (!resp.ok) {
          resp.text();
          throw new ServerError(resp.status, resp.statusText);
        }
        return resp.json();
      })
      .then((json) => {
        return json;
      })
      .catch((e) => {
        console.log("errot===", e);
      });
  }
  const url = buildUrl({
    url: `/rest/table/data${getModelPath(tableId)}`,
    search: getTableSearchFields(tableRequest),
    withoutEncoding: true,
  });
  return fetch(url, {
    signal: abort?.signal,
  })
    .then((resp) => {
      if (!resp.ok) {
        resp.text();
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function fetchReportUuid(
  tableId: string,
  tableRequest: TableRequest,
  reportId: string,
  filename: string
): Promise<string> {
  if (tableRequest.range) {
    delete tableRequest.range.offset;
    delete tableRequest.range.limit;
  }
  return fetch(`/rest/table/report`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      path: tableId,
      ...tableRequest,
      reportId: reportId,
      reportFilename: filename,
    }),
  })
    .then((resp) => {
      if (!resp.ok) {
        resp.text();
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json.fields.reportUuid;
    });
}

function fetchTableFilterSelectionImpl(
  tableId: string,
  columnField: string,
  columnFilters: ColumnFilterInfo[],
  tableRequest: TableRequest
): Promise<any[]> {
  const data = {
    path: tableId,
    parameters: tableRequest.parameters,
    filter: {
      columns: [columnField],
      finder: tableRequest.filter?.finder || null,
      keyFilters: null,
      columnFilters: parseColumnFilterList(columnFilters),
    },
  };

  return fetch(`/rest/table/distinct`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      if (!resp.ok) {
        resp.text();
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      if (!Array.isArray(json.rows)) {
        return [];
      }
      return json.rows.map((row: any) =>
        Array.isArray(row) ? row.join(",") : row
      );
    });
}

function fetchTableFilterRangeImpl(
  tableId: string,
  columnField: string,
  columnFilters: ColumnFilterInfo[],
  tableRequest: TableRequest
): Promise<{ min: any; max: any }> {
  const data = {
    path: tableId,
    parameters: tableRequest.parameters,
    filter: {
      columns: [columnField],
      finder: tableRequest.filter?.finder || null,
      keyFilters: null,
      columnFilters: parseColumnFilterList(columnFilters),
    },
  };

  return fetch(`/rest/table/range`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      if (!resp.ok) {
        resp.text();
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      const minData = json.rows.find((data: any) => data[0] === "min");
      const maxData = json.rows.find((data: any) => data[0] === "max");
      return { min: minData[1], max: maxData[1] };
    });
}

async function saveTableImpl(
  tableId: string,
  savingTableData: ServerTableData
): Promise<any> {
  const url = buildUrl({ url: `/rest/table/save${tableId}` });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(savingTableData),
  }).then(async (resp) => {
    if (!resp.ok) {
      // resp.text();
      throw await receiveServerError(resp);
    }
    return resp;
  });
}

async function saveFilterImpl(
  tableId: string,
  filter: TableUserSettings | {}
): Promise<any> {
  const url = buildUrl({ url: `/rest/table/settings${tableId}` });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filter),
  }).then(async (resp) => {
    if (!resp.ok) {
      // resp.text();
      throw await receiveServerError(resp);
    }
    return resp;
  });
}

function isHeaderLoaded(tableState: TableState) {
  return tableState && tableState.columns.length !== 0;
}

function downloadFile(href: string, filename: string) {
  /* Link attribute "download" works only in this two browsers */
  let isChromium =
    navigator.userAgent.toLowerCase().indexOf("chrome") !== -1 ||
    navigator.userAgent.toLowerCase().indexOf("safari") !== -1;
  let composedLink = href;
  if (isChromium && typeof getSearchData().debug === "undefined") {
    let link = document.createElement("a");
    link.setAttribute("href", composedLink);
    link.setAttribute("download", filename);
    link.click();
    return;
  }

  // Force file download (whether supported by server).
  if (composedLink.indexOf("?") > 0) {
    composedLink = composedLink.replace(
      "?",
      "?download&_filename=" + filename + "&"
    );
  } else {
    composedLink += "?download&_filename=" + filename;
  }
  window.open(composedLink);
}

/************************
 * Automation functions *
 ************************/
function getAutomationTableModule(
  tableData: TableState,
  dispatch: ThunkDispatch<ApplicationState, {}, AnyAction>,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): AutomationTableModule {
  const tableModule: {
    data: RowDataMap[];
    changed: RowDataMap[];
    filteredData: RowDataMap[];
    filteredChangedData: RowDataMap[];
  } = {
    data: [],
    changed: [],
    filteredData: [],
    filteredChangedData: [],
  };
  const originalRowsIdxs = tableData.pageRows;
  const filteredRowsIdxs = getTableRowsIndexes(tableData);
  const originalData = getTableRowsDataList(tableData, originalRowsIdxs);
  const filteredData = getTableRowsDataList(tableData, filteredRowsIdxs);
  for (let row of originalData) {
    tableModule.data.push(getRowAutomationData(row));
    tableModule.changed.push(row.changedData);
  }
  for (let row of filteredData) {
    tableModule.filteredData.push(getRowAutomationData(row));
    tableModule.filteredChangedData.push(row.changedData);
  }

  return {
    fields: tableData.fields,
    columns: tableData.columns,
    data: tableModule.data,
    changed: tableModule.changed,
    props: {
      fields: tableData.fields,
      columns: tableData.columns,
      data: tableModule.data,
    },
    getFilteredRows: () => {
      return tableModule.filteredData;
    },
    getFilteredChangedRows: () => {
      return tableModule.filteredChangedData;
    },
    reload: (parameters?: { [k: string]: string }) => {
      dispatch(
        fetchTableData(tableData.tableId, {
          fields: parameters,
          finder: null,
          reset: Boolean(parameters),
          onFetch,
        })
      );
    },
    openModal: (
      type: string,
      options: ModalOptions,
      okCallback?: OkCallback,
      cancelCallback?: CancelCallback,
      closeCallback?: CloseCallback
    ) => {
      const id = shortid.generate();
      dispatch(
        openModal(id, type, options, okCallback, cancelCallback, closeCallback)
      );
    },
    addAlert: (
      message: string,
      options?: { type?: AlertLevelType; icon?: string; dissmissable?: boolean }
    ) => {
      const type = options?.type || "info";
      dispatch(addAlert(type, message, options));
    },
    isSelected: (row: RowDataMap) => {
      if (!row.key) {
        return false;
      }
      return tableData.selectedRows[row.key];
    },
    addRow: (row: RowDataMap) => {
      dispatch(addRow(tableData.tableId, row));
    },
    changeRow: (row: RowDataMap, rowIdx: number) => {
      dispatch(changeRow(tableData.tableId, row, rowIdx));
    },
    changeCell: (value: any, rowIdx: number, column: string) => {
      dispatch(changeCell(tableData.tableId, value, rowIdx, column));
    },
    saveFilter: () => {
      dispatch(saveFilter(tableData.tableId));
    },
    resetFilter: () => {
      dispatch(resetFilter(tableData.tableId));
    },
  };
}

/*********************
 * Parsers functions *
 *********************/
function parseTableParameters(parameters: any): {
  [k: string]: TableParameter;
} {
  const parsedParams: { [k: string]: TableParameter } = {};
  if (!parameters || !parameters.length) {
    return parsedParams;
  }
  for (let param of parameters) {
    parsedParams[param.name] = {
      name: param.name,
      label: param.label,
      type: param.type.toLowerCase() as TableParameterType,
    };
  }
  return parsedParams;
}

function parseTableStylesheets(stylesheets: any): { [k: string]: string } {
  const stylesheetMap: { [k: string]: string } = {};
  if (!stylesheets || !stylesheets.length) {
    return stylesheetMap;
  }
  for (let stylesheet of stylesheets) {
    stylesheetMap[stylesheet.name] = stylesheet.style;
  }
  return stylesheetMap;
}

function parseTableToolbar(toolbar: any): ToolbarItem[] {
  const parsedItems: ToolbarItem[] = [];
  if (!toolbar || !toolbar.length) {
    return parsedItems;
  }
  for (let item of toolbar) {
    const parsedItem: ToolbarItem = {
      id: item.id,
      label: item.label,
      type: "button",
      variant: item.variant,
      icon: item.icon,
      hidden: item.hidden,
      acl: item.acl,
      width: item.width,
      path: item.path,
      namespace: item.namespace,
      rdfId: item.rdfId,
    };
    const itemType = item.type.toLowerCase();
    if (itemType === "copy_refs") {
      parsedItem.type = "copyRefs";
    } else if (itemType === "dropdown") {
      parsedItem.type = itemType as ToolbarItemType;
      (parsedItem as ToolbarGroup).items = item.item;
    } else {
      parsedItem.type = itemType as ToolbarItemType;
    }
    if (item.format) {
      const format = item.format.toLowerCase();
      if (format === "select_column") {
        (parsedItem as CommonToolbarItem).format = "selectColumn";
      } else {
        (parsedItem as CommonToolbarItem).format = format as ToolbarItemFormat;
      }
    }
    parsedItems.push(parsedItem);
  }
  return parsedItems;
}

function parseTableReports(reports: any): ToolbarReport[] {
  const parsedReports: ToolbarReport[] = [];
  if (!reports || !reports.length) {
    return parsedReports;
  }
  for (let report of reports) {
    parsedReports.push({
      label: report.label,
      name: report.name,
      file: report.file,
      type: report.type,
      filename: report.filename,
    });
  }
  return parsedReports;
}

function parseColumnFormat(format: any): ColumnFormat {
  if (format === "DATE_TIME") {
    return "dateTime";
  }
  return format ? (format.toLowerCase() as ColumnFormat) : "string";
}

function parseColumnFilterType(filter: any): FilterType | null {
  if (typeof filter !== "string") {
    return null;
  }
  filter = filter.toLowerCase();
  switch (filter) {
    case "string":
      return "string";
    case "number":
      return "number";
    case "date":
      return "date";
    case "date_time":
      return "date_time";
    case "boolean":
      return "boolean";
    default:
      break;
  }
  console.error(`Unknown filter type:"${filter}", use "string" filter.`);
  return "string";
}

function parseColumnFilterList(
  columnFilters: ColumnFilterInfo[]
): ServerColumnFilter[] {
  const columnFilterList: ServerColumnFilter[] = [];
  let type: FilterType = "string";
  for (let columnFilter of columnFilters) {
    let serverFilterData: ServerFilterData | null = null;
    if (isStringFilterData(columnFilter.data)) {
      type = "string";
      serverFilterData = {
        contains: columnFilter.data.contain,
        selected: null,
      };
      if (!isEmptyObject(columnFilter.data.selected)) {
        serverFilterData.selected = [];
        for (let p in columnFilter.data.selected) {
          if (columnFilter.data.selected[p]) {
            serverFilterData.selected.push(p);
          }
        }
      }
      if (
        (!serverFilterData.selected ||
          serverFilterData.selected.length === 0) &&
        !serverFilterData.contains &&
        columnFilter.data.allowNulls
      ) {
        serverFilterData = { allowNulls: true };
      }
    } else if (isNumberFilterData(columnFilter.data)) {
      type = "number";
      serverFilterData = { op: columnFilter.data.operation };
      if (columnFilter.data.operation === "between") {
        if (
          (columnFilter.data.valueFrom !== null &&
            columnFilter.data.valueTo !== null) ||
          !columnFilter.data.allowNulls
        ) {
          serverFilterData.f = Number(columnFilter.data.valueFrom);
          serverFilterData.t = Number(columnFilter.data.valueTo);
        }
      } else if (
        columnFilter.data.value !== null ||
        !columnFilter.data.allowNulls
      ) {
        serverFilterData.v = Number(columnFilter.data.value);
      }
    } else if (
      isDateFilterData(columnFilter.data) ||
      isDateTimeFilterData(columnFilter.data)
    ) {
      type = isDateFilterData(columnFilter.data) ? "date" : "date_time";
      serverFilterData = { op: columnFilter.data.operation };
      if (columnFilter.data.operation === "between") {
        if (
          (columnFilter.data.valueFrom !== null &&
            columnFilter.data.valueTo !== null) ||
          !columnFilter.data.allowNulls
        ) {
          serverFilterData.f = columnFilter.data.valueFrom;
          serverFilterData.t = columnFilter.data.valueTo;
        }
      } else if (
        columnFilter.data.value !== null ||
        !columnFilter.data.allowNulls
      ) {
        serverFilterData.v = columnFilter.data.value;
      }
    } else if (isBooleanFilterData(columnFilter.data)) {
      type = "boolean";
      if (columnFilter.data.selected !== null) {
        serverFilterData = {
          selected: columnFilter.data.selected,
        };
      } else if (columnFilter.data.allowNulls) {
        serverFilterData = {
          allowNulls: true,
        };
      }
    }
    if (serverFilterData === null) {
      continue;
    }
    if (columnFilter.data.allowNulls) {
      serverFilterData.allowNulls = true;
    }
    columnFilterList.push({
      field: columnFilter.field,
      type: type,
      filter: serverFilterData,
    });
  }
  return columnFilterList;
}

function parseTableColumns(columns: any): Column[] {
  const parsedColumns: Column[] = [];
  if (!columns || !columns.length) {
    return parsedColumns;
  }
  for (let column of columns) {
    const parsedColumn: Column = {
      key: column.key,
      hidden: column.hidden,
      dynamic: column.dynamic,
      originalWidth: column.width,
      width:
        typeof column.width != "undefined"
          ? parseFloat(column.width)
          : undefined,
      field: column.field,
      name: column.label,
      format: parseColumnFormat(column.format),
      filterType: parseColumnFilterType(column.filterStrategy),
      subjectRef: column.subjectRef,
      fileRef: column.fileRef,
    };
    /** Gant cell must be hidden in regular table */
    if (parsedColumn.field === "gantData") {
      parsedColumn.hidden = true;
    }
    if (parsedColumn.format === "currency") {
      (parsedColumn as CurrencyColumn).currencyName =
        column.currencyName || null;
      (parsedColumn as CurrencyColumn).currencyCents =
        column.currencyCents || null;
    }
    parsedColumns.push(parsedColumn);
  }
  return parsedColumns;
}

function parseTablePagination(pagination: any): TablePagination {
  if (!pagination) {
    return {
      size: -1,
      selection: [],
    };
  }
  return {
    size: pagination?.size || DEFAULT_PAGE_SIZE,
    selection: pagination?.sizesList || DEFAULT_PAGE_SELECTION,
  };
}

function parseTableGantOptions(
  gantOptions: TableGantOptionsSettings
): TableGantOptions | null {
  if (!gantOptions) {
    return null;
  }
  const gantGroups: GantGroup[] = [];
  if (gantOptions.gantGroups) {
    for (let group of gantOptions.gantGroups) {
      gantGroups.push(createGantGroup(group.id, group.name, group.color));
    }
  }
  let headersWidth = parseFloat(gantOptions.headersWidth);
  if (isNaN(headersWidth)) {
    headersWidth = 0.5;
  } else {
    headersWidth /= 100;
  }
  return {
    dateLimits: {
      from: gantOptions.dateLimits?.from || -1,
      to: gantOptions.dateLimits?.to || -1,
      maxFrom: gantOptions.dateLimits?.maxFrom || -1,
      minTo: gantOptions.dateLimits?.minTo || -1,
    },
    gantGroups: gantGroups,
    gantPlannedGroups: {
      plannedWork: {
        id: gantOptions.gantPlannedGroups?.plannedWork?.id || "plannedWork",
        name: gantOptions.gantPlannedGroups?.plannedWork?.name || null,
        color: STANDART_COLORS.standartGray,
        borderColor:
          shadeBlendConvert(-0.25, STANDART_COLORS.standartGray) || "#000",
      },
      plannedDocs: {
        id: gantOptions.gantPlannedGroups?.plannedDocs?.id || "plannedDocs",
        name: gantOptions.gantPlannedGroups?.plannedDocs?.name || null,
        color: STANDART_COLORS.standartDarkGray,
        borderColor:
          shadeBlendConvert(-0.25, STANDART_COLORS.standartDarkGray) || "#000",
      },
    },
    permissions: {
      value:
        (gantOptions.permissions?.value?.toLowerCase() as GantPermissionsValue) ||
        "full",
      chosenOnly: gantOptions.permissions?.chosenOnly || false,
    },
    scale: (gantOptions.scale?.toLowerCase() || "month") as GantScale,
    rounding: (gantOptions.rounding?.toLowerCase() || "day") as GantScale,
    headersWidth: headersWidth,
    elementHeight: gantOptions.elementHeight || null,
    minCellWidth: gantOptions.minCellWidth || 20,
    initialRoundingType: (gantOptions.initialRoundingType?.toLowerCase() ||
      "auto") as GantRoundingType,
    roundingType: (gantOptions.roundingType?.toLowerCase() ||
      "auto") as GantRoundingType,
    fullWeeks: gantOptions.fullWeeks || false,
    singleElementOnRow: gantOptions.singleElementOnRow || false,
  };
}

function parseTableFinderOptions(
  finderViewOptions?: any,
  fragmentTree?: any,
  criteriaTree?: any,
  classTree?: any
): FinderOptions | null {
  if (!finderViewOptions) {
    return null;
  }
  const finderOptions: FinderOptions = { ...finderViewOptions };
  if (fragmentTree) {
    finderOptions.fragmentTree = {
      path: fragmentTree.model,
      levels: fragmentTree.levels,
      hidden: Boolean(finderViewOptions.fragmentTree.hidden),
    };
    if (finderViewOptions.fragmentTree?.levels) {
      for (let i = 0; i < finderOptions.fragmentTree.levels.length; ++i) {
        const levelOptions = finderViewOptions.fragmentTree.levels[i];
        if (!levelOptions) {
          continue;
        }
        finderOptions.fragmentTree.levels[i] = {
          ...finderOptions.fragmentTree.levels[i],
          ...levelOptions,
        };
      }
    }
  }
  if (criteriaTree) {
    finderOptions.criteriaTree = {
      path: criteriaTree,
      treeSelections: finderViewOptions.criteriaTree.treeSelections,
      tableSelections: finderViewOptions.criteriaTree.tableSelections,
      hidden: Boolean(finderViewOptions.criteriaTree.hidden),
    };
  }
  if (classTree) {
    finderOptions.classTree = {
      levels: classTree.levels,
      hidden: Boolean(finderViewOptions.classTree.hidden),
    };
  }
  return finderOptions;
}

function parseTableDragOptions(
  drag?: TableDragViewOptions
): TableDragOptions | null {
  if (!drag || !drag.type) {
    return null;
  }
  const functionId =
    typeof drag.collect === "function" ? registerFunction(drag.collect) : null;
  return {
    type: drag.type,
    row: drag.row,
    columns: drag.columns,
    collectFunctionId: functionId,
  };
}

function parseTableDropOptions(
  drop?: TableDropViewOptions
): TableDropOptions | null {
  if (!drop || !drop.accepts) {
    return null;
  }
  const functionId =
    typeof drop.resolveDrop === "function"
      ? registerFunction(drop.resolveDrop)
      : null;
  return {
    accepts: Array.isArray(drop.accepts) ? drop.accepts : [drop.accepts],
    table: drop.table,
    row: drop.row,
    columns: drop.columns,
    resolveDropFunctionId: functionId,
  };
}

function parseFinderInfo(finderInfo: any): ServerFinderFilter | null {
  if (!finderInfo) {
    return null;
  }
  return {
    orBlocks: finderInfo.orBlocks || [],
    criteriaFetchList: finderInfo.criteriaFetchList || [],
    predicateFetchList: finderInfo.predicateFetchList || [],
    subjectFetchList: finderInfo.subjectFetchList || [],
    classList: finderInfo.classList || [],
    classLevelFetchList: finderInfo.classLevelFetchList || [],
    fragmentList: finderInfo.fragmentList || [],
    fragmentFetchList: finderInfo.fragmentFetchList || [],
  };
}

function parseTableUserSettings(userSettings?: any): TableUserSettings | null {
  if (!userSettings) {
    return null;
  }
  return {
    ...userSettings,
    finderInfo: parseFinderInfo(userSettings.finderInfo),
  };
}

function parseGantElement(elementData: any): GantElementData | null {
  if (!elementData.from || !elementData.to) {
    console.warn("Can't parse gant row element data:", elementData);
    return null;
  }
  const parsedElmData: GantElementData = {
    from: elementData.from,
    to: elementData.to,
    dashed: Boolean(elementData.dashed),
    group: [],
  };
  const groups = parseToArray(elementData.group);
  for (let group of groups) {
    if (typeof group.id !== "string") {
      console.warn("Group without id cannot be added:", group, elementData);
      continue;
    }
    parsedElmData.group.push(group);
  }
  return parsedElmData;
}

function parseGantData(data: any): GantRowData {
  const parsedData: GantRowData = {
    chosenDate: [],
    plannedDate: [],
  };
  if (!data) {
    return parsedData;
  }

  const chosenDate = parseToArray(data.chosenDate);
  const plannedDate = parseToArray(data.plannedDate);
  for (let elementData of chosenDate) {
    const parsedElmData = parseGantElement(elementData);
    if (parsedElmData === null) {
      continue;
    }
    parsedData.chosenDate.push(parsedElmData);
  }
  for (let elementData of plannedDate) {
    const group = Array.isArray(elementData.group)
      ? elementData.group[0]
      : elementData.group;
    if (!group || group.id !== "plannedWork" || group.id !== "plannedDocs") {
      console.warn(
        "Inappropriate planned group of gant data:",
        group,
        elementData
      );
      continue;
    }
    const parsedElmData = parseGantElement(elementData);
    if (parsedElmData === null) {
      continue;
    }
    parsedData.plannedDate.push(parsedElmData);
  }
  return parsedData;
}

function parseTableRows(rows: any, columns: any): RowData[] {
  const parsedRows: RowData[] = [];
  if (!rows || !rows.length) {
    return parsedRows;
  }
  for (let row of rows) {
    const parsedRow: RowData = {
      data: {},
      bindedData: {},
      changed: null,
      changedData: {},
      classes: {},
    };
    for (let i = 0; i < columns.length; ++i) {
      const field = columns[i];
      const data = row[i];
      if (field === "gantData") {
        parsedRow.data[field] = parseGantData(data);
      } else {
        parsedRow.data[field] = data;
      }
    }
    parsedRows.push(parsedRow);
  }
  return parsedRows;
}

function parseTableData(
  data: any,
  pageable: boolean,
  page: number = 0,
  pageSize: number = -1,
  totalRowsLength?: number,
  _paramsReadOnly?: string
): TableData {
  const rows = data.rows || [];
  if (data.offset && data.limit) {
    page = Math.floor(data.offset / data.limit);
  }
  totalRowsLength =
    typeof data.totalNumberOfElements !== "undefined"
      ? data.totalNumberOfElements
      : totalRowsLength;
  if (!pageable || typeof totalRowsLength == "undefined") {
    totalRowsLength = rows.length;
  }
  const fields = data.fields || {};
  if (_paramsReadOnly === "true") {
    fields._paramsReadOnly = "true";
  }
  return {
    fields: fields,
    rows: parseTableRows(rows, data.columns),
    page: page,
    pageSize: data.limit || pageSize,
    totalRowsLength: totalRowsLength,
  };
}

function parseTableHeader(
  json: any,
  initOptions?: TableInitialOptions
): TableHeader {
  return {
    model: typeof json.model == "string" ? json.model : undefined,
    parameters: parseTableParameters(json.parameters),
    stylesheets: parseTableStylesheets(json.stylesheet),
    toolbar: parseTableToolbar(json.toolbar),
    reports: parseTableReports(json.reports),
    columns: parseTableColumns(json.columns),
    pageable: Boolean(json.pagination),
    pagination: parseTablePagination(json.pagination),
    selectType: json.selectType ? json.selectType.toLowerCase() : null,
    automation: json.automation || null,
    finderOptions: parseTableFinderOptions(
      initOptions?.finderViewOptions,
      json.fragmentTree,
      json.criteriaTree,
      json.classTree
    ),
    gantOptions: parseTableGantOptions(
      json.gantOptions || initOptions?.gantViewOptions
    ),
    dragOptions: parseTableDragOptions(initOptions?.drag),
    dropOptions: parseTableDropOptions(initOptions?.drop),
    userSettings: parseTableUserSettings(json.userSettings),
  };
}

function parseToServerData(
  columns: Column[],
  fields: { [k: string]: string },
  rows: { changed: { [k: string]: boolean }; data: RowDataMap }[]
): ServerTableData {
  const savingColumns: { key: boolean; field: string }[] = [];
  for (let column of columns) {
    if (!column.key) {
      continue;
    }
    savingColumns.push({ key: true, field: column.field });
  }
  for (let row of rows) {
    for (let columnField in row.changed) {
      if (
        savingColumns.findIndex((column) => column.field === columnField) !== -1
      ) {
        continue;
      }
      savingColumns.push({ key: false, field: columnField });
    }
  }
  const savingRows: any[][] = [];
  for (let row of rows) {
    const savingRow: any[] = [];
    for (let column of savingColumns) {
      if (column.key || row.changed[column.field]) {
        savingRow.push(row.data[column.field]);
        continue;
      }
      savingRow.push(null);
    }
    savingRows.push(savingRow);
  }
  return {
    columns: savingColumns.map((column) => column.field),
    fields: fields,
    rows: savingRows,
  };
}

/*********************
 * Special functions *
 *********************/

/**Collect all table fields data into request object */
function composeTableRequest(
  tableState: TableState,
  finderState: FinderState | null,
  page: number,
  pageSize: number,
  fields: { [k: string]: string },
  language: string,
  finderData: FinderData | null,
  resetFields?: boolean
) {
  const compositeRequest: TableRequest = {
    model: tableState.model,
    parameters: resetFields
      ? Object.assign({}, fields)
      : Object.assign({}, tableState.fields, fields),
  };
  const pageable = Boolean(tableState?.pageable);
  if (pageable) {
    compositeRequest.range = {
      limit: pageSize,
      offset: pageSize * page,
    };
  }
  for (let columnSortInfo of tableState.sortInfo.columns) {
    if (!compositeRequest.range) {
      compositeRequest.range = {};
    }
    if (!compositeRequest.range.sortItemList) {
      compositeRequest.range.sortItemList = [];
    }
    compositeRequest.range.sortItemList.push({
      name: columnSortInfo.field,
      ascending: columnSortInfo.direction === "asc",
    });
  }
  if (finderData?.searchString) {
    compositeRequest.searchInput = finderData.searchString;
  }
  compositeRequest.filter = composeTableFilter(
    tableState.filterInfo.columns,
    tableState.dynamicColumns,
    finderState,
    finderData,
    language
  );
  return compositeRequest;
}

/**Prepare table request to use in fetch */
async function prepareTableRequest(
  request: TableRequest,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
) {
  if (onFetch) {
    request = await onFetch({ ...request });
  }
  return request;
}

/**Collect table and finder filter data into request object */
function composeTableFilter(
  columnFilters: ColumnFilterInfo[],
  dynamicColumns: string[] | null,
  finderState: FinderState | null,
  finderData: FinderData | null,
  language: string
) {
  const haveFinder = Boolean(finderState && finderData);
  /**TODO: add key filters */
  const columnFilterList = parseColumnFilterList(columnFilters);
  if (columnFilterList.length === 0 && !dynamicColumns && !haveFinder) {
    return null;
  }
  const filter: ServerTableFilter = {
    columns: dynamicColumns,
    finder: haveFinder
      ? parseFinderToFilterWithQuery(
          finderState as FinderState,
          finderData as FinderData,
          messages[language]
        )
      : null,
    keyFilters: null,
    columnFilters: columnFilterList.length === 0 ? null : columnFilterList,
  };
  if (
    !filter.columns &&
    !filter.columnFilters &&
    !filter.finder?.orBlocks.length &&
    !filter.finder?.classList?.length &&
    !filter.finder?.fragmentList?.length
  ) {
    return null;
  }
  return filter;
}

/*****************
 * Plain actions *
 *****************/
export function sendTableInitialData(
  tableId: string,
  data: { initialSorting?: ColumnSortInfo[] }
): SendTableInitialData {
  return {
    type: SEND_TABLE_INITIAL_DATA,
    tableId,
    payload: data,
  };
}

export function sendTableHeader(
  tableId: string,
  header: TableHeader
): SendTableHeader {
  return {
    type: SEND_TABLE_HEADER,
    tableId,
    payload: {
      header: header,
    },
  };
}

export function sendTableData(
  tableId: string,
  data: TableData,
  getAutomationTableModule: (state: TableState) => AutomationTableModule,
  reset?: boolean,
  savePage?: boolean
): SendTableData {
  return {
    type: SEND_TABLE_DATA,
    tableId,
    payload: {
      data,
      reset: Boolean(reset),
      savePage: Boolean(savePage),
      getAutomationTableModule,
    },
  };
}

export function sendTableLoading(tableId: string): SendTableLoading {
  return {
    type: SEND_TABLE_LOADING,
    tableId,
    payload: null,
  };
}

export function sendTableError(
  tableId: string,
  error: FetchError
): SendTableError {
  return {
    type: SEND_TABLE_ERROR,
    tableId,
    payload: {
      error,
    },
  };
}

export function sendTableDataLoading(
  tableId: string,
  payload: { page: number; pageSize: number; abort: AbortController }
): SendTableDataLoading {
  return {
    type: SEND_TABLE_DATA_LOADING,
    tableId,
    payload: payload,
  };
}

export function sendTableDataError(
  tableId: string,
  payload: { error: FetchError; page: number; pageSize: number }
): SendTableDataError {
  return {
    type: SEND_TABLE_DATA_ERROR,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterSelectionLoading(
  tableId: string,
  payload: { field: string }
): SendTableFilterSelectionLoading {
  return {
    type: SEND_TABLE_FILTER_SELECTION_LOADING,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterSelectionError(
  tableId: string,
  payload: { error: FetchError; field: string }
): SendTableFilterSelectionError {
  return {
    type: SEND_TABLE_FILTER_SELECTION_ERROR,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterSelection(
  tableId: string,
  payload: { selection: any[]; field: string }
): SendTableFilterSelection {
  return {
    type: SEND_TABLE_FILTER_SELECTION,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterRangeLoading(
  tableId: string,
  payload: { field: string }
): SendTableFilterRangeLoading {
  return {
    type: SEND_TABLE_FILTER_RANGE_LOADING,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterRangeError(
  tableId: string,
  payload: { error: FetchError; field: string }
): SendTableFilterRangeError {
  return {
    type: SEND_TABLE_FILTER_RANGE_ERROR,
    tableId,
    payload: payload,
  };
}

export function sendTableFilterRange(
  tableId: string,
  payload: { range: { min: any; max: any }; field: string }
): SendTableFilterRange {
  return {
    type: SEND_TABLE_FILTER_RANGE,
    tableId,
    payload: payload,
  };
}

export function sendTableSortOptions(
  tableId: string,
  payload: { sortColumns: ColumnSortInfo[]; filterColumns: string[] }
): SendTableSortOptions {
  return {
    type: SEND_TABLE_SORT_OPTIONS,
    tableId,
    payload: payload,
  };
}

export function sendTableColumns(
  tableId: string,
  selectedColumns: string[] | null,
  hiddenColumns: { [k: string]: boolean }
): SendTableColumns {
  return {
    type: SEND_TABLE_COLUMNS,
    tableId,
    payload: {
      selectedColumns,
      hiddenColumns,
    },
  };
}

export function sendSelectRow(tableId: string, key: string): SendSelectRow {
  return {
    type: SEND_SELECT_ROW,
    tableId,
    payload: {
      key,
    },
  };
}

export function sendSelectAll(tableId: string): SendSelectAll {
  return {
    type: SEND_SELECT_ALL,
    tableId,
    payload: null,
  };
}

export function plainChangePageSize(
  tableId: string,
  pageSize: number
): SendPageSize {
  return {
    type: SEND_PAGE_SIZE,
    tableId,
    payload: {
      pageSize,
    },
  };
}

export function plainChangePage(
  tableId: string,
  page: number,
  automationTableModule: AutomationTableModule
): SendPage {
  return {
    type: SEND_PAGE,
    tableId,
    payload: {
      page,
      automationTableModule,
    },
  };
}

export function sortByColumn(tableId: string, key: string): SendSortKey {
  return {
    type: SEND_SORT_KEY,
    tableId,
    payload: {
      key,
    },
  };
}

export function filterByColumn(
  tableId: string,
  field: string,
  filterData: FilterData | null
): SendFilterData {
  return {
    type: SEND_FILTER_DATA,
    tableId,
    payload: {
      field,
      filterData,
    },
  };
}

export function sendLocalTableInitialData(
  tableId: string,
  tableData: InitialLocalTableData
): SendLocalTableInitialData {
  return {
    type: SEND_LOCAL_TABLE_INITIAL_DATA,
    tableId,
    payload: tableData,
  };
}

export function sendLocalTableUpdateData(
  tableId: string,
  tableData: LocalTableData
): SendLocalTableUpdateData {
  return {
    type: SEND_LOCAL_TABLE_UPDATE_DATA,
    tableId,
    payload: tableData,
  };
}

export function sendLocalTableUninitialize(
  tableId: string
): SendLocalTableUninitialize {
  return {
    type: SEND_LOCAL_TABLE_UNINITIALIZE,
    tableId,
    payload: null,
  };
}

export function sendField(
  tableId: string,
  parameter: string,
  value: string
): SendField {
  return {
    type: SEND_FIELD,
    tableId,
    payload: {
      parameter,
      value,
    },
  };
}

export function sendGantViewType(
  tableId: string,
  type: GantViewType
): SendGantViewType {
  return {
    type: SEND_GANT_VIEW_TYPE,
    tableId,
    payload: {
      type,
    },
  };
}

export function sendGantSelectedGroup(
  tableId: string,
  group: string
): SendGantSelectedGroup {
  return {
    type: SEND_GANT_SELECTED_GROUP,
    tableId,
    payload: {
      group,
    },
  };
}

export function sendGantChangeElement(
  tableId: string,
  rowIndex: number,
  itemIndex: number,
  newDate: GantDateLimits,
  isPlanned: boolean
): SendGantChangeElement {
  return {
    type: SEND_GANT_CHANGE_ELEMENT,
    tableId,
    payload: {
      rowIndex,
      itemIndex,
      newDate,
      isPlanned,
    },
  };
}

function sendFilterChangesConfirm(tableId: string): SendFilterChangesConfirm {
  return {
    type: SEND_FILTER_CHANGES_CONFIRM,
    tableId,
    payload: null,
  };
}

function sendFilterChangesDeny(tableId: string): SendFilterChangesDeny {
  return {
    type: SEND_FILTER_CHANGES_DENY,
    tableId,
    payload: null,
  };
}

export function sendTableSaveStart(tableId: string): SendTableSaveStart {
  return {
    type: SEND_TABLE_SAVE_START,
    tableId,
    payload: null,
  };
}

export function sendTableSaveSuccess(tableId: string): SendTableSaveSuccess {
  return {
    type: SEND_TABLE_SAVE_SUCCESS,
    tableId,
    payload: null,
  };
}

export function sendTableSaveError(
  tableId: string,
  error: FetchError
): SendTableSaveError {
  return {
    type: SEND_TABLE_SAVE_ERROR,
    tableId,
    payload: {
      error,
    },
  };
}

export function sendRowAdd(tableId: string, row: RowDataMap): SendTableRowAdd {
  return {
    type: SEND_TABLE_ROW_ADD,
    tableId,
    payload: {
      row,
    },
  };
}

export function sendRowChange(
  tableId: string,
  row: RowDataMap,
  rowIdx: number
): SendTableRowChange {
  return {
    type: SEND_TABLE_ROW_CHANGE,
    tableId,
    payload: {
      row,
      rowIdx,
    },
  };
}

export function sendCellChange(
  tableId: string,
  value: any,
  rowIdx: number,
  column: string
): SendTableCellChange {
  return {
    type: SEND_TABLE_CELL_CHANGE,
    tableId,
    payload: {
      value,
      rowIdx,
      column,
    },
  };
}

export function sendClientSideFilter(
  tableId: string,
  clientSideFilter: boolean
): SendTableClientSideFilter {
  return {
    type: SEND_TABLE_CLIENT_SIDE_FILTER,
    tableId,
    payload: {
      clientSideFilter,
    },
  };
}

/***********
 * Actions *
 ***********/

export function fetchTable(
  tableId: string,
  fields?: { [k: string]: string },
  reset?: boolean,
  initOptions?: TableInitialOptions,
  forceLoadData?: boolean,
  forceLoadHeader?: boolean
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    try {
      const s = getState();
      if (
        s &&
        s.table &&
        s.table[tableId] &&
        (s.table[tableId] as TableState).loading
      ) {
        return;
      }
      dispatch(
        sendTableInitialData(tableId, {
          initialSorting: initOptions?.initialSorting,
        })
      );
      if (typeof s.selection.info != "undefined") {
        fields = Object.assign({}, s.selection.info, fields);
      }
      if (!isHeaderLoaded(s.table[tableId]) || forceLoadHeader) {
        dispatch(sendTableLoading(tableId));

        const locationParams = fields?._ignoreLocationFields
          ? {}
          : s.location.params;

        fields = Object.assign({}, s.location.params, fields);
        const header = parseTableHeader(
          await fetchTableHeaderImpl(tableId),
          initOptions
        );
        if (header.userSettings?.fields) {
          fields = Object.assign(header.userSettings?.fields, fields);
        }
        if (header.finderOptions) {
          dispatch(
            initializeFinder(
              tableId,
              header.finderOptions,
              header.userSettings?.finderInfo
            )
          );
        }
        dispatch(sendTableHeader(tableId, header));
      }
      const finderState = getState().finder[tableId];
      /**
       * If finder not ready - do not fetch data
       * (fetchTable will be again called from Table component when finder will be ready)
       **/
      if (finderState && !finderState.isReady) {
        console.log("Prevent table initialization: finder is not ready");
        return;
      }
      dispatch(
        fetchTableData(
          tableId,
          {
            fields,
            finder: finderState?.data,
            reset,
            resetFields: initOptions?.resetFields,
            onFetch: initOptions?.onFetch,
          },
          forceLoadData
        )
      );
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatch(sendTableError(tableId, { code: e.code, message: e.message }));
      } else if (typeof e.message == "string") {
        dispatch(sendTableError(tableId, { code: -1, message: e.message }));
      } else {
        dispatch(
          sendTableError(tableId, { code: -1, message: "Unknown error" })
        );
      }
    }
  };
}

export function fetchTableData(
  tableId: string,
  options: {
    fields?: { [k: string]: string } | null;
    finder?: FinderData | null;
    reset?: boolean;
    savePage?: boolean;
    resetFields?: boolean;
    onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
  },
  force: boolean = false
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const { fields, finder, reset, savePage, resetFields, onFetch } = options;
    let page = 0;
    let pageSize = -1;
    try {
      const state = getState();
      const tableState = state.table[tableId];
      const tableAbortLoading = tableState.abortLoading;
      if (tableState.loadingData) {
        console.warn("Can't fetch table data: data is already loading");
        return;
      }

      // if (tableState.loadingData && tableAbortLoading) {
      //   console.log("abort");
      //   tableAbortLoading.abort();
      // }
      const finderState = state.finder[tableId] || null;
      const finderData = finder || finderState?.data || null;
      /* Add pagination query parameters */
      const pageable = Boolean(tableState?.pageable);
      if (pageable) {
        if (reset) {
          pageSize = tableState.pageSize;
          if (savePage) {
            page = tableState.page;
          }
        } else {
          page = fields && fields._p ? parseInt(fields._p) : tableState.page;
          pageSize =
            fields && fields._ps ? parseInt(fields._ps) : tableState.pageSize;
        }
      }

      const compositeRequest = composeTableRequest(
        tableState,
        finderState,
        page,
        pageSize,
        fields || {},
        state.locale.language,
        finderData,
        resetFields
      );
      const tableRequest = await prepareTableRequest(compositeRequest, onFetch);
      const controller = new AbortController();
      const parameters = tableState?.parameters;
      dispatch(
        sendTableDataLoading(tableId, {
          page: page,
          pageSize: pageSize,
          abort: controller,
        })
      );
      if (parameters) {
        const searchData: { [k: string]: string } = {};
        for (let k in parameters) {
          const name = parameters[k].name;
          if (tableRequest.parameters?.[name]) {
            searchData[name] = tableRequest.parameters[name];
          }
        }
        if (fields?._paramsReadOnly !== "true") {
          dispatch(changeSearch(searchData));
        }
      }
      const getAutomationTable = (state: TableState) =>
        getAutomationTableModule(state, dispatch, onFetch);

      dispatch(
        sendTableData(
          tableId,
          parseTableData(
            await fetchTableDataImpl(tableId, tableRequest, controller),
            pageable,
            page,
            pageSize,
            tableState.totalRowsLength,
            fields?._paramsReadOnly
          ),
          getAutomationTable,
          reset,
          savePage
        )
      );
    } catch (e: any) {
      if (e.name == "AbortError") {
        // console.log("error", e);
        return;
      }
      if (e instanceof ServerError) {
        dispatch(
          sendTableDataError(tableId, {
            error: { code: e.code, message: e.message },
            page,
            pageSize,
          })
        );
      } else if (typeof e.message == "string") {
        dispatch(
          sendTableDataError(tableId, {
            error: { code: -1, message: e.message },
            page,
            pageSize,
          })
        );
      } else {
        dispatch(
          sendTableDataError(tableId, {
            error: { code: -1, message: "Unknown error" },
            page,
            pageSize,
          })
        );
      }
    }
  };
}

function getLocalSelection(tableState: TableState, fieldId: string): string[] {
  const valuesMap: { [k: string]: boolean } = {};
  const column = tableState.columns.find((column) => column.field === fieldId);
  const isHtml = column?.format === "html";
  for (let rowData of Object.values(tableState.rowByIdx)) {
    let value = null;
    if (typeof rowData.bindedData[fieldId] !== "undefined") {
      value = rowData.bindedData[fieldId];
    } else if (typeof rowData.data[fieldId] !== "undefined") {
      value = rowData.data[fieldId];
    }
    if (value === null) {
      value = "";
    } else if (isHtml) {
      const div = document.createElement("div");
      div.innerHTML = value.trim();
      value = div.innerText;
    }
    valuesMap[value] = true;
  }
  return Object.keys(valuesMap).sort();
}

export function fetchTableFilterSelection(
  tableId: string,
  columnField: string,
  filterData: FilterData | null,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const tableState = state.table[tableId];
      const finderState = state.finder[tableId] || null;
      const finderData = finderState?.data || null;

      if (tableState.clientSideFilter) {
        dispatch(
          sendTableFilterSelection(tableId, {
            field: columnField,
            selection: getLocalSelection(tableState, columnField),
          })
        );
        return;
      }

      dispatch(
        sendTableFilterSelectionLoading(tableId, { field: columnField })
      );
      const columnFilters = tableState.filterInfo.columns.slice();
      const existingFilterInfoIdx = columnFilters.findIndex(
        (filter) => filter.field === columnField
      );
      if (existingFilterInfoIdx !== -1) {
        if (!filterData) {
          columnFilters.splice(existingFilterInfoIdx, 1);
        } else {
          columnFilters.splice(existingFilterInfoIdx, 1, {
            field: columnField,
            data: filterData,
          });
        }
      } else if (filterData) {
        columnFilters.push({ field: columnField, data: filterData });
      }
      const compositeRequest = composeTableRequest(
        tableState,
        finderState,
        tableState.page,
        tableState.pageSize,
        tableState.fields,
        state.locale.language,
        finderData
      );
      const tableRequest = await prepareTableRequest(compositeRequest, onFetch);
      const selection = await fetchTableFilterSelectionImpl(
        tableId,
        columnField,
        columnFilters,
        tableRequest
      );
      dispatch(
        sendTableFilterSelection(tableId, { field: columnField, selection })
      );
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatch(
          sendTableFilterSelectionError(tableId, {
            error: { code: e.code, message: e.message },
            field: columnField,
          })
        );
      } else if (typeof e.message == "string") {
        dispatch(
          sendTableFilterSelectionError(tableId, {
            error: { code: -1, message: e.message },
            field: columnField,
          })
        );
      } else {
        dispatch(
          sendTableFilterSelectionError(tableId, {
            error: { code: -1, message: "Unknown error" },
            field: columnField,
          })
        );
      }
    }
  };
}

function getLocalRange(tableState: TableState, fieldId: string) {
  let min: any = null;
  let max: any = null;
  for (let rowData of Object.values(tableState.rowByIdx)) {
    let value = null;
    if (typeof rowData.bindedData[fieldId] !== "undefined") {
      value = rowData.bindedData[fieldId];
    } else if (typeof rowData.data[fieldId] !== "undefined") {
      value = rowData.data[fieldId];
    }
    if (value === null) {
      continue;
    }
    if (min === null || value < min) {
      min = value;
    }
    if (max === null || value > max) {
      max = value;
    }
  }
  return { min, max };
}

export function fetchTableFilterRange(
  tableId: string,
  columnField: string,
  filterData: FilterData | null,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const tableState = state.table[tableId];
      const finderState = state.finder[tableId] || null;
      const finderData = finderState?.data || null;

      if (tableState.clientSideFilter) {
        dispatch(
          sendTableFilterRange(tableId, {
            field: columnField,
            range: getLocalRange(tableState, columnField),
          })
        );
        return;
      }

      dispatch(sendTableFilterRangeLoading(tableId, { field: columnField }));
      const columnFilters = tableState.filterInfo.columns.slice();
      const existingFilterInfoIdx = columnFilters.findIndex(
        (filter) => filter.field === columnField
      );
      if (existingFilterInfoIdx !== -1) {
        if (!filterData) {
          columnFilters.splice(existingFilterInfoIdx, 1);
        } else {
          columnFilters.splice(existingFilterInfoIdx, 1, {
            field: columnField,
            data: filterData,
          });
        }
      } else if (filterData) {
        columnFilters.push({ field: columnField, data: filterData });
      }
      const compositeRequest = composeTableRequest(
        tableState,
        finderState,
        tableState.page,
        tableState.pageSize,
        tableState.fields,
        state.locale.language,
        finderData
      );
      const tableRequest = await prepareTableRequest(compositeRequest, onFetch);
      const range = await fetchTableFilterRangeImpl(
        tableId,
        columnField,
        columnFilters,
        tableRequest
      );
      dispatch(sendTableFilterRange(tableId, { field: columnField, range }));
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatch(
          sendTableFilterRangeError(tableId, {
            error: { code: e.code, message: e.message },
            field: columnField,
          })
        );
      } else if (typeof e.message == "string") {
        dispatch(
          sendTableFilterRangeError(tableId, {
            error: { code: -1, message: e.message },
            field: columnField,
          })
        );
      } else {
        dispatch(
          sendTableFilterRangeError(tableId, {
            error: { code: -1, message: "Unknown error" },
            field: columnField,
          })
        );
      }
    }
  };
}

export function openTableSortOptions(
  tableId: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "table_sort_options_modal",
        "tableSortOptions",
        { tableId },
        (result: {
          sortColumns: ColumnSortInfo[];
          filterColumns: string[];
        }) => {
          dispatch(sendTableSortOptions(tableId, result));
        }
      )
    );
  };
}

export function openTableDynamicColumns(
  tableId: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      openModal(
        "table_dynamic_columns_options_modal",
        "tableDynamicColumnsOptions",
        { tableId },
        (result: {
          selectedColumns: string[] | null;
          hiddenColumns: { [k: string]: boolean };
        }) => {
          const s = getState();
          const tableState = s.table[tableId];
          if (result.selectedColumns === null) {
            console.log("Reset dynamic columns");
            return;
          }
          dispatch(
            sendTableColumns(
              tableId,
              result.selectedColumns,
              result.hiddenColumns
            )
          );
        }
      )
    );
  };
}

export function downloadReport(
  tableId: string,
  report: ToolbarReport,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableState = s.table[tableId];
    const finderState = s.finder[tableId] || null;
    const finderData = finderState?.data || null;

    const compositeRequest = composeTableRequest(
      tableState,
      finderState,
      tableState.page,
      tableState.pageSize,
      tableState.fields,
      s.locale.language,
      finderData
    );
    const tableRequest = await prepareTableRequest(compositeRequest, onFetch);

    let href = null;
    const filename = getFileName(report.label, report.type, report.filename);
    if (!isFilterNeeded(tableRequest)) {
      const fields = getTableSearchFields(tableRequest);
      delete fields._p;
      delete fields._ps;
      fields._report = report.name;
      fields._filename = filename;
      href = buildUrl({
        url: `/rest/table/report${tableId}`,
        search: fields,
        withoutEncoding: true,
      });
    } else {
      if (tableRequest.range?.sortItemList) {
        tableRequest.range = {
          sortItemList: tableRequest.range.sortItemList,
        };
      } else {
        delete tableRequest.range;
      }
      try {
        let reportUuid = await fetchReportUuid(
          tableId,
          tableRequest,
          report.name,
          filename
        );
        href = `/rest/file/report/${reportUuid}`;
      } catch (e: any) {
        console.error(e);
      }
    }

    if (!href) {
      return;
    }

    downloadFile(href, filename);
  };
}

export function buttonClick(
  tableId: string,
  buttonId: string,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    if (
      !tableData.automation.clickBindings ||
      !tableData.automation.clickBindings[buttonId]
    ) {
      return;
    }
    const func = retrieveFunction(tableData.automation.clickBindings[buttonId]);
    const automationTableModule = getAutomationTableModule(
      tableData,
      dispatch,
      onFetch
    );
    func(automationTableModule);
  };
}

export function changePageSize(
  tableId: string,
  pageSize: number,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    if (!tableData.pageable || tableData.pageSize === pageSize) {
      return;
    }
    const firstRowIdx = tableData.page * tableData.pageSize;
    const newPage = Math.floor(firstRowIdx / pageSize);
    dispatch(fetchPageData(tableId, newPage, pageSize, onFetch));
    dispatch(plainChangePageSize(tableId, pageSize));
  };
}

function fetchPageData(
  tableId: string,
  page: number,
  pageSize: number,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    /* Check if fetch needed */
    if (isPageFetched(tableData, page, pageSize)) {
      return;
    }
    dispatch(
      fetchTableData(tableId, {
        fields: { _p: page.toString(), _ps: pageSize.toString() },
        onFetch,
      })
    );
  };
}

export function changePage(
  tableId: string,
  page: number,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    if (!tableData.pageable || tableData.page === page) {
      return;
    }
    dispatch(fetchPageData(tableId, page, tableData.pageSize, onFetch));
    const automationTableModule = getAutomationTableModule(
      tableData,
      dispatch,
      onFetch
    );

    dispatch(plainChangePage(tableId, page, automationTableModule));
  };
}

export function changeGantElement(
  tableId: string,
  rowIndex: number,
  itemIndex: number,
  newDate: GantDateLimits,
  isPlanned: boolean
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(
      sendGantChangeElement(tableId, rowIndex, itemIndex, newDate, isPlanned)
    );
  };
}

export function confirmFilterChanges(
  tableId: string,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableState = s.table[tableId];
    const finderState = s.finder[tableId] || null;
    if (!tableState.filterChanges && !finderState.changes) {
      return;
    }
    dispatch(
      fetchTableData(tableId, {
        fields: tableState.filterChanges?.fields,
        finder: finderState?.changes,
        reset: true,
        onFetch,
      })
    );
    dispatch(sendFinderChangesConfirm(tableId));
    dispatch(sendFilterChangesConfirm(tableId));
  };
}

export function cancelFilterChanges(
  tableId: string
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(sendFinderChangesDeny(tableId));
    dispatch(sendFilterChangesDeny(tableId));
  };
}

export function saveTable(
  tableId: string,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, AnyAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableState = s.table[tableId];
    const savingRows: {
      changed: { [k: string]: boolean };
      data: RowDataMap;
    }[] = [];
    for (let rowIdx in tableState.rowByIdx) {
      const row = tableState.rowByIdx[rowIdx];
      if (!row.changed) {
        continue;
      }
      savingRows.push({
        changed: row.changed,
        data: Object.assign({}, row.data, row.bindedData, row.changedData),
      });
    }
    if (savingRows.length === 0) {
      return;
    }
    dispatch(sendTableSaveStart(tableId));
    try {
      const response = await saveTableImpl(
        tableId,
        parseToServerData(tableState.columns, tableState.fields, savingRows)
      );
      response.text();
      if (response.status === 200) {
        dispatch(
          addAlert(ALERT_LEVEL_SUCCESS, { id: "NPT_TABLE_SAVE_SUCCESS" })
        );
      } else {
        const message = await receiveServerMessage(response);
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, message));
      }
      dispatch(sendTableSaveSuccess(tableId));
      dispatch(
        fetchPageData(tableId, tableState.page, tableState.pageSize, onFetch)
      );
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatchErrorV2(e.message, e, dispatch);
        dispatch(
          sendTableSaveError(tableId, { code: e.code, message: e.message })
        );
      } else if (typeof e.message == "string") {
        dispatchErrorV2(e.message, e, dispatch);
        dispatch(sendTableSaveError(tableId, { code: -1, message: e.message }));
      } else {
        dispatchError("NPT_TABLE_SAVE_ERROR", e, dispatch);
        dispatch(
          sendTableSaveError(tableId, { code: -1, message: "Unknown error" })
        );
      }
    }
  };
}

export function resolveDrop(
  tableId: string,
  collectedData: any,
  dropInfo: {
    cell?: any;
    row?: RowData;
    rowIdx?: number;
    column?: string;
    columnIdx?: number;
  },
  resolveDropFunctionId: string,
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    const func = retrieveFunction(resolveDropFunctionId);
    const automationTableModule = getAutomationTableModule(
      tableData,
      dispatch,
      onFetch
    );
    try {
      await func(collectedData, dropInfo, automationTableModule);
    } catch (e) {
      console.error("Failure of the table resolve drop function", e);
    }
  };
}

export function addRow(
  tableId: string,
  row: RowDataMap
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    dispatch(sendRowAdd(tableId, row));
  };
}

export function changeRow(
  tableId: string,
  row: RowDataMap,
  rowIdx: number
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    if (!tableData.rowByIdx[rowIdx]) {
      dispatchError("NPT_TABLE_CHANGE_ROW_NOT_LOADED", null, dispatch, {
        rowIdx,
      });
      // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_ROW_NOT_LOADED", values: { rowIdx } }));
      return;
    }
    dispatch(sendRowChange(tableId, row, rowIdx));
  };
}

export function changeCell(
  tableId: string,
  value: any,
  rowIdx: number,
  column: string
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableData = s.table[tableId];
    if (!column) {
      dispatchError("NPT_TABLE_CHANGE_CELL_UNDEFINED_COLUMN", null, dispatch, {
        column,
      });
      // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_CELL_UNDEFINED_COLUMN", values: { column } }));
      return;
    }
    if (!tableData.rowByIdx[rowIdx]) {
      dispatchError("NPT_TABLE_CHANGE_CELL_NOT_LOADED", null, dispatch, {
        column,
        rowIdx,
      });
      // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_CELL_NOT_LOADED", values: { column, rowIdx } }));
      return;
    }
    dispatch(sendCellChange(tableId, value, rowIdx, column));
  };
}

export function saveFilter(
  tableId: string
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    const s = getState();
    const tableState = s.table[tableId];
    const finderState = s.finder[tableId];
    try {
      const filter: TableUserSettings = {
        fields: tableState.fields,
        dynamicColumns: tableState.dynamicColumns,
        dynamicHiddenColumns: tableState.dynamicHiddenColumns,
        sortInfo: tableState.sortInfo,
        filterInfo: tableState.filterInfo,
        finderInfo: null,
      };
      if (finderState) {
        filter.finderInfo = parseFinderToFilter(finderState, finderState.data);
      }
      const response = await saveFilterImpl(tableId, filter);
      response.text();
      if (response.status === 200) {
        dispatch(
          addAlert(ALERT_LEVEL_SUCCESS, { id: "NPT_TABLE_FILTER_SAVE_SUCCESS" })
        );
      } else {
        const message = await receiveServerMessage(response);
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, message));
      }
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatchErrorV2(e.message, e, dispatch);
      } else if (typeof e.message == "string") {
        dispatchErrorV2(e.message, e, dispatch);
      } else {
        dispatchError("NPT_TABLE_FILTER_SAVE_ERROR", e, dispatch);
      }
    }
  };
}

export function resetFilter(
  tableId: string
): ThunkAction<void, ApplicationState, {}, TableAction> {
  return async (dispatch, getState) => {
    try {
      const response = await saveFilterImpl(tableId, {});
      response.text();
      if (response.status === 200) {
        dispatch(
          addAlert(ALERT_LEVEL_SUCCESS, {
            id: "NPT_TABLE_FILTER_RESET_SUCCESS",
          })
        );
      } else {
        const message = await receiveServerMessage(response);
        dispatch(addAlert(ALERT_LEVEL_SUCCESS, message));
      }
    } catch (e: any) {
      console.error(e);
      if (e instanceof ServerError) {
        dispatchErrorV2(e.message, e, dispatch);
      } else if (typeof e.message == "string") {
        dispatchErrorV2(e.message, e, dispatch);
      } else {
        dispatchError("NPT_TABLE_FILTER_RESET_ERROR", e, dispatch);
      }
    }
  };
}
